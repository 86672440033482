<template>
    <section>
      <el-form ref="form" label-position="right" status-icon :model="form" :rules="rules" label-width="100px" style="width:95%;overflow:auto">
        <el-form-item label="设备编号" prop="EquipmentNum" >
          <el-input placeholder="请输入设备编号" v-model="form.EquipmentNum" readonly>
          </el-input>
        </el-form-item>
        <el-form-item label="设备站点" prop="sign" >
          <el-select v-model="form.sign" placeholder="请选择" >
             <el-option
               v-for="item in equipmentSiteOptions"
               :key="item.code"
               :label="item.remarks"
               :value="item.code">
             </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button @click="close('form')">取消</el-button>
          <el-button type="primary" @click="submit('form')" :plain='true'>提交</el-button>
        </el-form-item>
      </el-form>
    </section>
  </template>
  
  <script>
  let _this;
  import {GetEquipmentSetNetwork,GetEquipmentSite} from '@/api/public.js';
  
  export default {
    props:{row:{type:Object}},
    data() {
      return {
        form:{
            EquipmentNum:'',
            sign:'',
        },
        equipmentSiteOptions:[],
        rules: {
            sign: [
            { required: true, message: '请选择设备站点', trigger: 'change', }
          ],
        },
      };
    },
  
    components: {},
  
    computed: {},
  
    mounted() {
      _this=this;
      _this.form.EquipmentNum=_this.row.EquipmentNum;
      _this.init();
    },
  
    methods: {
        init(){
            GetEquipmentSite({sign:'EquipmentSite'}).then(res=>{
                if(res.Success){
                    this.equipmentSiteOptions=res.Data;
                }else{
                    this.equipmentSiteOptions=[];
                }
            })
        },
      close(){
         _this.$emit('close');
      },
       //  添加
       submit(form) {
         _this.$refs["form"].validate(valid => {
            if (valid) {
                GetEquipmentSetNetwork(_this.form)
                .then((res) => {
                  if (res.Success) {
                    _this.$message({
                      message: "转网成功",
                      type: "success",
                    });
                    setTimeout(() => {
                      _this.$emit("success");
                      _this.$emit('close');
                      _this.init();
                    }, 1000);
                  }else{
                    _this.$message({
                      message: "转网失败，请重试",
                      type: "fail",
                    });
                  }
                })
                .catch(error => {});
            } else {
              return false;
            }
        });
      },
      
    }
  };
  </script>
  <style lang="scss" scoped>
   .el-drawer{
     overflow:auto !important;
   }
   .avatar-uploader .el-upload {
      cursor: pointer;
      position: relative;
      overflow: hidden;
    }
    .avatar-uploader .el-upload:hover {
      border-color: #409EFF;
    }
    .avatar-uploader-icon {
      border: 1px dashed #ccc;
      border-radius: 50%;
      overflow:hidden;
      font-size: 28px;
      color: #8c939d;
      width: 178px;
      height: 178px;
      line-height: 178px;
      text-align: center;
    }
    .avatar {
      width: 178px;
      height: 178px;
      display: block;
    }
  </style>
  