<template>
  <div class="detail">
    <div class="basicInfo">
        <div class="one">
            <p><span>场所名称</span><b>{{neighbourInfo.NeighbourhoodName}}</b></p>
            <p><span>安装日期</span><b>{{neighbourInfo.InstallDate}}</b></p>
            <p><span>物业</span><b>{{neighbourInfo.WeChatName?neighbourInfo.WeChatName:'暂未绑定'}}</b></p>
        </div>
        <div class="two">
            <p><span>省市区</span><b>{{neighbourInfo.Provinces+neighbourInfo.City+neighbourInfo.Area}}</b></p>
            <p><span>详细地址</span><b>{{neighbourInfo.Street+neighbourInfo.Address}}</b></p>
            <p><span>投入成本（元）</span><b>{{neighbourInfo.InputCost}}</b></p>
        </div>
    </div>
    <div class="tab">
        <el-tabs v-model="activeName">
          <el-tab-pane label="设备" name="first">
            <div class="equip">
              <el-table
              :data="equipInfo"
              style="width: 100%" 
              class="table"
              fit
              >
                <!-- 数据展示区 -->
                <el-table-column
                  label="设备编号"
                  prop="EquipmentNum"
                  align='center'>
                </el-table-column>
                <el-table-column
                  label="设备名称"
                  prop="Address"
                  align='center'>
                </el-table-column>
                <el-table-column
                  label="设备类型"
                  prop="EquipmentType"
                  align='center'>
                  <template slot-scope="scope">
                    <p>{{scope.row.EquipmentType}}口充电桩</p>
                  </template>
                </el-table-column>
                <el-table-column
                  label="设备状态"
                  prop="EquipmentState"
                  align='center'>
                </el-table-column>
                <el-table-column
                  label="端口状态"
                  prop="PortState"
                  align='center'>
                  <template slot-scope="scope">
                    <span style="">{{scope.row.free}}</span>
                    <span>{{scope.row.fault}}</span>
                    <span>{{scope.row.take}}</span>
                  </template>
                </el-table-column>
                <!-- <el-table-column
                  label="详细地址"
                  prop="NeighbourhoodAddress"
                  align='center'>
                </el-table-column> -->
                <el-table-column
                  label="运营状态"
                  prop="IsOperate"
                  align='center'>
                </el-table-column>
              </el-table>
              <!--分页-->
              <el-col :span="24" class="toolbar" >
                <el-pagination
                  layout="total, prev, pager, next, jumper"
                  @current-change="handleCurrentChange"
                  @prev-click="prevpage"
                  @next-click="nextpage"
                  :page-size="10"
                  :total="equipTotal"
                  style="float:right;"
                ></el-pagination>
              </el-col>
            </div>
          </el-tab-pane>
          <el-tab-pane label="订单" name="second">
            <div class="order">
              <div class="topInfo">
                <p>订单统计</p>
                <div>
                  <p><span>订单总数（笔）</span><b>{{staticInfo.countnum}}</b></p>
                  <p><span>订单金额（元）</span><b>{{staticInfo.sumorderamount}}</b></p>
                  <p><span>总消耗电量（度）</span><b>{{staticInfo.sumTotalElectricity}}</b></p>
                </div>
              </div>
              <div class="search">
                <div class="Btns">
                   <el-button type="primary" @click="toSearch">查询</el-button>
                   <el-button  @click="backAll">返回所有</el-button>
                </div>
                <div class="inputs">
                  <!-- 微信昵称搜索 -->
                  <el-input class="wxName" v-model="orderSearchInfo.data.OrderNum" placeholder="订单编号"></el-input>
                  <el-input class="wxName" v-model="orderSearchInfo.data.Address" placeholder="设备名称"></el-input>
                  <el-input class="wxName" v-model="orderSearchInfo.data.EquipmentNum" placeholder="设备编号"></el-input>
                  <!-- 订单状态 -->
                  <el-select class='select' v-model="state" clearable placeholder="订单状态" >
                    <el-option
                      v-for="item in statusOptions"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value">
                    </el-option>
                  </el-select>
                  <!-- 时间选择 -->
                  <el-date-picker
                   v-model="time"
                   type="datetimerange"
                   range-separator="至"
                   start-placeholder="最小结束时间"
                   end-placeholder="最大结束时间"
                   @change='chooseTime'
                   class='timeSelect'>
                  </el-date-picker>
                </div>
              </div>
              <el-table
                :data="orderInfo"
                style="width: 100%" 
                class="table"
                fit
                border
              >
              <!-- 数据展示区 -->
                <el-table-column
                  label="订单编号"
                  prop="OrderNum"
                  align='center'>
                </el-table-column>
                <el-table-column
                  label="设备信息"
                  prop="Address"
                  align='center'>
                </el-table-column>
                <el-table-column
                  label="消费/耗电量"
                  prop="OrderAmount"
                  align='center'
                  width='100px'>
                  <template  slot-scope="scope" >
                   <p>{{scope.row.OrderAmount }}元</p>
                   <p>{{scope.row.TotalElectricity }}度</p>
                  </template>
                </el-table-column>
                <el-table-column
                  label="用户信息"
                  prop="WeChatName "
                  align='center'>
                  <template  slot-scope="scope" >
                   <p>{{scope.row.WeChatName }}</p>
                   <p>{{scope.row.Phone }}</p>
                  </template>
                </el-table-column>
                <el-table-column
                  label="订单状态"
                  prop="OrderState"
                  align='center'
                  width='100px'>
                  <template slot-scope="scope"><p :style='scope.row.statusStyle' class="orderStatus">{{scope.row.OrderState}}</p><p class="stopWay">{{scope.row.stopWay}}</p></template>
                </el-table-column>
                <el-table-column
                  label="订单时间"
                  prop="BeginTime"
                  align='center'>
                  <template  slot-scope="scope" >
                   <p>开始：{{scope.row.BeginTime }}</p>
                   <p v-if="scope.row.Process == 2 && scope.row.OrderState == '已支付' ">结束：{{scope.row.EndTime }}</p>
                  </template>
                </el-table-column>
              </el-table>
              <!--分页-->
              <el-col :span="24" class="toolbar" >
                <el-pagination
                  layout="total, prev, pager, next, jumper"
                  @current-change="handleCurrent"
                  @prev-click="prev"
                  @next-click="next"
                  :page-size="10"
                  :total="orderTotal"
                  style="float:right;"
                ></el-pagination>
              </el-col>
            </div>
          </el-tab-pane>
          <el-tab-pane label="分润" name="third">
            <div class="benefit">
              <div>
                <span>用户名称</span>
                <span>用户类型</span>
                <span>分润比例(%)</span>
              </div>
              <p v-if="!benefitInfo || benefitInfo.length==0">暂无数据</p>
              <div v-for="(item,index) in benefitInfo" :key="index">
                <span>{{item.CustomerName}}</span>
                <span>{{item.RoleName}}</span>
                <span>{{item.ProfitRatio}}</span>
              </div>
            </div>
          </el-tab-pane>
        </el-tabs>
    </div>
  </div>
</template>

<script>
import {selectNeibourhood,getEquipment,getOrder,getShowDataXiaoQu,getBenefitRulesDatas} from '@/api/public.js';
export default {
  props:{row:{type:Object}},
  data() {
    return {
        activeName:'first',
        neighbourInfo:{},
        equipIndex:1,
        equipSize:10,
        equipTotal:0,
        equipInfo:[],
        benefitInfo:[],
        orderTotal:0,
        orderSearchInfo:{
          pageIndex:1,
          pageSize:10,
          data:{
            Process:-1,
            NeighbourhoodId:this.row.Id,
            OrderState:-1,
          },
        },// 查询条件
        orderInfo:[],
        staticInfo:{
          countnum:0,
          sumorderamount:0,
          sumTotalElectricity:0,
        },
        state:'',
        process:'',
        time:'',
        statusOptions:[
          {value:0,label:'待支付'},
          {value:1,label:'已支付'},
          {value:2,label:'已结束'},
          {value:3,label:'退款中'},
          {value:4,label:'已退款'},
        ],
    }
  },

  components: {},

  computed: {},

  mounted() {
    this.init();
    this.getEquip();
    this.getOrderInfo();
  },

  methods: {
    async getEquip(){
        await getEquipment({data:{NeighbourhoodId:this.row.Id,EquipmentState:-1,IsOperate:-1},pageIndex:this.equipIndex,pageSize:this.equipSize}).then(res=>{
            if(res.Success){
                this.equipInfo=res.Data.data;
                this.equipTotal=res.Data.totalCount;
                this.equipInfo.forEach(element=>{
                    element.EquipmentState=element.EquipmentState == 0 ? '待激活' :(element.EquipmentState == 1 ? '启用' : '下线');
                    element.IsOperate=element.IsOperate == 0 ? '停用' : '启用';
                    // 端口状态
                    element.free=0;
                    element.take=0;
                    element.fault=0;
                    let portState=element.PortState?element.PortState.split(','):[];
                    portState.forEach(ele=>{
                      if(ele==0){
                        element.free++;
                      }else if(ele==1){
                        element.take++;
                      }else{
                        element.fault++;
                      }
                    })
                })
                
            }
        })
    },
    async init(){
        await selectNeibourhood({Id:this.row.Id}).then(res=>{
            if(res.Success){
                this.neighbourInfo=res.Data[0];
                this.neighbourInfo.InstallDate=this.timeToTime(this.neighbourInfo.InstallDate.substring(6,19));
            }
        })
        await getBenefitRulesDatas({Id:this.row.Id}).then(res=>{
          if(res.Success){
            this.benefitInfo=res.Data;
          }
        })
        await getShowDataXiaoQu({NeighbourhoodId:this.row.Id}).then(res=>{
          if(res.Success){
            this.staticInfo=res.Data;
          }
        })
    },
    async getOrderInfo(){
      await getOrder(this.orderSearchInfo).then(res=>{
        if(res.Success){
          this.orderInfo=res.Data.data;
          this.orderTotal=res.Data.totalCount;
          this.orderInfo.forEach(element=>{
            element.BeginTime=this.timeToTime(element.BeginTime.substring(6,19));
            element.EndTime=element.Process == 2 && element.OrderState == 1 ? this.timeToTime(element.EndTime.substring(6,19)) : '/';
            element.OrderState=element.OrderState==0 ? '待支付' : (element.OrderState== 1 ? '已支付' : (element.OrderState== 2 ? '已结束' : (element.OrderState== 3 ? '退款中' : '已退款' ) ) );
          })
        }
      })
    },
    // 时间戳转时间
    timeToTime(time){
       const date = new Date(Number(time))
       const Y = date.getFullYear() // 年
       const M = date.getMonth() + 1 < 10 ? '0'+(date.getMonth()+1) : date.getMonth()+1 // 月
       const D = date.getDate() < 10 ? '0' + date.getDate() : date.getDate() // 日
       const H = date.getHours() < 10 ? '0' + date.getHours() : date.getHours() // 时
       const m = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes() // 分
       const S = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds() // 秒 
       const Time=`${Y}-${M}-${D} ${H}:${m}:${S}`;
       return Time;
    },
    // 中国标准时间转年月日
    timeChange(time,ifend){
      var chinaStandard=time;
        var date = new Date(chinaStandard);
        var y = date.getFullYear();
        var m = date.getMonth() + 1;
        m = m < 10 ? ('0' + m) : m;
        var d = date.getDate();
        d = d < 10 ? ('0' + d) : d;
        var h = date.getHours();
        var minute = date.getMinutes();
        minute = minute < 10 ? ('0' + minute) : minute;
        var second = date.getSeconds();
        second = second < 10 ? ('0' + second) : second;
        let Time;
        if(ifend){
          Time = y + '/' + m + '/' + d+' 23:59:59';
        }else{
          Time = y + '/' + m + '/' + d+' '+h+':'+minute+ ':' + second;
        }
        return Time;
    },
    // 时间选择
    chooseTime(e){
      this.searchInfo.data.SBeginTime= this.timeChange(e[0]);
      this.searchInfo.data.SEndTime= this.timeChange(e[1]);
    },
    // 条件查询
    toSearch(){
      this.orderSearchInfo.data.OrderState=this.state !==''  ? this.state : this.orderSearchInfo.data.OrderState; 
      this.orderSearchInfo.data.Process=this.process !==''  ? this.process : this.orderSearchInfo.data.Process; 
      this.orderSearchInfo.pageIndex=1;
      this.getOrderInfo();
    },
    // 清除查询条件
    backAll(){
      this.orderSearchInfo.pageIndex=1;
      this.orderSearchInfo.data={
        Process:-1,
        OrderState:-1,
        NeighbourhoodId:this.row.Id,
      };
      this.time='';
      this.state='';
      this.process='';
      this.getOrderInfo();
    },
    // 设备分页
    handleCurrentChange(e){
      this.equipIndex=e;
      this.getEquip();  
    },
    prevpage(e){
      this.equipIndex=e;
      this.getEquip();  
    },
    nextpage(e){
      this.equipIndex=e;
      this.getEquip();  
    },
    // 订单分页
    handleCurrent(e){
      this.orderSearchInfo.pageIndex=e;
      this.getOrderInfo();
    },
    prev(e){
      this.orderSearchInfo.pageIndex=e;
      this.getOrderInfo();
    },
    next(e){
      this.orderSearchInfo.pageIndex=e;
      this.getOrderInfo();
    },
  },
};
</script>
<style scoped lang='scss'>
 .el-drawer{
   overflow:auto !important;
 }
.detail{
    padding: 15px;
    background: #f1f1f1;
    height: 100%;
    width: 100%;
}
.basicInfo{
    background: #fff;
    padding: 30px;
    margin-bottom: 20px;
    div{
        display: flex;
        font-size: 14px;
        p{
            width: 25%;
            height: 37px;
            line-height: 37px;
            box-sizing: border-box;
            border: solid 1px #f0f0f0;
            display: flex;
            &:nth-child(2){
                width: 50%;
            }
            span{
                display: inline-block;
                width: 100px;
                background:  #fafafa;
            }
            b{
                flex: 1;
                font-weight: 400;
            }
        }
    }
    // .one,.two{
    //     p{
    //        &:nth-child(2){
    //         width: 25%;
    //        }
    //        &:nth-child(3){
    //         width: 35%;
    //        }
    //     }
    // }
}
.tab{
  background: #fff;
  padding: 30px;
    .equip{
        span{
              display: inline-block;
              padding: 0 6px;
              margin-right: 5px;
              border-radius: 3px;
              &:nth-child(1){
                color: #88d75f;
                border: #88d75f solid 1px;
                background: #f6ffed;
              }
              &:nth-child(2){
                color: #ff696b;
                border: #ff696b solid 1px;
                background: #fff2f0;
              }
              &:nth-child(3){
                color: #3aa1ff;
                border: #3aa1ff solid 1px;
                background: #e6f7ff;
              }
        }
    }
    .benefit{
      display: flex;
      flex-direction: column;
      border-bottom: solid 1px #f0f0f0;
      p{
        height: 50px;
        line-height: 50px;
        text-align: center;
        border-left: solid #f0f0f0 1px;
        border-right: solid #f0f0f0 1px;
        color: #8c8c8c;
        font-size: 14px;
      }
      div{
        width: 100%;
        box-sizing: border-box;
        display: flex;
        margin-bottom: 0 !important;
        height: 40px!important;
        &:nth-child(1){
          background: #fafafa;
        }
        span{
          display: inline-block;
          width: calc(100% / 3);
          border: solid 1px #f0f0f0;
          height: 40px;
          line-height: 40px;
          font-size: 14px;
        }
      }
    }
    .order{
      .topInfo{
        border: solid #f0f0f0 1px;
        padding: 10px;
        margin-bottom: 30px;
        p{
          text-align: left;
          height: 20px;
          line-height: 20px;
          border-bottom: solid #f0f0f0 1px;
          font-size: 14px;
        }
        div{
          display: flex;
          height: 80px;
          align-items: center;
          p{
            border: 0;
            width: calc(100% /3);
            display: flex;
            flex-direction: column;
            align-items: center;
            span{
              font-size: 14px;
              color: #8c8c8c;
              margin-bottom: 10px;
            }
            b{
              font-weight: 500;
              font-size: 18px;
            }
          }
        }
      }
      .search{
        margin-bottom: 30px;
          .inputs{
            display: flex;
            justify-content: space-between;
          }
          .wxName,.select,.timeSelect{
            width: 13%;
            margin-right: 15px;
          }
          .timeSelect{
            flex: 1;
          }
          .Btns{
            display: flex;
            justify-content: flex-end;
            margin-bottom: 10px;
          }
      }
      .search .wxName[data-v-0dade7ca], .search .select[data-v-0dade7ca], .search .timeSelect[data-v-0dade7ca]{
        margin-right: 5px;
      }
    }
}
</style>
