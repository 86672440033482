<template>
<section>
  <!-- 搜索 -->
  <div class="search">
    <div class="inputs">
      <el-input class="wxName" v-model="searchInfo.TemplateName" placeholder="模板名称"></el-input>
      <div class="Btns">
       <el-button type="primary" @click="toSearch">查询</el-button>
       <el-button  @click="backAll">返回所有</el-button>
    </div>
    </div>
  </div>
  <div class="role">
    <el-button type="primary" @click="handleAdd">添加</el-button>
  </div>
  <div>
    <el-table
      :data="consumptionData"
      style="width: 100%"
      row-key="id"
      default-expand-all
      :tree-props="{children: 'children', hasChildren: 'hasChildren'}"
    >
      <af-table-column
        prop="Id"
        align="center"
        label="ID">
      </af-table-column>
      <af-table-column
        prop="TemplateName"
        align="center"
        label="模板名称">
      </af-table-column>
      <af-table-column
        align="center"
        prop="CreateName"
        label="创建人">
      </af-table-column>
      <af-table-column
        align="center"
        prop="CreateTime"
        label="创建时间">
      </af-table-column>
      <af-table-column
        align="center"
        prop="Remarks"
        label="备注">
      </af-table-column>
      <el-table-column label="操作" fixed="right" align='center' min-width="200">
        <template slot-scope="scope">
          <el-button
            size="mini"
            @click="handleEdit(scope.$index, scope.row)">编辑</el-button>
            <el-button
            size="mini"
            @click="handleDetail(scope.$index, scope.row)">详情</el-button>
            <el-button
            size="mini"
            @click="handleDel(scope.$index, scope.row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>

    <!--分页-->
    <el-col :span="24" class="toolbar pagination" >
      <el-pagination
        layout="total, prev, pager, next, jumper"
        @current-change="handleCurrentChange"
        @prev-click="prevpage"
        @next-click="nextpage"
        :page-size="10"
        :total="total"
        :current-page="currentPage"
        style="float:right;"
      ></el-pagination>
    </el-col>

    <!-- 新增菜单 -->
    <el-drawer
      title="新增"
      :visible.sync="addDrawer"
      :direction="direction"
      :before-close="handleClose"
      size='50%'
      style="overflow:auto">
      <add v-if="addDrawer" @close='handleClose' @success="handleDrawerSuccess(form)  "  ref='newForm' :id="id" :row="row"></add>
    </el-drawer>
    <!-- 修改模板 -->
    <el-drawer
      title="修改"
      :visible.sync="editDrawer"
      :direction="direction"
      :before-close="handleClose"
      size='50%'>
      <edit v-if="editDrawer" @close='handleClose' @success="handleDrawerSuccess(form)  "  ref='newForm' :id="id" :row="row"></edit>
    </el-drawer>
    <!-- 模板详情 -->
    <el-drawer
      title="详情"
      :visible.sync="detailDrawer"
      :direction="direction"
      :before-close="handleClose"
      size='50%'>
      <detail v-if="detailDrawer" @close='handleClose' @success="handleDrawerSuccess(form)  "  ref='newForm' :id="id" :row="row"></detail>
    </el-drawer>
  </div>
</section>
  
</template>

<script>
  import {getConsumeTemplateInfo,deleteConsumeTemplate} from '@/api/public.js';
  import add from './addtest.vue';
  import edit from './edit.vue';
  import detail from './detail.vue'

export default {
  data() {
    return {
      consumptionData: [],
      addDrawer:false,
      editDrawer:false,
      detailDrawer:false,
      id:'',
      row:'',
      direction:'rtl',
      total:0,
      wechatUsers:[],
      searchInfo:{
        pageIndex:1,
        pageSize:10,
        Data:{
          TemplateName:'',
        }
      },
      currentPage:1,
    }
  },

  components: {add,edit,detail},

  computed: {},

  mounted() {
    this.init();
  },

  methods: {
    // 页面初始化
    async init(){
      await getConsumeTemplateInfo(this.searchInfo).then(res=>{
        if(res.Success){
          this.consumptionData=res.Data.data;
          this.total=res.Data.totalCount;
          this.consumptionData.forEach(element => {
            element.CreateTime=this.timeToTime(element.CreateTime.substring(6,19));
            for(let key in element){
              if(!element[key] && element[key]!=0){
                element[key]='--';
              }
            }
          });
        }
      });
    },
    // 时间戳转时间
    timeToTime(time){
       const date = new Date(Number(time))
       const Y = date.getFullYear() // 年
       const M = date.getMonth() + 1 < 10 ? '0'+(date.getMonth()+1) : date.getMonth()+1 // 月
       const D = date.getDate() < 10 ? '0' + date.getDate() : date.getDate() // 日
       const H = date.getHours() < 10 ? '0' + date.getHours() : date.getHours() // 时
       const m = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes() // 分
       const S = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds() // 秒 
       const Time=`${Y}-${M}-${D} ${H}:${m}:${S}`;
       return Time;
    },
    // 条件查询
    toSearch(){
      this.searchInfo.pageIndex = 1;
      this.currentPage = 1;
      this.init();
    },
    // 清除查询条件
    backAll(){
      this.searchInfo = {
        pageIndex:1,
        pageSize:10,
        Data:{
          TemplateName:'',
        }
      };
      this.currentPage = 1;
      this.init();
    },
    // 打开新增
    handleAdd(index,row){
      this.addDrawer=true;
    },
    // 打开修改模板
    handleEdit(index,row){
      this.row=row;
      this.editDrawer=true;
      this.id=index;
    },
    // 关闭
    handleClose(){
      this.addDrawer=false;
      this.editDrawer=false;
      this.detailDrawer=false;
      this.init();
    },
    // 删除
    async handleDel(index,row){
      await this.$confirm('确认删除该模板吗？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          deleteConsumeTemplate({Id:row.Id}).then(res=>{
            if(res.Success){
              this.$message({
                message: "删除成功",
                type: "success",
              });
              this.init();
            }
          })
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });          
        });
    },
    // 修改、新增成功关闭抽屉
    handleDrawerSuccess(){
      this.addDrawer=false;
      this.editDrawer=false;
      this.init();
    },
    // 查看详情
    handleDetail(index,row){
      this.row=row;
      this.detailDrawer=true;
    },
    // 分页
    prevpage(val){
      this.searchInfo.pageIndex=val;
      this.init();
    },
    nextpage(val){
      this.searchInfo.pageIndex=val;
      this.init();
    },
    handleCurrentChange(val){
      this.searchInfo.pageIndex =val;
      this.currentPage = val;
      this.init();
    },
  },
};
</script>
<style scoped lang='scss'>
  /* 搜索 */
.search{
  margin-bottom: 30px;
  .inputs{
    display: flex;
    text-align: left;
  }
  .wxName,.select,.timeSelect{
    width: 18%;
    margin-right: 20px;
  }
  .Btns{
    display: flex;
    justify-content: flex-end;
    margin-bottom: 10px;
  }
}
  .role{
    width: 100%;
    background-color: white;
    height: 60px;
    width: 100%;
    padding: 10px 50px;
    position: relative;
  }
  .role button{
    position: absolute;
    right:20px;
    top:10px;
  }
  ::v-deep .el-drawer.rtl{
    overflow: auto !important;
  }
</style>
