<template>
  <section>
    <div class="search">
      <div class="Btns">
        <el-button @click="backToEquip" v-if="$route.query.EquipmentNum" style="float:left;!important">返回设备列表</el-button>
         <el-button type="primary" @click="toSearch">查询</el-button>
         <el-button  @click="backAll">返回所有</el-button>
      </div>
      <div class="inputs">
        <!-- 微信昵称搜索 -->
        <el-input class="wxName" v-model="searchInfo.data.OrderNum" placeholder="订单编号"></el-input>
        <el-input class="wxName" v-model="searchInfo.data.Address" placeholder="设备名称"></el-input>
        <el-input class="wxName" v-model="searchInfo.data.EquipmentNum" placeholder="设备编号"></el-input>
        <!-- 订单状态 -->
        <el-select class='select' v-model="state" clearable placeholder="订单状态" @change="selectStatus">
          <el-option
            v-for="item in statusOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
        <el-select class='select' v-model="process" clearable placeholder="充电进程">
          <el-option
            v-for="item in ProcessOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
        <el-select class='select' v-model="payType" clearable placeholder="支付类型">
          <el-option
            v-for="item in PayTypeOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
        <!-- 时间选择 -->
        <el-date-picker
         v-model="time"
         type="datetimerange"
         range-separator="至"
         start-placeholder="最小结束时间"
         end-placeholder="最大结束时间"
         @change='chooseTime'
         class='timeSelect'>
        </el-date-picker>
      </div>
    </div>
    <div class="container">
      <div class="btns">
        <el-button type="primary" @click="educe">订单导出</el-button>
      </div>
      <el-table
        :data="orderInfo"
        style="width: 100%"
        class="table"
        fit
      >
      <!-- 数据展示区 -->
        <af-table-column
          label="订单编号"
          prop="OrderNum"
          align='center'>
          <template  slot-scope="scope" >
           <p @click="checkDetail(scope.row.OrderNum)" style="cursor:pointer;color:#40a9ff">{{scope.row.OrderNum}}</p>
          </template>
        </af-table-column>
        <af-table-column
          label="订单类型"
          prop="OrderType"
          align='center'>
        </af-table-column>
        <af-table-column
          label="支付类型"
          prop="PayType"
          align='center'>
        </af-table-column>
        <af-table-column
          label="设备名称"
          prop="Address"
          align='center'>
          <template slot-scope="scope">{{!scope.row.Address && scope.row.Address !=0 ? '--' : scope.row.Address}}</template>
        </af-table-column>
        <af-table-column
          label="充电端口"
          prop="EquipmentPort"
          align='center'>
        </af-table-column>
        <af-table-column
          label="所属小区"
          prop="NeighbourhoodName"
          align='center'>
        </af-table-column>
        <af-table-column
          label="预收金额"
          prop="OrderAmount"
          align='center'>
        </af-table-column>
        <af-table-column
          label="结算金额"
          prop="RealAmount"
          align='center'>
        </af-table-column>
        <af-table-column
          label="耗电量"
          prop="TotalElectricity"
          align='center'>
        </af-table-column>
        <af-table-column
          label="订单状态"
          prop="OrderState"
          align='center'>
          <template slot-scope="scope"><p :style='scope.row.statusStyle' class="orderStatus">{{scope.row.OrderState}}</p><p class="stopWay" v-if="scope.row.StopWhy!=0">{{scope.row.StopWhy}}</p></template>
        </af-table-column>
        <af-table-column
          label="充电用户"
          prop="WeChatName"
          min-width="100"
          align='center'>
          <template  slot-scope="scope" >
           <p>{{scope.row.WeChatName }}</p>
           <p>{{scope.row.Phone }}</p>
           <p>{{scope.row.Attribution }}</p>
           <p>{{scope.row.CardId }}</p>
          </template>
        </af-table-column>
        <af-table-column
          label="所属代理"
          prop="CustomerName"
          align='center'>
        </af-table-column>
        <af-table-column
          label="开始时间"
          prop="BeginTime"
          align='center'>
        </af-table-column>
        <af-table-column
          label="结束时间"
          prop="EndTime"
          align='center'>
        </af-table-column>
        <el-table-column label="操作" fixed="right" align='center' min-width="80">
          <template slot-scope="scope">
            <el-button size="mini" @click="handleDetail(scope.$index, scope.row)">详情</el-button>
          </template>
        </el-table-column>
      </el-table>
      
      <el-drawer
        title=""
        :visible.sync="detailDrawer"
        :direction="direction"
        :before-close="handleClose"
        size="70%"
      >
      <detail v-if="detailDrawer" @close='handleClose' @success="handleDrawerSuccess(form)  "  ref='newForm' :id="id" :row="row"></detail>
    </el-drawer>
      <!--分页-->
      <el-col :span="24" class="toolbar" >
        <el-pagination
          layout="total, prev, pager, next, jumper"
          @current-change="handleCurrentChange"
          @prev-click="prevpage"
          @next-click="nextpage"
          :page-size="10"
          :total="total"
          style="float:right;"
        ></el-pagination>
      </el-col>
    </div>
  </section>
</template>

<script>
import {getBatteryOrder,exportBatteryOrder} from '@/api/public.js';
import {baseUrl} from '@/api/url.js';
import detail from './detail.vue'
export default {
  data() {
    return {
      // 订单列表信息
      orderInfo:[],
      statusStyle:'', // 状态样式
      detailDrawer:false,
      id:'',
      row:'',
      direction:'rtl',
      // 分页样式
      total:0,
      searchInfo:{
        pageIndex:1,
        pageSize:10,
        data:{
          EquipmentNum:'',
          OrderNum:'',
          Address:'',
          Process:-1,
          OrderState:-1,
          PayType:-1,
        },
      },// 查询条件
      state:'',
      process:'',
      payType:'',
      time:'',
      statusOptions:[
        {value:-1,label:'请选择'},
        {value:0,label:'待支付'},
        {value:1,label:'已支付'},
        {value:2,label:'已结束'},
        {value:3,label:'退款中'},
        {value:4,label:'已退款'},
      ],
      ProcessOptions:[
        {value:0,label:'未开始'},
        {value:1,label:'充电中'},
        {value:2,label:'已完成'},
      ],
      PayTypeOptions:[
        {value:0,label:'微信支付'},
        {value:1,label:'余额支付'},
        {value:2,label:'内部员工'},
        {value:3,label:'刷卡充电'},
      ],
    }
  },

  components: {detail},

  computed: {},

  mounted() {
    this.searchInfo.data.EquipmentNum=this.$route.query.EquipmentNum ? this.$route.query.EquipmentNum :'';
    this.init();
  },

  methods: {
    backToEquip(){
      this.$router.go(-1);
    },
    selectStatus(e){
    },
    // 初始化
    async init(){
      await getBatteryOrder(this.searchInfo).then(res=>{
        if(res.Success){
          this.orderInfo=res.Data.data;
          this.orderInfo.forEach(element => {
            let type=element.OrderNum.substring(element.OrderNum.length-1,element.OrderNum.length);
            // 1 时间模式  2功率模式充满自离 3功率模式时间计费 4功率模式金额计费  5电量模式金额计费 6 电量模式时间计费 7电量模式充满自离
            if(type==1){
              element.OrderType='时间模式';
            }else if(type==2){
              element.OrderType='功率模式充满自离';
            }else if(type==3){
              element.OrderType='功率模式时间计费';
            }else if(type==4){
              element.OrderType='功率模式金额计费';
            }else if(type==5){
              element.OrderType='电量模式金额计费';
            }else if(type==6){
              element.OrderType='电量模式时间计费';
            }else if(type==7){
              element.OrderType='电量模式充满自离';
            }
            element.BeginTime=this.timeToTime(element.BeginTime.substring(6,19));
            // 只有充电结束后才显示结束时间
            element.EndTime=element.Process == 2 && element.OrderState == 2 ? this.timeToTime(element.EndTime.substring(6,19)) : '--';
            element.Process=element.Process==0 ? '待支付' : (element.OrderState==1 && element.Process== 0 ? '已支付' : (element.OrderState==1 && element.Process==1?'充电中':'充电完成') );
            element.PayType=element.PayType === 0 ? '微信支付' : (element.PayType === 1 ? '余额支付':(element.PayType === 2 ? '内部员工' :'刷卡充电'));
            element.OrderState=element.OrderState==0 ? '待支付' : (element.OrderState== 1 ? '已支付' : (element.OrderState== 2 ? '已结束' : (element.OrderState== 3 ? '退款中' : '已退款' ) ) );
            element.RealAmount=element.RealAmount==0 ? '待结算' : element.RealAmount;
            element.TotalElectricity = element.TotalElectricity==null?'待结算':element.TotalElectricity;
            // 停止充电原因
            if(element.StopWhy==1){ 
              element.StopWhy='充满自停'
            }else if(element.StopWhy==2){
              element.StopWhy='达到最大充电时间'
            }else if(element.StopWhy==3){
              element.StopWhy='达到预设时间'
            }else if(element.StopWhy==4){
              element.StopWhy='达到预设电量'
            }else if(element.StopWhy==5){
              element.StopWhy='用户拔出'
            }else if(element.StopWhy==6){
              element.StopWhy='负载过大'
            }else if(element.StopWhy==7){
              element.StopWhy='服务器控制停止'
            }else if(element.StopWhy==8){
              element.StopWhy='动态过载'
            }else if(element.StopWhy==9){
              element.StopWhy='功率过小'
            }
          });
          this.total=res.Data.totalCount;
        }
      })
    },
    // 时间戳转时间
    timeToTime(time){
       const date = new Date(Number(time))
       const Y = date.getFullYear() // 年
       const M = date.getMonth() + 1 < 10 ? '0'+(date.getMonth()+1) : date.getMonth()+1 // 月
       const D = date.getDate() < 10 ? '0' + date.getDate() : date.getDate() // 日
       const H = date.getHours() < 10 ? '0' + date.getHours() : date.getHours() // 时
       const m = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes() // 分
       const S = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds() // 秒 
       const Time=`${Y}-${M}-${D} ${H}:${m}:${S}`;
       return Time;
    },
    // 中国标准时间转年月日
    timeChange(time,ifend){
      var chinaStandard=time;
        var date = new Date(chinaStandard);
        var y = date.getFullYear();
        var m = date.getMonth() + 1;
        m = m < 10 ? ('0' + m) : m;
        var d = date.getDate();
        d = d < 10 ? ('0' + d) : d;
        var h = date.getHours();
        var minute = date.getMinutes();
        minute = minute < 10 ? ('0' + minute) : minute;
        var second = date.getSeconds();
        second = second < 10 ? ('0' + second) : second;
        let Time;
        if(ifend){
          Time = y + '/' + m + '/' + d+' 23:59:59';
        }else{
          Time = y + '/' + m + '/' + d+' '+h+':'+minute+ ':' + second;
        }
        return Time;
    },
    // 导出订单
    async educe(){
      await exportBatteryOrder(this.searchInfo.data).then(res=>{
        if(res.Success){
          window.open(baseUrl+'/'+res.Data);
        }else{
          this.$message({
            message: "查询不到该类数据",
            type: "fail",
          });
        }
      })
    },
    // 查看详情
    handleDetail(index,row){
      this.row={orderNum:row.OrderNum};
      this.detailDrawer=true;
    },
    // 新增、绑定、编辑成功
    handleDrawerSuccess(){
      this.detailDrawer=false;
    },
    // 关闭
    handleClose(){
      this.detailDrawer=false;
      this.init();
    },
    // 时间选择
    chooseTime(e){
      this.searchInfo.data.SBeginTime= this.timeChange(e[0]);
      this.searchInfo.data.SEndTime= this.timeChange(e[1]);
    },
    // 条件查询
    toSearch(){
      this.searchInfo.data.OrderState=this.state !==''  ? this.state : -1;
      this.searchInfo.data.Process=this.process !==''  ? this.process : -1;
      this.searchInfo.data.PayType=this.payType !==''  ? this.payType : -1;
      this.searchInfo.pageIndex=1;
      this.init();
    },
    // 清除查询条件
    backAll(){
      this.searchInfo.pageIndex=1;
      this.searchInfo.data={
        EquipmentNum:'',
        OrderNum:'',
        Address:'',
        Process:-1,
        OrderState:-1,
        PayType:-1,
      };
      this.time='';
      this.state='';
      this.process='';
      this.payType="";
      this.init();
    },
    // 分页
    handleCurrentChange(val){
      this.searchInfo.pageIndex=val;
      this.init();
    },
    prevpage(e){
      this.searchInfo.pageIndex=e;
      this.init();
    },
    nextpage(e){
      this.searchInfo.pageIndex=e;
      this.init();
    },
  },
};
</script>
<style scoped lang='scss'>

 .search{
  margin-bottom: 30px;
    .inputs{
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
    }
    .wxName,.select,.timeSelect{
      width: 13%;
      margin-right: 15px;
    }
    .timeSelect{
      flex: 1;
    }
    .Btns{
      display: flex;
      justify-content: flex-end;
      margin-bottom: 10px;
    }
  }
  .search .wxName[data-v-0dade7ca], .search .select[data-v-0dade7ca], .search .timeSelect[data-v-0dade7ca]{
    margin-right: 5px;
  }
  
// 按钮
  .btns{
    width: 100%;
    background-color: white;
    height: 60px;
    width: 100%;
    padding: 10px 50px;
    position: relative;
    button{
      position: absolute;
      right:20px;
      top:10px;
    }
  }
  

.el-table >>> .cell {
  white-space: pre-line;
}
.el-drawer.rtl{
  overflow-y: auto !important;
}

.equipName{
  margin-bottom: 10px;
}
.equipNumber{
  color: #40a9ff;
}
.number{
  display: inline-block;
  height: 20px;
  width: 20px;
  padding: 0 2px;
  border: solid 1px #91d5ff;
  color:#40a9ff;
  background: #e6f7ff;
  border-radius: 3px;
  margin-left: 10px;
  text-align: center;
  font-size: 14px;
}
.el-tabs__content{
  overflow:auto !important;
}
.orderStatus{
  background:#40a9ff;
  color:#fff;
  display: inline-block;
  padding: 3px 5px;
  border-radius: 5px;
}

.stopWay{
  color: #40a9ff;
  margin-top: 10px;
}
</style>
