<template>
    <section>
      <el-form ref="form" label-position="right" status-icon :model="form" label-width="120px" style="width:95%;overflow:auto">
        <el-form-item label="电动车消费模板" prop="ConsumeBatteryTemplateId" >
          <el-select v-model="form.ConsumeBatteryTemplateId" placeholder="请选择" style="width:100%;">
            <el-option
              v-for="item in batteryCarTemplateOptions"
              :key="item.Id"
              :label="item.TemplateName"
              :value="item.Id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="汽车消费模板" prop="ConsumeTemplateId" >
          <el-select v-model="form.ConsumeTemplateId" placeholder="请选择" style="width:100%;">
            <el-option
              v-for="item in carTemplateOptions"
              :key="item.Id"
              :label="item.TemplateName"
              :value="item.Id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button @click="close('form')">取消</el-button>
          <el-button type="primary" @click="edit('form')" :plain='true'>提交</el-button>
        </el-form-item>
      </el-form>
    </section>
  </template>
  
  <script>
  let _this;
  import {SetConsumeTemplate,GetConsumeTemplateInfo,getConsumeTemplateBatteryCarInfo} from '@/api/public.js';
  
  export default {
    props:{row:{type:Object}},
    data() {
      return {
        form:{},
        carTemplateOptions:[],
        batteryCarTemplateOptions:[],
      };
    },
  
    components: {},
  
    computed: {},
  
    mounted() {
      _this=this;
      this.form=this.row;
      this.init();
    },
  
    methods: {
        close(){
          _this.$emit("close");
        },
        init(){
            GetConsumeTemplateInfo().then(res=>{
                if(res.Success){
                    this.carTemplateOptions=res.Data;
                }
            })
            getConsumeTemplateBatteryCarInfo().then(res=>{
                if(res.Success){
                    this.batteryCarTemplateOptions=res.Data;
                }
            })
        },
        // 提交
        edit(form) {
           _this.$refs["form"].validate(valid => {
              if (valid) {
                SetConsumeTemplate(_this.form)
                  .then((res) => {
                    if (res.Success) {
                      _this.$message({
                        message: "提交成功",
                        type: "success",
                      });
                      setTimeout(() => {
                        _this.$emit("success");
                        _this.init();
                      }, 1000);
                    }else{
                      _this.$message({
                        message: "提交失败，请重试",
                        type: "fail",
                      });
                    }
                  })
                  .catch(error => {});
              } else {
                return false;
              }
          });
        },
    }
  };
  </script>
  <style lang="scss" scoped>
  
  
   .el-drawer{
     overflow:auto !important;
   }
   .avatar-uploader .el-upload {
      cursor: pointer;
      position: relative;
      overflow: hidden;
    }
    .avatar-uploader .el-upload:hover {
      border-color: #409EFF;
    }
    .avatar-uploader-icon {
      border: 1px dashed #ccc;
      border-radius: 50%;
      overflow:hidden;
      font-size: 28px;
      color: #8c939d;
      width: 178px;
      height: 178px;
      line-height: 178px;
      text-align: center;
    }
    .avatar {
      width: 178px;
      height: 178px;
      display: block;
    }
  </style>
  