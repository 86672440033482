<template>
  <section>
    <div class="search">
      <div class="inputs">
        <!-- 微信昵称搜索 -->
        <el-input class="wxName" v-model="searchInfo.data.WeChatName" placeholder="微信用户昵称"></el-input>
        <div class="Btns">
         <el-button type="primary" @click="toSearch">查询</el-button>
         <el-button  @click="backAll">返回所有</el-button>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="btns">
        <el-button type="primary" @click="educe">导出</el-button>
      </div>
      <el-table
        :data="topUpInfo"
        style="width: 100%" 
        class="table"
        fit
      >
      <!-- 数据展示区 -->
        <el-table-column
          label="充值单号"
          prop="StoredNum"
          align='center'>
        </el-table-column>
        <el-table-column
          label="充值金额"
          prop="OrderAmount"
          align='center'>
        </el-table-column>
        <el-table-column
          label="用户昵称"
          prop="WeChatName"
          align='center'>
        </el-table-column>
        <el-table-column
          label="充值状态"
          prop="OrderState"
          align='center'>
        </el-table-column>
        <el-table-column
          label="创建时间"
          prop="CreateTime"
          align='center'>
        </el-table-column>
      </el-table>

      <!--分页-->
      <el-col :span="24" class="toolbar" >
        <el-pagination
          layout="total, prev, pager, next, jumper"
          @current-change="handleCurrentChange"
          @prev-click="prevpage"
          @next-click="nextpage"
          :page-size="10"
          :total="total"
          style="float:right;"
        ></el-pagination>
      </el-col>
    </div>
  </section>
</template>

<script>
import {getStoredValueInfo,exportStoredValue} from '@/api/public.js';
import {baseUrl} from '@/api/url.js';
export default {
  data() {
    return {
      // 订单列表信息
      topUpInfo:[],
      // 分页样式
      total:0,
      searchInfo:{
        pageIndex:1,
        pageSize:10,
        data:{
          WeChatName:'',
        },
      },// 查询条件
    }
  },

  components: {},

  computed: {},

  mounted() {
    this.init();
  },

  methods: {
    // 初始化
    async init(){
      await getStoredValueInfo(this.searchInfo).then(res=>{
        if(res.Success){
          this.topUpInfo=res.Data.data;
          this.topUpInfo.forEach(element => {
            element.CreateTime=this.timeToTime(element.CreateTime.substring(6,19));
            element.OrderState=this.OrderState==0?'未支付':(element.OrderState==1?'支付成功':'异常');
          });
          this.total=res.Data.totalCount;
        }
      })
    },
    // 时间戳转时间 timestamp
    timeToTime(time){
       const date = new Date(Number(time));
       const Y = date.getFullYear(); // 年
       const M = date.getMonth() + 1 < 10 ? '0'+(date.getMonth()+1) : date.getMonth()+1; // 月
       const D = date.getDate() < 10 ? '0' + date.getDate() : date.getDate(); // 日
       const H = date.getHours() < 10 ? '0' + date.getHours() : date.getHours(); // 时
       const m = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes(); // 分
       const S = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds(); // 秒 
       const Time=`${Y}-${M}-${D} ${H}:${m}:${S}`;
       return Time;
    },
    // 导出
    async educe(){
      await exportStoredValue(this.searchInfo.data).then(res=>{
        if(res.Success){
          window.open(baseUrl+'/'+res.Data);
        }else{
          this.$message({
            message: "查询不到该类数据",
            type: "fail",
          });
        }
      })
    },
    // 条件查询
    toSearch(){
      this.searchInfo.pageIndex=1;
      this.init();
    },
    // 清除查询条件
    backAll(){
      this.searchInfo.pageIndex=1;
      this.searchInfo.data={
        WeChatName:'',
      };
      this.init();
    },
    // 分页
    handleCurrentChange(val){
      this.searchInfo.pageIndex=val;
      this.init();
    },
    prevpage(e){
      this.searchInfo.pageIndex=e;
      this.init();
    },
    nextpage(e){
      this.searchInfo.pageIndex=e;
      this.init();
    },
  },
};
</script>
<style scoped lang='scss'>

 .search{
  margin-bottom: 30px;
    .inputs{
      display: flex;
    }
    .wxName,.select,.timeSelect{
      width: 20%;
      margin-right: 15px;
    }
    .timeSelect{
      flex: 1;
    }
    .Btns{
      display: flex;
      margin-bottom: 10px;
    }
  }
  .search .wxName[data-v-0dade7ca], .search .select[data-v-0dade7ca], .search .timeSelect[data-v-0dade7ca]{
    margin-right: 5px;
  }
  
// 按钮
  .btns{
    width: 100%;
    background-color: white;
    height: 60px;
    width: 100%;
    padding: 10px 50px;
    position: relative;
    button{
      position: absolute;
      right:20px;
      top:10px;
    }
  }
  

.el-table >>> .cell {
  white-space: pre-line;
}
.el-drawer.rtl{
  overflow-y: auto !important;
}

.equipName{
  margin-bottom: 10px;
}
.equipNumber{
  color: #40a9ff;
}
.number{
  display: inline-block;
  height: 20px;
  width: 20px;
  padding: 0 2px;
  border: solid 1px #91d5ff;
  color:#40a9ff;
  background: #e6f7ff;
  border-radius: 3px;
  margin-left: 10px;
  text-align: center;
  font-size: 14px;
}
.el-tabs__content{
  overflow:auto !important;
}
.orderStatus{
  background:#40a9ff;
  color:#fff;
  display: inline-block;
  padding: 3px 5px;
  border-radius: 5px;
}

.stopWay{
  color: #40a9ff;
  margin-top: 10px;
}
</style>
