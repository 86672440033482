<template>
    <section>
      <el-form ref="form" label-position="right" status-icon :model="form" :rules="rules" label-width="150px" style="width:95%;overflow:auto">
        <el-form-item label="卡号" prop="CardNum">
          <el-input placeholder="请输入卡号" v-model="form.CardNum">
          </el-input>
        </el-form-item>
        <el-form-item label="余额" prop="AccountAmount">
          <el-input type="Number" placeholder="请输入卡余额" v-model="form.AccountAmount">
          </el-input>
        </el-form-item>
        <el-form-item label="赠送余额" prop="GiveAmount">
          <el-input type="Number" placeholder="请输入赠送余额" v-model="form.GiveAmount">
          </el-input>
        </el-form-item>
        <el-form-item label="郑州设备读取卡号" prop="ZZCardNum">
          <el-input placeholder="请输入郑州设备读取卡号" v-model="form.ZZCardNum">
          </el-input>
        </el-form-item>
        <el-form-item label="卡类型" prop="CardType">
          <el-select class='select' v-model="form.CardType" placeholder="请选择">
            <el-option
              v-for="item in CardTypeOptions"
              :key="item.code"
              :label="item.Name"
              :value="item.code">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="优惠类型" prop="PreferentialType" v-if="form.CardType == 3">
          <el-select class='select' v-model="form.PreferentialType" placeholder="请选择">
            <el-option
              v-for="item in PreferentialTypeOptions"
              :key="item.code"
              :label="item.Name"
              :value="item.code">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="卡可用类型" prop="CarType">
          <el-select class='select' v-model="form.CarType" placeholder="请选择" >
            <el-option
              v-for="item in CarTypeOptions"
              :key="item.code"
              :label="item.Name"
              :value="item.code">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="可用小区" prop="Nid" >
          <el-select class='select' v-model="form.Nid" placeholder="请选择" >
            <el-option
              v-for="item in NidOptions"
              :key="item.Id"
              :label="item.NeighbourhoodName"
              :value="item.Id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="备注" prop="Rmk">
          <el-input placeholder="请输入备注" v-model="form.Rmk" type="textarea">
          </el-input>
        </el-form-item>
        <el-form-item>
          <el-button @click="close('form')">取消</el-button>
          <el-button type="primary" @click="handleSubmit('form')" :plain='true'>提交</el-button>
        </el-form-item>
      </el-form>
    </section>
  </template>
  
  <script>
  let _this;
  import {addStoredValueCard,getWechatDictionariesList,getNidList,editStoredValueCard} from '@/api/public.js';
  //  自定义表单验证规则
  var checkAmount = (rule, value, callback) => {
    if (value<0) {
      return callback(new Error('金额不得小于零'));
    }else{
        callback();
    }
  };
  export default {
    props:['row'],
    data() {
      return {
        form:{ // 表单数据
            CardNum:'',
            CardPwd:'',
            UserName:'',
            AccountAmount:0,
            GiveAmount:0,
            State:'',
        },
        userRole:sessionStorage.getItem('roleId'),
        rules: { // 表单验证
          CardNum: [
            { required: true, message: "卡号不得为空", trigger: "blur" },
          ],
          CardPwd: [
            { required: true, message: "卡密码不得为空", trigger: "blur" },
          ],
          UserName: [
            { required: true, message: "用户昵称不得为空", trigger: "blur" },
          ],
          AccountAmount: [
            { required: true, message: "卡内金额不得为空", trigger: "blur" },
            { validator: checkAmount, trigger: 'blur' }
          ],
          State: [
            { required: true, message: "卡状态不得为空", trigger: "change" },
          ],
        },
        CardTypeOptions:[], // 卡类型选项
        CarTypeOptions:[], // 卡可用类型选项
        PreferentialTypeOptions:[], // 优惠类型选项
        NidOptions:[], // 可用小区选项，登陆账号为管理员时，多一个 通用 0
      };
    },
  
    components: {},
  
    computed: {},
  
    mounted() {
      if(this.row){
        this.form = this.row;
        this.form.CardType = ''+ this.form.CardType;
        this.form.PreferentialType = ''+ this.form.PreferentialType;
        this.form.CarType = ''+ this.form.CarType;
      }
      
      _this=this;
      this.getOptions('CarType');
      this.getOptions('CardType');
      this.getOptions('WXUserMod');
      this.getNidOptions()
    },
  
    methods: {
      // 获取各类选项
      async getOptions(sign){
        await getWechatDictionariesList({sign:sign}).then(res=>{
          if(res.Success){
            if(sign == 'CardType'){
              this.CardTypeOptions = [...res.Data];
            }else if(sign == 'CarType'){
              this.CarTypeOptions = [...res.Data];
            }else if(sign == 'WXUserMod'){
              this.PreferentialTypeOptions = [...res.Data];
            }
          }
        })
      },
      async getNidOptions(){
        this.NidOptions = [];
        await getNidList().then(res=>{
          if(res.Success){
            this.NidOptions = res.Data;
            if(this.userRole == 3){
              this.NidOptions.unshift({Id:0,NeighbourhoodName:'通用'}) 
            }
          }else{
            this.NidOptions = [];
          }
        })
      },
       changeCardNumber(e){ 
        let  num='0x'+e//0x的意思为：在数字前面加上0x，表示十六进制的数
        this.form.CardNum=Number(num);
       },
       close(){
          _this.$emit('close');
       },
       selectState(e){
       },
       async add(){
        await addStoredValueCard(this.form).then((res) => {
          if (res.Success) {
            _this.$message({
              message: "提交成功",
              type: "success",
            });
            setTimeout(() => {
              _this.$emit('close');
            }, 1000);
          }else{
            _this.$message({
              message: "提交失败,请重试",
              type: "fail",
            });
          }
        })
       },
      async edit(){
        await editStoredValueCard(this.form).then((res) => {
          if (res.Success) {
            _this.$message({
              message: "提交成功",
              type: "success",
            });
            setTimeout(() => {
              _this.$emit('close');
            }, 1000);
          }else{
            _this.$message({
              message: "提交失败,请重试",
              type: "fail",
            });
          }
        })
       },
       //  提交
       handleSubmit(form) {
          _this.$refs["form"].validate(valid => {
            if (valid) {
                this.form.AccountAmount=Number(this.form.AccountAmount)
                if(this.row){
                  this.edit();
                }else{
                  this.add();
                }
            } else {
              return false;
            }
        });
      },
    }
  };
  </script>
  <style lang="scss" scoped>
   .el-drawer{
     overflow:auto !important;
   }
   .el-select{
    width: 100%;
   }
   .avatar-uploader .el-upload {
      cursor: pointer;
      position: relative;
      overflow: hidden;
    }
    .avatar-uploader .el-upload:hover {
      border-color: #409EFF;
    }
    .avatar-uploader-icon {
      border: 1px dashed #ccc;
      border-radius: 50%;
      overflow:hidden;
      font-size: 28px;
      color: #8c939d;
      width: 178px;
      height: 178px;
      line-height: 178px;
      text-align: center;
    }
    .avatar {
      width: 178px;
      height: 178px;
      display: block;
    }
  </style>
  