<template>
  <section>
    <el-form ref="form" label-position="right" status-icon :model="form" :rules="rules" label-width="100px" style="width:95%;overflow:auto">
      <el-form-item label="角色名称" prop="RoleName" >
        <el-input placeholder="请输入角色名" v-model="form.RoleName" required>
        </el-input>
      </el-form-item>
      <el-form-item>
        <el-button @click="close('form')">取消</el-button>
        <el-button type="primary" @click="addRole('form')" :plain='true'>立即添加</el-button>
      </el-form-item>
    </el-form>
  </section>
</template>

<script>
let _this;
import {addRole} from '@/api/public.js';

export default {
  data() {
    return {
      form:{},
      imageUrl:'',
      value:[],
      options:[],
      show:true,
      rules: {
        RoleName: [
          { required: true, message: "角色名不得为空", trigger: "blur" },
        ],
      },
    };
  },

  components: {},

  computed: {},

  mounted() {
    _this=this;
    _this.init();
  },

  methods: {
    close(){
       _this.$emit('close');
    },
     //  添加
    addRole(form) {
       _this.$refs["form"].validate(valid => {
          if (valid) {
            addRole(_this.form)
              .then((res) => {
                if (res.Success) {
                  _this.$message({
                    message: "添加成功",
                    type: "success",
                  });
                  setTimeout(() => {
                    _this.$emit("success");
                    _this.$emit('close');
                    _this.init();
                  }, 1000);
                }else{
                  _this.$message({
                    message: "添加失败，请重试",
                    type: "fail",
                  });
                }
              })
              .catch(error => {});
          } else {
            return false;
          }
      });
    },
    
  }
};
</script>
<style lang="scss" scoped>
 .el-drawer{
   overflow:auto !important;
 }
 .avatar-uploader .el-upload {
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }
  .avatar-uploader-icon {
    border: 1px dashed #ccc;
    border-radius: 50%;
    overflow:hidden;
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }
  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }
</style>
