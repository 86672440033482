<template>
    <section>
      <el-form ref="form" label-position="right" status-icon :model="form" :rules="rules" label-width="100px" style="width:95%;overflow:auto">
        <el-form-item label="卡号" prop="CardNum">
          <el-input placeholder="请输入卡号" v-model="form.CardNum" disabled>
          </el-input>
        </el-form-item>
        <el-form-item label="微信用户" prop="WeChatUserId">
          <el-select class='select' filterable  remote v-model="form.WeChatUserId" clearable placeholder="输入微信昵称或手机号进行查询"  :remote-method="remoteMethod">
            <el-option
              v-for="item in WeChatUserIdOptions"
              :key="item.Id"
              :label="item.WeChatName + ' - '+ item.Phone"
              :value="item.Id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button @click="close('form')">取消</el-button>
          <el-button type="primary" @click="bind('form')" :plain='true'>绑定</el-button>
        </el-form-item>
      </el-form>
    </section>
  </template>
  
  <script>
  let _this;
  import {storedValueCardBindWechat,selectWechatUser,getWechatUser} from '@/api/public.js';
  
  export default {
    props:{cardNum:{type:String}},
    data() {
      return {
        form:{ // 表单数据
            CardNum:'',
        },
        rules: { // 表单验证
            WeChatUserId: [
            { required: true, message: "微信用户不得为空", trigger: "blur" },
          ],
        },
        WeChatUserIdOptions:[], // 微信用户选项
        searchInfo:{
          pageIndex:1,
          pageSize:10,
          Data:{
            GetWhere:'',
          }
        }
      };
    },
  
    components: {},
  
    computed: {},
  
    mounted() {
      this.form.CardNum=this.cardNum;
      this.init();
      _this=this;
    },
  
    methods: {
        init(){
          getWechatUser(this.searchInfo).then(res=>{
              this.WeChatUserIdOptions=res.Data.data;
          })
        },
        remoteMethod(query){
          this.searchInfo.Data.GetWhere = query;
          this.init();
        },
       close(){
          _this.$emit('close');
       },
       selectState(e){
       },
       //  添加
       bind(form) {
          _this.$refs["form"].validate(valid => {
            if (valid) {
              this.form.AccountAmount=Number(this.form.AccountAmount)
              storedValueCardBindWechat(_this.form)
                .then((res) => {
                  if (res.Success) {
                    _this.$message({
                      message: "绑定成功",
                      type: "success",
                    });
                    setTimeout(() => {
                      _this.$emit("success");
                      _this.$emit('close');
                    }, 1000);
                  }else{
                    _this.$message({
                      message: res.Message,
                      type: "fail",
                    });
                  }
                })
                .catch(error => {});
            } else {
              return false;
            }
        });
      },
    }
  };
  </script>
  <style lang="scss" scoped>
   .el-drawer{
     overflow:auto !important;
   }
   .el-select{
    width: 100%;
   }
   .avatar-uploader .el-upload {
      cursor: pointer;
      position: relative;
      overflow: hidden;
    }
    .avatar-uploader .el-upload:hover {
      border-color: #409EFF;
    }
    .avatar-uploader-icon {
      border: 1px dashed #ccc;
      border-radius: 50%;
      overflow:hidden;
      font-size: 28px;
      color: #8c939d;
      width: 178px;
      height: 178px;
      line-height: 178px;
      text-align: center;
    }
    .avatar {
      width: 178px;
      height: 178px;
      display: block;
    }
  </style>
  