<template>
    <section style="height: 100%;display: flex;flex-direction: column;">
      <div class="search">
        <el-button type="primary" @click="educe">导出</el-button>
      </div>
      <div class="container">
        <el-table
         :data="tableData"
         border
         height="calc(100% - 24px)"
         class="table"
         fit>
          <af-table-column
          align='center'
          fixed="left"
          prop="NeighbourhoodName"
          label="场地名称"
          width="180">
          </af-table-column>
          <af-table-column
            align='center'
            prop="CreateTime"
            label="安装时间">
          </af-table-column>
          <af-table-column
            align='center'
            prop="sumPortCount"
            label="安装枪数">
          </af-table-column>
          <af-table-column
            align='center'
            prop="InputCost"
            label="投入成本（元）">
          </af-table-column>
          <af-table-column
            align='center'
            prop="InputDay"
            label="投入时长（天）">
          </af-table-column>
          <af-table-column
            align='center'
            prop="TotalIncome"
            label="累计收益（服务费净收益）">
          </af-table-column>
          <af-table-column
            align='center'
            prop="OneSpearDayIncome"
            label="单枪日收益（元/枪/天）">
          </af-table-column>
          <af-table-column
            align='center'
            prop="RecoveryCostDay"
            label="预计收回成本天数">
          </af-table-column>
          <af-table-column
            align='center'
            prop="residueRecoveryCostDay"
            label="剩余回本天数">
          </af-table-column>
        </el-table>
      </div>
    </section>
  </template>
  
  <script>
  import {GetOrderCostReport,ExportOrderCostReport} from '@/api/public.js';
  import {baseUrl} from '@/api/url.js';
  export default {
    data() {
      return {
          tableData:[],
      }
    },
  
    components: {},
  
    computed: {},
  
    mounted() {
      this.$nextTick(()=>{
        this.init();
      });
    },
  
    methods: {
      async init(){
        await GetOrderCostReport().then(res=>{
          if(res.Success){
            this.tableData = res.Data;
            this.tableData.forEach(element => {
              element.CreateTime=this.timeToTime(element.CreateTime.substring(6,19));
            });
          }else{
            this.tableData = [];
            this.$message({
              message:'获取数据失败，请重试',
              type:'fail'
            })
          }
        })
      },
      // 时间戳转时间
      timeToTime(time){
         const date = new Date(Number(time))
         const Y = date.getFullYear() // 年
         const M = date.getMonth() + 1 < 10 ? '0'+(date.getMonth()+1) : date.getMonth()+1 // 月
         const D = date.getDate() < 10 ? '0' + date.getDate() : date.getDate() // 日
         const H = date.getHours() < 10 ? '0' + date.getHours() : date.getHours() // 时
         const m = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes() // 分
         const S = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds() // 秒 
         const Time=`${Y}-${M}-${D} ${H}:${m}:${S}`;
         return Time;
      },
      // 导出
      async educe(){
        await ExportOrderCostReport().then(res=>{
          if(res.Success){
            if(res.Success){
              window.open(baseUrl+'/'+res.Data);
            }else{
              this.$message({
                message: res.Message,
                type: "fail",
              });
            }
          }
        })
      },
    },
  };
  </script>
  <style scoped lang="scss">
    @import "@/static/default";
    .remind{
      font-size: 14px;
      color: rgb(116, 4, 4);
      width: 100%;
      text-align: left;
      line-height: 45px;
    }
      /* 搜索 */
    .search{
      margin-bottom: 10px;
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-end;
     }
    .container{
      background-color: #fff !important;
      padding:0 32px;
      flex: 1;
      .table{
        margin-top:24px !important;
        width: 100% !important;
      }
    }
  </style>
  