<!-- 耗电统计 -->
<template>
  <section>
    <div class="search">
      <div class="inputs">
        <!-- 搜索功能 -->
        <el-input class="wxName" v-model="searchInfo.data.EquipmentNum" placeholder="请输入设备编号"></el-input>
        <el-input class="wxName" v-model="searchInfo.data.Port" placeholder="请输入端口号"></el-input>
        <div class="Btns">
         <el-button type="primary" @click="toSearch">查询</el-button>
         <el-button  @click="backAll">返回所有</el-button>
        </div>
      </div>
    </div>
    <div class="container">
      <!-- 表格 -->
      <el-table
        :data="cumulativeElectricityInfo"
        style="width: 100%" 
        class="table"
        fit
      >
      <!-- 数据展示区 -->
        <af-table-column
          label="ID"
          prop="Id"
          align='center'>
        </af-table-column>
        <af-table-column
          label="所属设备"
          prop="EquipmentNum"
          align='center'>
        </af-table-column>
        <af-table-column
          label="设备地址"
          prop="Address"
          align='center'>
        </af-table-column>
        <af-table-column
          label="端口号"
          prop="Port"
          align='center'>
        </af-table-column>
        <af-table-column
          label="初始电量(度)"
          prop="InitialElectric"
          align='center'>
        </af-table-column>
        <af-table-column
          label="当前电量(度)"
          prop="CurrentElectric"
          align='center'>
        </af-table-column>
        <af-table-column
          label="更新时间"
          prop="UpdateTime"
          align='center'>
        </af-table-column>
        <af-table-column
          label="创建时间"
          prop="CreateTime"
          align='center'>
        </af-table-column>
         <af-table-column
          label="备注"
          prop="Remarks"
          align='center'>
          <template slot-scope="scope">{{ scope.row.Remarks || scope.row.Remarks ==0 ? scope.row.Remarks : '--' }}</template>
        </af-table-column>
        <el-table-column label="操作" fixed="right" align='center' min-width="100">
          <template slot-scope="scope">
            <el-button size="mini" @click="handleSettle(scope.$index, scope.row)">结算耗电</el-button>
          </template>
        </el-table-column>
      </el-table>
      <!--分页-->
      <el-col :span="24" class="toolbar" >
        <el-pagination
          layout="total, prev, pager, next, jumper"
          @current-change="handleCurrentChange"
          @prev-click="prevpage"
          @next-click="nextpage"
          :page-size="10"
          :total="total"
          style="float:right;"
        ></el-pagination>
      </el-col>
    </div>
  </section>
</template>

<script>
import {getCumulativeElectricityInfo,settlementElectricity} from '@/api/public.js';
import { timeToTime } from '@/api/publicFun.js';
export default {
  data() {
    return {
        searchInfo:{ // 搜索条件
            pageIndex:1,
            pageSize:10,
            data:{
                EquipmentNum:'',
                Port:'',
            }
        },
        total:0, // 数据总条数
        cumulativeElectricityInfo:[], // 耗电统计列表
    }
  },

  components: {},

  computed: {},

  mounted() {
    this.init();
  },

  methods: {
    // 初始化获取耗电统计
    async init(){
        await getCumulativeElectricityInfo(this.searchInfo).then(res=>{
            if(res.Success){
                this.cumulativeElectricityInfo=res.Data.data;
                this.total=res.Data.totalCount;
                this.cumulativeElectricityInfo.forEach(element => {
                    element.UpdateTime=timeToTime(element.UpdateTime.substring(6,19));
                    element.CreateTime=timeToTime(element.CreateTime.substring(6,19));
                });
            }
        })
    },
    // 条件查询
    toSearch(){
      this.searchInfo.pageIndex=1;
      this.init();
    },
    // 清除查询条件
    backAll(){
      this.searchInfo.pageIndex=1;
      this.searchInfo.data={
        EquipmentNum:'',
        Port:'',
      };
      this.init();
    },
    // 结算耗电
    async handleSettle(index,row){
        await settlementElectricity({Data:row.Id}).then(res=>{
            if(res.Success){
                this.$message({
                    message:'结算成功！',
                    type:'success'
                })
                this.init();
            }else{
                this.$message({
                    message:'结算失败，请重试',
                    type:'fail'
                })
            }
        })
    },
    // 分页
    handleCurrentChange(val){
      this.searchInfo.pageIndex=val;
      this.init();
    },
    prevpage(e){
      this.searchInfo.pageIndex=e;
      this.init();
    },
    nextpage(e){
      this.searchInfo.pageIndex=e;
      this.init();
    },
  },
};
</script>
<style scoped lang='scss'>
.search{
  margin-bottom: 30px;
    .inputs{
      display: flex;
    }
    .wxName,.select,.timeSelect{
      width: 20%;
      margin-right: 15px;
    }
    .timeSelect{
      flex: 1;
    }
    .Btns{
      display: flex;
      justify-content: flex-end;
      margin-bottom: 10px;
    }
  }
  .search .wxName[data-v-0dade7ca], .search .select[data-v-0dade7ca], .search .timeSelect[data-v-0dade7ca]{
    margin-right: 5px;
  }
  
// 按钮
  .btns{
    width: 100%;
    background-color: white;
    height: 60px;
    width: 100%;
    padding: 10px 50px;
    position: relative;
    button{
      position: absolute;
      right:20px;
      top:10px;
    }
  }
  

.el-table >>> .cell {
  white-space: pre-line;
}
.el-drawer.rtl{
  overflow-y: auto !important;
}

.equipName{
  margin-bottom: 10px;
}
.equipNumber{
  color: #40a9ff;
}
.number{
  display: inline-block;
  height: 20px;
  width: 20px;
  padding: 0 2px;
  border: solid 1px #91d5ff;
  color:#40a9ff;
  background: #e6f7ff;
  border-radius: 3px;
  margin-left: 10px;
  text-align: center;
  font-size: 14px;
}
.el-tabs__content{
  overflow:auto !important;
}
.orderStatus{
  background:#40a9ff;
  color:#fff;
  display: inline-block;
  padding: 3px 5px;
  border-radius: 5px;
}

.stopWay{
  color: #40a9ff;
  margin-top: 10px;
}
</style>
