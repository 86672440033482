<template>
  <section>
    <el-form ref="form" label-position="right" status-icon :model="form" :rules="rules" label-width="100px" style="width:95%;overflow:auto">
      <!-- 物业 -->
      <el-form-item label="绑定物业" prop="WeChatUserId" v-if="row.Role==2">
        <el-select v-model="value" placeholder="请选择" @change="selectProperty" >
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>
      <!-- 代理 -->
      <el-form-item label="绑定代理" prop="WeChatUserId" v-if="row.Role==1">
        <el-select v-model="agentValue" placeholder="请选择" @change="selectAgent" >
          <el-option
            v-for="item in agentOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button @click="close('form')">取消</el-button>
        <el-button type="primary" @click="bind('form')" :plain='true'>立即绑定</el-button>
      </el-form-item>
    </el-form>
  </section>
</template>

<script>
let _this;
import {bandingProperty,selectRole,bandingAgent,getTemplateList,setConsumeTemplate} from '@/api/public.js';
export default {
  props:{row:{type:Object}},
  data() {
    return {
        options:[],
        value:'',
        agentOptions:[],
        agentValue:'',
        templateOptions:[],
        templateValue:'',
        form:{},
        rules:{},
    }
  },

  components: {},

  computed: {},

  mounted() {
    _this=this;
    _this.init();
    this.form.Id=this.row.Id;
  },

  methods: {
    // 取消操作，关闭弹窗
    close(){
       this.$emit('close');
    },
    init(){
        if(this.row.Role==2){
            selectRole({Role:2}).then(res=>{
                if(res.Success){
                    this.options=[];
                    res.Data.forEach(element => {
                        this.options.push({value:element.Id,label:element.WeChatName})
                    });
                }
            })
        }else if(this.row.Role==1){
            selectRole({Role:1}).then(res=>{
                if(res.Success){
                    this.agentOptions=[];
                    res.Data.forEach(element => {
                        this.agentOptions.push({value:element.Id,label:element.WeChatName})
                    });
                }
            })
        }else{
            getTemplateList().then(res=>{
                if(res.Success){
                    this.templateOptions=[];
                    res.Data.forEach(element => {
                        this.templateOptions.push({value:element.Id,label:element.TemplateName})
                    });
                }
            })
        }
    },
    selectProperty(e){
        this.form.WeChatUserId=e;
    },
    selectAgent(e){
        this.form.AgentId=e;
    },
    selectTemplate(e){
        this.form.ConsumeTemplateId=e
    },
    bind(){
        if(this.row.Role==2){
            bandingProperty(this.form).then(res=>{
                if(res.Success){
                    _this.$message({
                      message: "绑定成功",
                      type: "success",
                    });
                    setTimeout(() => {
                      _this.$emit("success");
                      _this.init();
                    }, 1000);
                }else{
                    _this.$message({
                      message: "绑定失败，请重试",
                      type: "fail",
                    });
                }
            })
        }else if(this.row.Role==1){
            bandingAgent(this.form).then(res=>{
                if(res.Success){
                    _this.$message({
                      message: "绑定成功",
                      type: "success",
                    });
                    setTimeout(() => {
                      _this.$emit("success");
                      _this.init();
                    }, 1000);
                }else{
                    _this.$message({
                      message: "绑定失败，请重试",
                      type: "fail",
                    });
                }
            })
        }else{
            setConsumeTemplate(this.form).then(res=>{
                if(res.Success){
                    _this.$message({
                      message: "设置成功",
                      type: "success",
                    });
                    setTimeout(() => {
                      _this.$emit("success");
                      _this.init();
                    }, 1000);
                }else{
                    _this.$message({
                      message: "设置失败，请重试",
                      type: "fail",
                    });
                }
            })
        }
    },
  },
};
</script>
<style scoped></style>
