<template>
    <section>
      <el-form ref="form" label-position="right" status-icon :model="form" :rules="rules" label-width="100px" style="width:95%;overflow:auto">
          <!-- 分润 -->
          <el-form-item label="总部" prop="headquarters">
            <el-input  placeholder="百分比" type='number' v-model="form.headquarters"   class="input-with-select">
              <span slot="prepend">总部</span>
              <span slot="append" >%</span>
            </el-input>
          </el-form-item>
          <el-form-item label="代理" prop="agentPercent">
            <el-input placeholder="百分比" type='number' v-model="form.agentPercent" class="input-with-select">
              <span slot="prepend">{{row.AgentName}}</span>
              <span slot="append">%</span>
            </el-input>
          </el-form-item>
          <!-- 分润 -->
          <el-form-item label="合伙人" prop="propertyPercent">
            <el-input placeholder="百分比" type='number' v-model="form.propertyPercent" class="input-with-select">
              <span slot="prepend">{{row.WeChatName}}</span>
              <span slot="append" >%</span>
            </el-input>
          </el-form-item>
          <el-form-item label="结算电费" prop="IsElectricity">
            <el-radio-group v-model="form.IsElectricity">
              <el-radio label="总部"></el-radio>
              <el-radio label="代理"></el-radio>
              <el-radio label="合伙人"></el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item>
            <el-button @click="close('form')">取消</el-button>
            <el-button type="primary" @click="setBenefit('form')" :plain='true'>立即设置</el-button>
          </el-form-item>
        </el-form>
    </section>
  </template>
  
  <script>
  import {SetBatteryBenefitRules} from '@/api/public.js';
  export default {
    props:{row:{type:Object}},
    data() {
      return {
        form:{
          headquarters:0,
          agentPercent:0, // 代理比例
          propertyPercent:0, // 合伙人比例
          IsElectricity:'', // 结算电费
        },
        rules:{
          headquarters: [
            { required: true, message: '百分比不得为空', trigger: 'blur', }
          ],
          agentPercent: [
            { required: true, message: '百分比不得为空', trigger: 'blur', }
          ],
          propertyPercent: [
            { required: true, message: '百分比不得为空', trigger: 'blur', }
          ],
          IsElectricity: [
            { required: true, message: '请选择结算电费', trigger: 'change', }
          ],
        }
      }
    },
  
    components: {},
  
    computed: {},
  
    mounted() {
      this.init();
    },
  
    methods: {
      // 取消操作，关闭弹窗
      close(){
         this.$emit('close');
      },
      init(){
        this.form.Id=this.row.Id;
        this.row.BatteryBenefitRules=this.row.BatteryBenefitRules?JSON.parse(this.row.BatteryBenefitRules):this.row.BatteryBenefitRules;
        this.row.BatteryBenefitRules?this.row.BatteryBenefitRules.forEach(element => {
          this.form.agentPercent=element.Profitid==this.row.AgentId?element.ProfitRatio:this.form.agentPercent;
          this.form.propertyPercent=element.Profitid==this.row.WeChatUserId?element.ProfitRatio:this.form.propertyPercent;
          this.form.headquarters=element.Profitid==0?element.ProfitRatio:this.form.headquarters;
          this.form.IsElectricity=element.IsElectricity==1 && element.Profitid==this.row.WeChatUserId? '合伙人' :(element.IsElectricity==1 && element.Profitid==0? '总部' : '代理')
        }):this.row.BatteryBenefitRules;
      },
      // 设置分润
      setBenefit(from){ 
        this.$refs["form"].validate(async valid => {
          if(valid){
            // 保证分润比例为int类型
            this.form.headquarters=Number(this.form.headquarters);
            this.form.agentPercent=Number(this.form.agentPercent);
            this.form.propertyPercent=Number(this.form.propertyPercent);
            let rule=[
              {"ProfitRatio":this.form.headquarters,"Profitid":0,IsElectricity:0},
              {"ProfitRatio":this.form.agentPercent,"Profitid":this.row.AgentId,IsElectricity:0},
              {"ProfitRatio":this.form.propertyPercent,"Profitid":this.row.WeChatUserId,IsElectricity:0},
            ];
            if(this.form.IsElectricity=='总部'){
              rule[0].IsElectricity=1;
            }else if(this.form.IsElectricity=='代理'){
              rule[1].IsElectricity=1;
            }else if(this.form.IsElectricity=='合伙人'){
              rule[2].IsElectricity=1;
            }
            if(this.row.AgentId==0 || this.agentPercent==0){ // 如果代理未绑定，则代理分润不作为参数传递
              rule.splice(1,1);
            }
            if(this.propertyPercent==0 || this.row.WeChatUserId==0){ // 如果物业未绑定，则物业分润不作为参数传递
              rule.length == 3 ? rule.splice(2,1) : rule.splice(1,1);
            }
            this.form.BatteryBenefitRules=rule;
            let all=this.form.headquarters+this.form.agentPercent+this.form.propertyPercent;
            this.form.BatteryBenefitRules=JSON.stringify(this.form.BatteryBenefitRules);
            if(all == 100){
              await SetBatteryBenefitRules(this.form).then(res=>{
                if(res.Success){
                  this.$message({
                    message: "设置成功",
                    type: "success",
                  });
                  setTimeout(() => {
                    this.$emit("success");
                    this.init();
                  }, 1000);
                }else{
                    this.$message({
                      message: "设置失败，请重试",
                      type: "fail",
                    });
                }
              })
            }else{
              this.$message({
                message: "百分比总和必须为100%",
                type: "fail",
              })
            }
          }
        })
      },
      selectTemplate(){},
    },
  };
  </script>
  <style scoped lang='scss'>
    .el-select,.el-input-group__prepend{
      width: 120px !important;
    }
    /deep/ .el-form-item__content{
      display: flex;
      align-items: center;
      height: 40px;
      justify-content: center;
      .el-input-group{
        margin-right: 5px;
        height: 100%;
      }
    }
  </style>
  