<template>
  <section>
    <el-form ref="form" label-position="right" status-icon :model="form" :rules="rules" label-width="120px" style="width:95%;overflow:auto">
      <el-form-item label="渠道" prop="Channel" >
        <el-select v-model="form.Channel" placeholder="请选择渠道" @change="selectChannel" style="width:100%;">
          <el-option
            v-for="item in channelOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="银行名称" prop="BankName" v-if="!isAlipay">
        <el-input placeholder="请输入银行名称" v-model="form.BankName">
        </el-input>
      </el-form-item>
      <el-form-item label="支行名称" prop="SubBranchName" v-if="!isAlipay">
        <el-input placeholder="请输入支行名称" v-model="form.SubBranchName">
        </el-input>
      </el-form-item>
      <el-form-item label="银行卡号" prop="BankNum" v-if="!isAlipay">
        <el-input placeholder="请输入银行卡号" v-model="form.BankNum">
        </el-input>
      </el-form-item>
      <el-form-item label="卡主名称" prop="BankOwnerName" v-if="!isAlipay">
        <el-input placeholder="请输入卡主名称" v-model="form.BankOwnerName">
        </el-input>
      </el-form-item>
      <el-form-item label="支付宝账号" prop="AlipayNumber" v-if="isAlipay">
        <el-input placeholder="请输入支付宝账号" v-model="form.AlipayNumber">
        </el-input>
      </el-form-item>
      <el-form-item label="支付宝账号名称" prop="AlipayName" v-if="isAlipay">
        <el-input placeholder="请输入支付宝账号名称" v-model="form.AlipayName">
        </el-input>
      </el-form-item>
      <el-form-item label="所属用户" prop="WeChatUserId" >
        <el-select v-model="form.WeChatUserId" placeholder="请选择" @change="selectWeChatUser" style="width:100%;">
          <el-option
            v-for="item in WeChatUseroptions"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button @click="close('form')">取消</el-button>
        <el-button type="primary" @click="edit('form')" :plain='true'>立即修改</el-button>
      </el-form-item>
    </el-form>
  </section>
</template>

<script>
let _this;
import {updateWithdrawalNum,getWechatUser} from '@/api/public.js';

export default {
  props:{row:Object},
  data() {
    return {
      form:{},
      // 表单验证
      rules: {
        Channel: [
          { required: true, message: "渠道不得为空", trigger: "blur" },
        ],
        BankName: [
          { required: true, message: "银行名称不得为空", trigger: "blur" },
        ],
        SubBranchName: [
          { required: true, message: "支行名称不得为空", trigger: "blur" },
        ],
        BankNum: [
          { required: true, message: "银行卡号不得为空", trigger: "blur" },
        ],
        BankOwnerName: [
          { required: true, message: "卡主名称不得为空", trigger: "blur" },
        ],
        AlipayNumber: [
          { required: true, message: "支付宝账号不得为空", trigger: "blur" },
          { pattern:/^((13|14|15|16|17|18)[0-9]{1}\d{8})|((166|199|198)[0-9]{1}\d{7})$/, message: '请输入正确的支付宝账号', trigger: 'blur' }
        ],
        AlipayName: [
          { required: true, message: "支付宝账号名称不得为空", trigger: "blur" },
        ],
        WeChatUserId: [
          { required: true, message: "请选择所属用户", trigger: "change" },
        ],
      },
      // 渠道选项   
      channelOptions:[
          {
              value:0,
              label:'银行卡',
          },{
              value:1,
              label:'支付宝',
          }
      ],
      //微信用户选项   
      WeChatUseroptions:[],
      value:'',
      isAlipay:false,
    };
  },

  components: {},

  computed: {},

  mounted() {
    _this=this;
    this.form=this.row;
    this.isAlipay = this.form.Channel == '支付宝' ? true : false;
    // 微信用户角色为代理的id
    getWechatUser({pageIndex:1,pageSize:1000}).then(res=>{
      res.Data.data.forEach((element,index) => {
          if(element.Role == 1){
              this.WeChatUseroptions.push({value:element.Id,label:element.WeChatName});
          }
      });
    })
  },

  methods: {
    close(){
       _this.$emit('close');
    },
    // 选择渠道
    selectChannel(e){
        this.form.Channel=e;
        this.isAlipay = e == 1 ? true : false;
    },
    // 修改
    edit(form) {
      this.form.BankName=this.isAlipay?'': this.form.BankName;
      this.form.SubBranchName=this.isAlipay?'': this.form.SubBranchName;
      this.form.BankNum=this.isAlipay?'': this.form.BankNum;
      this.form.BankOwnerName=this.isAlipay?'': this.form.BankOwnerName;
      this.form.AlipayNumber=!this.isAlipay?'': this.form.AlipayNumber;
      this.form.AlipayName=!this.isAlipay?'': this.form.AlipayName;
       _this.$refs["form"].validate(valid => {
          if (valid) {
            updateWithdrawalNum(_this.form)
              .then((res) => {
                if (res.Success) {
                  _this.$message({
                    message: "修改成功",
                    type: "success",
                  });
                  setTimeout(() => {
                    _this.$emit("success");
                    _this.init();
                  }, 1000);
                }else{
                  _this.$message({
                    message: "修改失败，请重试",
                    type: "fail",
                  });
                }
              })
              .catch(error => {});
          } else {
            return false;
          }
      });
    },
    
  }
};
</script>
<style lang="scss" scoped>
 .el-drawer{
   overflow:auto !important;
 }
 .avatar-uploader .el-upload {
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }
  .avatar-uploader-icon {
    border: 1px dashed #ccc;
    border-radius: 50%;
    overflow:hidden;
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }
  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }
</style>
