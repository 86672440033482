<!-- 场地电瓶车经营报表 -->
<template>
  <section style="height: 100%;display: flex;flex-direction: column;">
    <div class="search">
      <div class="inputs">
        <el-date-picker
          v-model="searchInfo.Data"
          type="month"
          @change="chooseDate"
          placeholder="选择日期">
        </el-date-picker>
        <el-button type="primary" @click="educe">导出</el-button>
      </div>
    </div>
    <div class="container">
      <el-table
       :data="tableData"
       border
       height="calc(100% - 24px)"
       class="table"
       fit>
        <af-table-column
          fixed="left"
          align='center'
          prop="BeginTime"
          label="结算日期">
        </af-table-column>
        <af-table-column
          align='center'
          prop="sumOrderAmount"
          label="当天入账（元）">
        </af-table-column>
        <af-table-column
          align='center'
          prop="countnum"
          label="订单数量">
        </af-table-column>
        <af-table-column
          align='center'
          prop="sumRefundAmount"
          label="当天退款（元）">
        </af-table-column>
        <af-table-column
          align='center'
          prop="refundcountnum"
          label="退款数量">
        </af-table-column>
        <af-table-column
          align='center'
          prop="ActualAmounts"
          label="实际收益（元）">
        </af-table-column>
      </el-table>
    </div>
  </section>
</template>

<script>
import {GetOrderDaySettlement,ExportOrderDaySettlements} from '@/api/public.js';
import {baseUrl} from '@/api/url.js';
export default {
  data() {
    return {
      searchInfo:{
        Data:'',
      },
      tableData:[],
    }
  },

  components: {},

  computed: {},

  mounted() {
    this.getToday();
    this.$nextTick(()=>{
      this.init();
    });
  },

  methods: {
    getToday(){
      let myDate = new Date();
      let year = myDate.getFullYear();    //获取完整的年份  2023
      let month = myDate.getMonth() < 10 ? '0' + (myDate.getMonth() +1) : myDate.getMonth()+1 ;       //获取当前月份(0-11,0代表1月)
      this.searchInfo.Data = `${year}-${month}`
    },
    async init(){
      await GetOrderDaySettlement(this.searchInfo).then(res=>{
        if(res.Success){
          this.tableData = res.Data;
        }else{
          this.tableData = [];
          this.$message({
            message:'获取数据失败，请重试',
            type:'fail'
          })
        }
      })
    },
    chooseDate(e){
      this.searchInfo.Data = e;
      this.init();
    },
    // 导出
    async educe(){
      await ExportOrderDaySettlements(this.searchInfo).then(res=>{
        if(res.Success){
          if(res.Success){
            window.open(baseUrl+'/'+res.Data);
          }else{
            this.$message({
              message: res.Message,
              type: "fail",
            });
          }
        }
      })
    },
  },
};
</script>
<style scoped lang="scss">
  @import "@/static/default";
  .remind{
    font-size: 14px;
    color: rgb(116, 4, 4);
    width: 100%;
    text-align: left;
    line-height: 45px;
  }
    /* 搜索 */
    .search{
      margin-bottom: 10px;
        .inputs{
          display: flex;
          flex-wrap: wrap;
        }
        .el-date-editor.el-input{
          margin-right: 20px;
        }
      }
  .container{
    background-color: #fff !important;
    padding:0 32px;
    flex: 1;
    .table{
      margin-top:24px !important;
      width: 100% !important;
    }
  }
</style>
