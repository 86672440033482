// 时间戳转时间
export function timeToTime(time){
    const date = new Date(Number(time))
    const Y = date.getFullYear() // 年
    const M = date.getMonth() + 1 < 10 ? '0'+(date.getMonth()+1) : date.getMonth()+1 // 月
    const D = date.getDate() < 10 ? '0' + date.getDate() : date.getDate() // 日
    const H = date.getHours() < 10 ? '0' + date.getHours() : date.getHours() // 时
    const m = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes() // 分
    const S = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds() // 秒 
    const Time=`${Y}-${M}-${D} ${H}:${m}:${S}`;
    return Time;
 };