<template>
    <section>
      <el-form ref="form" label-position="right" status-icon :model="form" :rules="rules" label-width="100px" style="width:95%;overflow:auto">
        <el-form-item label="卡号" prop="CardNum">
          <el-input placeholder="请输入卡号" v-model="CardNum" disabled>
          </el-input>
        </el-form-item>
        <el-form-item label="充值金额" prop="AccountAmount">
          <el-input type="number" placeholder="请输入充值金额" v-model="form.AccountAmount">
          </el-input>
        </el-form-item>
        <el-form-item>
          <el-button @click="close('form')">取消</el-button>
          <el-button type="primary" @click="bind('form')" :plain='true'>充值</el-button>
        </el-form-item>
      </el-form>
    </section>
  </template>
  
  <script>
  let _this;
  import {rechargeStoredValueCard} from '@/api/public.js';
  //  自定义表单验证规则
  var checkAmount = (rule, value, callback) => {
    if (value<=0) {
      return callback(new Error('充值金额不得小于等于零'));
    }else{
        callback();
    }
  };
  export default {
    props:{row:{type:Object}},
    data() {
      return {
        form:{ // 表单数据
            AccountAmount:0,
            Id:'',
        },
        CardNum:'',
        
        rules: { // 表单验证
            AccountAmount: [
              { required: true, message: "充值金额不得为空", trigger: "blur" },
              { validator: checkAmount, trigger: 'blur' }
            ],
        },
      };
    },
  
    components: {},
  
    computed: {},
  
    mounted() {
      this.form.Id=this.row.Id;
      this.form.CardNum = this.row.CardNum;
      this.CardNum=this.row.CardNum;
      _this=this;
    },
  
    methods: {
       close(){
          _this.$emit('close');
       },
       selectState(e){},
       //  添加
       bind(form) {
          _this.$refs["form"].validate(valid => {
            if (valid) {
                this.form.AccountAmount=Number(this.form.AccountAmount)
              rechargeStoredValueCard(_this.form)
                .then((res) => {
                  if (res.Success) {
                    _this.$message({
                      message: "充值成功",
                      type: "success",
                    });
                    setTimeout(() => {
                      _this.$emit("success");
                      _this.$emit('close');
                    }, 1000);
                  }else{
                    _this.$message({
                      message: "充值失败,请重试",
                      type: "fail",
                    });
                  }
                })
                .catch(error => {});
            } else {
              return false;
            }
        });
      },
    }
  };
  </script>
  <style lang="scss" scoped>
   .el-drawer{
     overflow:auto !important;
   }
   .el-select{
    width: 100%;
   }
   .avatar-uploader .el-upload {
      cursor: pointer;
      position: relative;
      overflow: hidden;
    }
    .avatar-uploader .el-upload:hover {
      border-color: #409EFF;
    }
    .avatar-uploader-icon {
      border: 1px dashed #ccc;
      border-radius: 50%;
      overflow:hidden;
      font-size: 28px;
      color: #8c939d;
      width: 178px;
      height: 178px;
      line-height: 178px;
      text-align: center;
    }
    .avatar {
      width: 178px;
      height: 178px;
      display: block;
    }
  </style>
  