<template>
  <section>
    <!-- 搜索 -->
    <div class="search">
      <div class="inputs">
        <el-input class="wxName" v-model="searchInfo.EquipmentNum" placeholder="设备编号"></el-input>
        <el-input class="wxName" v-model="searchInfo.NeighbourhoodName" placeholder="设备名称"></el-input>
        <el-select class='select' v-model="state" clearable  placeholder="设备状态">
          <el-option
            v-for="item in statusOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
        <el-select class='select' v-model="operate" clearable placeholder="运营状态">
          <el-option
            v-for="item in operateOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
        <div class="Btns">
         <el-button type="primary" @click="toSearch">查询</el-button>
         <el-button  @click="backAll">返回所有</el-button>
      </div>
      </div>
    </div>
    <div class="add">
      <el-button type="primary" @click="handleAdd">添加</el-button>
      <el-button type="primary" @click="generateQrcode">生成二维码</el-button>
      <el-button  @click="handlePLbind">批量绑定</el-button>
    </div>
     <div class="container">
       <el-table
        :data="equipmentInfo"
        style="width: 100%" 
        class="table"
        fit
        @selection-change="handleSelectionChange"
      >
        <el-table-column
          type="selection"
          align='center'
        >
        </el-table-column>
        <!-- 数据展示区 -->
        <af-table-column
          label="ID"
          prop="Id"
          align='center'
          width='60px'>
        </af-table-column>
        <af-table-column
          label="设备编号"
          prop="EquipmentNum"
          align='center'>
          <template slot-scope="scope">
            <p style="display:flex;align-items:center">
              <span style="color:#40a9ff;border:0;background:none;">{{scope.row.EquipmentNum}}</span>
              <img style="height:15px;width:15px" src="@/static/img/qrCode.png" alt="" @click="openQrCode(scope.$index,scope.row)">
            </p>
          </template>
        </af-table-column>
        <af-table-column
          label="设备类型"
          prop="EquipmentType"
          align='center'>
        </af-table-column>
        <af-table-column
          label="设备状态"
          prop="EquipmentState"
          align='center'>
        </af-table-column>
        <af-table-column
          label="端口状态"
          prop="PortState"
          align='center'>
          <template slot-scope="scope">
            <el-tooltip class="item" effect="dark" content="空闲" placement="top">
              <el-button>{{scope.row.free}}</el-button>
            </el-tooltip>
            <el-tooltip class="item" effect="dark" content="充电中" placement="top">
              <el-button>{{scope.row.take}}</el-button>
            </el-tooltip>
            <el-tooltip class="item" effect="dark" content="故障" placement="top">
              <el-button>{{scope.row.fault}}</el-button>
            </el-tooltip>
          </template>
        </af-table-column>
        <af-table-column
          label="运营状态"
          prop="IsOperate"
          align='center'>
        </af-table-column>
        <af-table-column
          label="所在小区"
          prop="NeighbourhoodName"
          align='center'>
        </af-table-column>
        <af-table-column
          label="小区地址"
          prop="NeighbourhoodAddress"
          align='center'>
        </af-table-column>
        <af-table-column
          label="所属代理"
          prop="AgentName"
          align='center'>
        </af-table-column>
        <af-table-column
          label="备注"
          prop="Remarks"
          align='center'>
          <template slot-scope="scope">{{ !scope.row.Remarks && scope.row.Remarks != 0 ? '--' : scope.row.Remarks}}</template>
        </af-table-column>
        <el-table-column label="操作" fixed="right" align='center' min-width="250">
          <template slot-scope="scope">
            <el-button size="mini" @click="handleOprate(scope.$index, scope.row)">{{scope.row.isopen}}</el-button>
            <el-button size="mini" @click="handleBind(scope.$index, scope.row)">绑定</el-button>
            <el-button size="mini" @click="checkOrder(scope.$index, scope.row)">查看订单</el-button>
          </template>
      </el-table-column>
      </el-table>
      <!--分页-->
      <el-col :span="24" class="toolbar" >
        <el-pagination
          layout="total, prev, pager, next, jumper"
          @current-change="handleCurrentChange"
          @prev-click="prevpage"
          @next-click="nextpage"
          :page-size="10"
          :total="total"
          style="float:right;"
        ></el-pagination>
      </el-col>
      <!-- 新增设备 -->
      <el-drawer
        title="新增设备"
        :visible.sync="addDrawer"
        :direction="direction"
        :before-close="handleClose">
        <add v-if="addDrawer" @close='handleClose' @success="handleDrawerSuccess(form)  "  ref='newForm' :id="id" :row="row"></add>
      </el-drawer>
      <!-- 绑定小区 -->
      <el-drawer
        title="绑定小区"
        :visible.sync="bindDrawer"
        :direction="direction"
        :before-close="handleClose">
        <bind v-if="bindDrawer" @close='handleClose' @success="handleDrawerSuccess(form)  "  ref='newForm' :id="id" :row="row"></bind>
      </el-drawer>
       <!-- 编辑设备 -->
      <el-drawer
        title="编辑"
        :visible.sync="editDrawer"
        :direction="direction"
        :before-close="handleClose">
        <edit v-if="editDrawer" @close='handleClose' @success="handleDrawerSuccess(form)  "  ref='newForm' :id="id" :row="row"></edit>
      </el-drawer>
    </div>
    <div class="cover" v-if="showQRcode" @click="hideCover">
      <div class="inside" @click="stopPop">
        <p>{{this.num}}</p>
        <img :src="qrCode" alt="二维码暂未生成，请点击页面‘生成二维码按钮’完成生成">
      </div>
    </div>
  </section>
</template>

<script>
import {getEquipmentInfoBatteryCar,updateBatteryOperate,createBatteryQRcode} from '@/api/public.js';
import bind from './bind.vue';
import add from './add.vue';
import edit from './edit.vue';
import {baseUrl} from '@/api/url.js';
export default {
  data() {
    return {
      addDrawer:false,
      bindDrawer:false,
      editDrawer:false,
      id:'',
      row:'',
      direction:'rtl',
      plId:'',
      // 分页信息
      pageIndex:1,
      pageSize:10,
      total:0,
      // 设备信息
      equipmentInfo:[],
      searchInfo:{
        EquipmentState:-1,
        IsOperate:-1,
      },
      operate:'',
      state:'',
      statusOptions:[
        {value:0,label:'待激活'},
        {value:1,label:'启用'},
        {value:2,label:'下线'},
      ],
      operateOptions:[
        {value:0,label:'停用'},
        {value:1,label:'启用'},
      ],
      qrCode:'', // 设备二维码
      num:'',
      showQRcode:false,
    }
  },

  components: {add,bind,edit},

  computed: {},

  mounted() {
    this.init();
  },

  methods: {
    // 初始化
    async init(){
      await getEquipmentInfoBatteryCar({pageIndex:this.pageIndex,pageSize:this.pageSize,data:this.searchInfo}).then((res)=>{
        if(res.Success){
          this.equipmentInfo=res.Data.data;
          this.total=res.Data.totalCount;
          this.equipmentInfo.forEach(element => {
            element.EquipmentState=element.EquipmentState == 0 ? '待激活' :(element.EquipmentState == 1 ? '启用' : '下线');
            element.isopen=element.IsOperate == 1 ? '停用' : '启用';
            element.IsOperate=element.IsOperate == 0 ? '停用' : '启用';
            element.EquipmentType=element.EquipmentType+'口充电桩';
            for (const key in element) {
              element[key]=element[key]==''?'/':element[key];
            }
            // 端口状态
            element.free=0; // 空闲
            element.take=0; // 占用
            element.fault=0; // 故障
            let portState=element.PortState?element.PortState.split(','):[];
            portState.forEach(ele=>{
              if(ele==0 || ele==2 || ele==3){
                element.free++;
              }else if(ele==1 ||ele==5){
                element.take++;
              }else{
                element.fault++;
              }
            })
          });
        }
      })
      
    },
    // 生成二维码
    async generateQrcode(){
      await createBatteryQRcode().then(res=>{
        if(res.Success){
          this.$message({
            message:'二维码生成成功',
            type:'type',
          })
        }
      })
    },
    // 条件查询
    toSearch(){
      this.pageIndex=1;
      this.searchInfo.EquipmentState=this.state !==''  ? this.state : -1; 
      this.searchInfo.IsOperate=this.operate !==''  ? this.operate : -1; 
      this.init();
    },
    // 清除查询条件
    backAll(){
      this.searchInfo={
        EquipmentState:-1,
        IsOperate:-1,
      };
      this.state='';
      this.operate='';
      this.pageIndex=1;
      this.init();
    },
    // 修改设备运营状态
    async handleOprate(index,row){
      let isOperate=row.IsOperate == '启用' ? 0 : 1;
      await updateBatteryOperate({Id:row.Id,IsOperate:isOperate})
        .then((res) => {
          if (res.Success) {
            this.$message({
              message: "修改成功",
              type: "success",
            });
            this.init();
          }else{
            this.$message({
              message: "修改失败，请重试",
              type: "fail",
            });
          }
        })
        .catch(error => {});
    },
    // 打开二维码
    openQrCode(index,row){
      this.qrCode=baseUrl+row.QRcodeUrl;
      this.num=row.EquipmentNum;
      this.showQRcode=true;
    },
    hideCover(){
      this.showQRcode=false;
    },
    stopPop(e){
      e.stopPropagation()();
    },
    // 批量选择
    handleSelectionChange(val) {
      let Ids=[];
      val.forEach(ele=>{
        Ids.push(ele.Id);
        this.plId=Ids.join(',');
      })
    },
    // 打开新增
    handleAdd(){
      this.addDrawer=true;
    },
    // 查看订单
    checkOrder(index,row){
      this.$router.push({ 
        path: `/orderManagement/orderList`,// 路由路径
        query: { // 参数
          EquipmentNum: row.EquipmentNum,
        }
      });
    },
    // 打开编辑
    handleEdit(index,row){
      this.row=row;
      this.editDrawer=true;
    },
    // 关闭
    handleClose(){
      this.addDrawer=false;
      this.bindDrawer=false;
      this.editDrawer=false;
      this.init();
    },
    // 打开绑定
    handleBind(index,row){
      this.row=row;
      this.bindDrawer=true;
    },
    // 批量绑定
    handlePLbind(){
      this.row={Ids:this.plId,isPL:true};
      this.bindDrawer=true;
    },
    // 分页
    prevpage(val){
      this.pageIndex=val;
      this.init();
    },
    nextpage(val){
      this.pageIndex=val;
      this.init();
    },
    handleCurrentChange(val){
      this.pageIndex=val;
      this.init();
    },
  },
};
</script>
<style scoped lang='scss'>
.el-tooltip{
    display: inline-block;
    padding: 0 6px;
    margin-right: 5px;
    border-radius: 3px;
    margin-left: 0 !important;
    &:nth-child(1){
      color: #88d75f;
      border: #88d75f solid 1px;
      background: #f6ffed;
    }
    &:nth-child(2){
      color: #3aa1ff;
      border: #3aa1ff solid 1px;
      background: #e6f7ff;
    }
    &:nth-child(3){
      color: #ff696b;
      border: #ff696b solid 1px;
      background: #fff2f0;
    }
  }
// 遮罩层
.cover{
  height:100%;
  width: 100%;
  background: rgba($color: #000000, $alpha: 0.3);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2000;
  display: flex;
  justify-content: center;
  align-items: center;
  .inside{
    height: 400px;
    width: 300px;
    background: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    p{
      font-size: 20px;
      font-weight: 500;
      margin-bottom: 20px;
    }
  }
}
 /* 搜索 */
.search{
  margin-bottom: 30px;
  .inputs{
    text-align: left;
    display: flex;

  }
  .wxName,.select,.timeSelect{
    width: 15%;
    margin-right: 20px;
  }
  .timeSelect{
    width: 20%;
  }
  .Btns{
    display: flex;
    justify-content: flex-end;
    margin-bottom: 10px;
  }
}
/* 添加 */
.add{
    width: 100%;
    background-color: white;
    height: 60px;
    width: 100%;
    padding: 10px 50px;
    position: relative;
  }
  .add button{
    // position: absolute;
    // right:20px;
    // top:10px;
    float: right;
    margin:0 10px 0 0 ;
    // width: 100px;
    text-align: center;
    &:last-child{
      position: absolute;
      left:20px;
      top:10px;
    }
  }

  span{
    display: inline-block;
    padding: 0 6px;
    margin-right: 5px;
    border-radius: 3px;
    &:nth-child(1){
      color: #88d75f;
      border: #88d75f solid 1px;
      background: #f6ffed;
    }
    &:nth-child(2){
      color: #ff696b;
      border: #ff696b solid 1px;
      background: #fff2f0;
    }
    &:nth-child(3){
      color: #3aa1ff;
      border: #3aa1ff solid 1px;
      background: #e6f7ff;
    }
  }

  .container{
    img{
      &:hover{
        cursor: pointer;
      }
    }
  }
</style>
