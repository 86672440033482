<template>
    <section>
      <div class="search">
        <div class="inputs">
          <!-- 搜索 -->
          <el-input class="wxName" v-model="searchInfo.data.TotalPhone" placeholder="联系号码"></el-input>
          <el-input class="wxName" v-model="searchInfo.data.TotalRaise" placeholder="购买方名称（抬头）"></el-input>
          <div class="Btns">
             <el-button type="primary" @click="toSearch">查询</el-button>
             <el-button  @click="backAll">返回所有</el-button>
          </div>
        </div>
      </div>
      <div class="container">
        <el-table
          :data="billInfo"
          style="width: 100%" 
          class="table"
          fit
        >
        <!-- 数据展示区 -->
          <el-table-column
            label="购买方名称（抬头）"
            prop="TotalRaise"
            align='center'>
          </el-table-column>
          <el-table-column
            label="开票人联系方式"
            prop="TotalPhone"
            align='center'>
          </el-table-column>
          <el-table-column
            label="开票人邮箱"
            prop="TotalEmail"
            align='center'>
          </el-table-column> 
          <el-table-column
            label="发票识别号（税号）"
            prop="Totaltaxpayer"
            align='center'>
          </el-table-column>
          <el-table-column
            label="银行账号"
            prop="Totalaccount"
            align='center'>
          </el-table-column>
          <el-table-column
            label="发票类型"
            prop="Totaltype"
            align='center'>
          </el-table-column>
          <el-table-column
            label="企业地址"
            prop="address"
            align='center'>
          </el-table-column>
          <el-table-column
            label="订单类型"
            prop="FapiaoType"
            align='center'>
            <template slot-scope="scope"><p>{{scope.row.FapiaoType==1?'两轮电动车':'四轮电动车'}}</p></template>
          </el-table-column>
          <el-table-column
            label="申请时间"
            prop="CreateTime"
            min-width="150"
            align='center'>
          </el-table-column>
          <el-table-column
            label="状态"
            prop="Sate"
            align='center'>
            <template slot-scope="scope"><p>{{scope.row.Sate==1?'申请中':(scope.row.Sate==2?'已开票':(scope.row.Sate==4?'作废':'暂无'))}}</p></template>
          </el-table-column>
          <el-table-column label="操作" fixed="right" align='center' min-width="100">
            <template slot-scope="scope">
              <el-button size="mini" @click="confirmAmount(scope.$index, scope.row)" v-if="scope.row.Sate==1">确认金额</el-button>
              <el-button size="mini" @click="cancellation(scope.$index, scope.row)" v-if="scope.row.Sate==1">作废</el-button>
              <el-button size="mini" @click="checkOrder(scope.$index, scope.row)">开票订单</el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-drawer
         title="开票订单"
         :visible.sync="detailDrawer"
         :direction="direction"
         :before-close="handleClose"
         size="70%"
        >
          <detail v-if="detailDrawer" @close='handleClose' @success="handleDrawerSuccess(form)  "  ref='newForm' :row="row"></detail>
        </el-drawer>
        <!--分页-->
        <el-col :span="24" class="toolbar" >
          <el-pagination
            layout="total, prev, pager, next, jumper"
            @current-change="handleCurrentChange"
            @prev-click="prevpage"
            @next-click="nextpage"
            :page-size="10"
            :total="total"
            style="float:right;"
          ></el-pagination>
        </el-col>
        <!-- 确认金额弹窗 -->
        <el-dialog title="开票金额" :visible.sync="dialogFormVisible">
            <el-form>
                <el-form-item label="电费" prop="ElectricityAmount">
                    <el-input v-model="confirmInfo.ElectricityAmount"></el-input>
                </el-form-item>
                <el-form-item label="服务费" prop="ServiceAmount">
                    <el-input v-model="confirmInfo.ServiceAmount"></el-input>
                </el-form-item>
                <el-form-item label="开票总金额" prop="total_amount">
                    <el-input v-model="confirmInfo.total_amount"></el-input>
                </el-form-item>
                <el-form-item>
                  <el-button @click="dialogFormVisible = false">取 消</el-button>
                  <el-button type="primary" @click="confirm('eachOne')" :plain='true'>确认开票</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
      </div>
    </section>
  </template>
  
  <script>
  import {getWeChatUserToTalBill,updataWeChatUserToTalBill,upStateWeChatUserToTalBill} from '@/api/public.js';
  import detail from './detail.vue';
  import {timeToTime} from '@/api/publicFun.js';
  export default {
    data() {
      return {
        billInfo:[], // 开票列表信息
        searchInfo:{ // 查询条件
          pageIndex:1,
          pageSize:10,
          data:{
            TotalPhone:'',
            TotalRaise:'',
          },
        },
        confirmInfo:{ // 确认金额信息
            State:2,
            ElectricityAmount:0,
            ServiceAmount:0,
            total_amount:0,
        },
        dialogFormVisible:false, // 确认金额弹框是否打开
        detailDrawer:false, // 开票订单弹框是否打开
        row:'',
        direction:'rtl',
        total:0, // 数据总条数
      }
    },
  
    components: {detail},
  
    computed: {},
  
    mounted() {
      this.init();
    },
  
    methods: {
      // 初始化获取申请列表
      async init(){
        await getWeChatUserToTalBill(this.searchInfo).then(res=>{
          if(res.Success){
            this.billInfo=res.Data.data;
            this.total=res.Data.totalCount;
            this.billInfo.forEach(Element=>{
              Element.CreateTime = timeToTime(Element.CreateTime.substring(6,19));
              for(let key in Element){
                Element[key] =( Element[key] || Element[key] == 0 ) ? (Element[key] == 'null' ? '--' : Element[key] ): '--';
              }
            })
          }else{
            this.total = 0;
            this.billInfo = [];
          }
        })
      },
      // 申请作废
      cancellation(index,row){
        this.$confirm('确认作废该申请吗？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(async (res)=>{
            await upStateWeChatUserToTalBill({Sate:4,Id:row.Id}).then(res=>{
                if(res.Success){
                    this.$message({
                        message:'作废成功',
                        type:'success'
                    })
                    this.init();
                }else{
                    this.$message({
                        message:'作废失败，请重试',
                        type:'fail'
                    })
                    this.init();
                }
            })
        })
        
      },
      // 确认金额打开弹框
      confirmAmount(index,row){
          this.dialogFormVisible=true;
          this.confirmInfo.ElectricityAmount=Number(row.ElectricityAmount.toFixed(2));
          this.confirmInfo.ServiceAmount=Number(row.ServiceAmount.toFixed(2));
          this.confirmInfo.total_amount=Number(row.total_amount.toFixed(2))
          this.confirmInfo.Id=row.Id;
      },
      // 确认开票
      confirm(){
        this.$confirm('确认金额正确且开票？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(async res=>{
            await updataWeChatUserToTalBill(this.confirmInfo).then(res=>{
                if(res.Success){
                    this.$message({
                        message:'开票成功',
                        type:'success'
                    })
                    this.init();
                }else{
                    this.$message({
                        message:'开票失败，请重试',
                        type:'fail'
                    })
                    this.init();
                }
            })
            this.dialogFormVisible=false;
        })
      },
      // 查看开票申请下的订单
      checkOrder(index,row){
        this.row=row;
        this.detailDrawer=true;
      },
      // 新增、绑定、编辑成功
      handleDrawerSuccess(){
        this.detailDrawer=false;
      },
      // 关闭
      handleClose(){
        this.detailDrawer=false;
        this.init();
      },
      // 条件查询
      toSearch(){
        this.searchInfo.pageIndex=1;
        this.init();
      },
      // 清除查询条件
      backAll(){
        this.searchInfo.pageIndex=1;
        this.searchInfo.data={
          TotalPhone:'',
          TotalRaise:'',
        };
        this.init();
      },
      // 分页
      handleCurrentChange(val){
        this.searchInfo.pageIndex=val;
        this.init();
      },
      prevpage(e){
        this.searchInfo.pageIndex=e;
        this.init();
      },
      nextpage(e){
        this.searchInfo.pageIndex=e;
        this.init();
      },
    },
  };
  </script>
  <style scoped lang='scss'>
   .search{
    margin-bottom: 30px;
      .inputs{
        display: flex;
      }
      .wxName,.select,.timeSelect{
        width: 20%;
        margin-right: 15px;
      }
      .timeSelect{
        flex: 1;
      }
      .Btns{
        display: flex;
        justify-content: flex-end;
        margin-bottom: 10px;
      }
    }
    .search .wxName[data-v-0dade7ca], .search .select[data-v-0dade7ca], .search .timeSelect[data-v-0dade7ca]{
      margin-right: 5px;
    }
    
  // 按钮
    .btns{
      width: 100%;
      background-color: white;
      height: 60px;
      width: 100%;
      padding: 10px 50px;
      position: relative;
      button{
        position: absolute;
        right:20px;
        top:10px;
      }
    }
    
  
  .el-table >>> .cell {
    white-space: pre-line;
  }
  .el-drawer.rtl{
    overflow-y: auto !important;
  }
  
  .equipName{
    margin-bottom: 10px;
  }
  .equipNumber{
    color: #40a9ff;
  }
  .number{
    display: inline-block;
    height: 20px;
    width: 20px;
    padding: 0 2px;
    border: solid 1px #91d5ff;
    color:#40a9ff;
    background: #e6f7ff;
    border-radius: 3px;
    margin-left: 10px;
    text-align: center;
    font-size: 14px;
  }
  .el-tabs__content{
    overflow:auto !important;
  }
  .orderStatus{
    background:#40a9ff;
    color:#fff;
    display: inline-block;
    padding: 3px 5px;
    border-radius: 5px;
  }
  
  .stopWay{
    color: #40a9ff;
    margin-top: 10px;
  }
  </style>
  