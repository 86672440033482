<template>
  <section>
    <!-- 搜索 -->
    <div class="search">
      <div class="inputs">
        <el-input class="wxName" v-model="searchInfo.CardNum" placeholder="卡号"></el-input>
        <el-input class="wxName" v-model="searchInfo.Phone" placeholder="微信用户电话"></el-input>
        <el-input class="wxName" v-model="searchInfo.UserName" placeholder="微信用户名称"></el-input>
        <el-select class='select' v-model="CardType" clearable  placeholder="卡类型"> 
          <el-option
            v-for="item in typeOptions"
            :key="item.code"
            :label="item.Name"
            :value="item.code">
          </el-option>
        </el-select>
        <el-select class='select' v-model="CardState" clearable  placeholder="卡状态"> 
          <el-option
            v-for="item in statusOptions"
            :key="item.code"
            :label="item.Name"
            :value="item.code">
          </el-option>
        </el-select>
        <el-select class='select' v-model="CarType" clearable  placeholder="卡可用类型"> 
          <el-option
            v-for="item in useableOptions"
            :key="item.code"
            :label="item.Name"
            :value="item.code">
          </el-option>
        </el-select>
        <div class="Btns">
         <el-button type="primary" @click="toSearch">查询</el-button>
         <el-button type="primary" @click="backAll">返回所有</el-button>
         <el-button type="primary" @click="handleAdd(null)">新增</el-button>
      </div>
      </div>
    </div>
    <div class="container">
      <el-table
        :data="cardUser"
        style="width: 100%" 
        class="table"
        fit
      >
        <!-- 数据展示区 -->
        <af-table-column
          label="卡号"
          prop="CardNum"
          align='center'>
        </af-table-column>
        <af-table-column
          label="关联微信用户信息"
          prop="nickname"
          align='center'>
           <template slot-scope="scope"><p>{{scope.row.WeChatName ? scope.row.WeChatName : '--'}}</p><p>{{scope.row.Phone?scope.row.Phone:'--'}}</p></template>
        </af-table-column>
        <af-table-column
          label="绑定小区"
          prop="NeighbourhoodName"
          align='center'>
            <template slot-scope="scope">
               <p>{{scope.row.NeighbourhoodName ? scope.row.NeighbourhoodName : '通用'}}</p>
            </template>
        </af-table-column>
        <af-table-column
          label="余额(元)"
          prop="AccountAmount"
          align='center'>
        </af-table-column>
        <af-table-column
          label="赠送余额(元)"
          prop="GiveAmount"
          align='center'>
        </af-table-column>
        <af-table-column
          label="卡类型"
          prop="cardTypeName"
          align='center'>
        </af-table-column>
        <af-table-column
          label="卡可用类型"
          prop="carTypeName"
          align='center'>
        </af-table-column>
        <af-table-column
          label="优惠类型"
          prop="preferentialTypeName"
          align='center'>
        </af-table-column>
        <af-table-column
          label="备注"
          prop="Rmk"
          align='center'>
        </af-table-column>
        <af-table-column
          label="创建时间"
          prop="CreateTime"
          align='center'>
        </af-table-column>
        <el-table-column label="操作" fixed="right" align='center' min-width="250">
          <template slot-scope="scope">
            <el-button size="mini" @click="handleAdd(scope.row)">编辑</el-button>
            <el-button size="mini" @click="bind(scope.row)">绑定微信</el-button>
            <el-button size="mini" @click="recharge(scope.row)" v-if="scope.row.State != '正在使用'">充值</el-button>
          </template>
        </el-table-column>
      </el-table>
      <!--分页-->
      <el-col :span="24" class="toolbar">
        <el-pagination
          layout="total, prev, pager, next, jumper"
          @current-change="handleCurrentChange"
          @prev-click="prevpage"
          @next-click="nextpage"
          :page-size="10"
          :total="total"
          style="float:right;"
        ></el-pagination>
      </el-col>
      <el-drawer
        :title="row ? '编辑' :'新增'"
        size="50%"
        :visible.sync="addDrawer"
        :direction="direction"
        :before-close="handleClose">
        <add v-if="addDrawer" @close='handleClose' :row="row" ref='newForm' ></add>
      </el-drawer>
      <el-drawer
        title="绑定"
        :visible.sync="bindDrawer"
        :direction="direction"
        :before-close="handleClose">
        <bind v-if="bindDrawer" @close='handleClose'  ref='newForm' :cardNum="row.CardNum"></bind>
      </el-drawer>
      <el-drawer
        title="充值"
        :visible.sync="rechargeDrawer"
        :direction="direction"
        :before-close="handleClose">
        <recharge v-if="rechargeDrawer" @close='handleClose'  ref='newForm' :row="row"></recharge>
      </el-drawer>
    </div>
  </section>
</template>

<script>
import {getStoredValueCard,getWechatDictionariesList} from '@/api/public.js';
import add from './add.vue';
import bind from './bind.vue';
import recharge from './recharge.vue';
export default {
  data() {
    return {
      // 用户信息
      cardUser:[],
      row:null,
      // 分页信息
      pageIndex:1,
      pageSize:10,
      total:0,
      typeOptions:[],
      statusOptions:[],
      useableOptions:[],
      modeOptions:[],
      searchInfo:{
        State:-1,
        CarType:-1,
        CardType:-1,
      },
      State:'',
      state:'',
      CardType:"",
      CardState:"",
      CarType:"",
      addDrawer:false,
      bindDrawer:false,
      rechargeDrawer:false,
      direction:'rtl',
    }
  },

  components: {add,bind,recharge},

  computed: {},

  mounted() {
    this.getState('CardType');
    this.getState('CardState');
    this.getState('CarType');
    this.getState('CardMod');
  },

  methods: {
    // 数据初始化
    async init(){
      await getStoredValueCard({pageIndex:this.pageIndex,pageSize:this.pageSize,data:this.searchInfo}).then(res=>{
        if(res.Success){
          this.cardUser=res.Data.data;
          this.total=res.Data.totalCount;
          this.cardUser.forEach(element=>{
            element.CreateTime=this.timeToTime(element.CreateTime.substring(6,19));
             this.useableOptions.filter( (each) => { if(element.CarType == each.code) { element.carTypeName = each.Name } });
             this.typeOptions.filter( (each) => { if(element.CardType == each.code) { element.cardTypeName = each.Name } });
             this.modeOptions.filter( (each) => { if(element.PreferentialType == each.code) { element.preferentialTypeName = each.Name } });
          })
        }
      })
    },
    // 获取卡状态
    async getState(state){
      await getWechatDictionariesList({sign:state}).then(res=>{
        if(res.Success){
           if(state == 'CardType'){
            this.typeOptions = [...res.Data];
           }else if(state == 'CardState'){
            this.statusOptions = [...res.Data];
           }else if(state == 'CarType'){
            this.useableOptions = [...res.Data];
           }else if(state == 'CardMod'){
            this.modeOptions = [...res.Data]
           }
        }
      })
      this.$nextTick(()=>{
        this.init();
      })
    },
    // 条件查询
    toSearch(){
      this.searchInfo.CardType = this.CardType != '' ? Number(this.CardType) : -1;
      this.searchInfo.CardState = this.CardState != '' ? Number(this.CardState) : -1;
      this.searchInfo.CarType = this.CarType != '' ?  Number(this.CarType) : -1;
      this.pageIndex=1;
      this.init();
    },
    // 清除查询条件
    backAll(){
      this.pageIndex=1;
      this.searchInfo={
        State:-1,
        CarType:-1,
        CardType:-1,
      };
      this.CardType='';
      this.CardState='';
      this.CarType='';
      this.init();
    },
    // 时间戳转时间
    timeToTime(time){
       const date = new Date(Number(time));
       const Y = date.getFullYear(); // 年
       const M = date.getMonth() + 1 < 10 ? '0'+(date.getMonth()+1) : date.getMonth()+1; // 月
       const D = date.getDate() < 10 ? '0' + date.getDate() : date.getDate(); // 日
       const H = date.getHours() < 10 ? '0' + date.getHours() : date.getHours(); // 时
       const m = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes(); // 分
       const S = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds(); // 秒 
       const Time=`${Y}-${M}-${D} ${H}:${m}:${S}`;
       return Time;
    },
    // 打开新增或编辑弹框
    handleAdd(row){
      this.row = row;
      this.addDrawer=true;
    },
    bind(row){
      this.row=row;
      this.bindDrawer=true;
    },
    // 打开充值弹框
    recharge(row){
      this.row=row;
      this.rechargeDrawer=true;
    },
    // 关闭侧边弹框
    handleClose(){
      this.addDrawer=false;
      this.bindDrawer=false;
      this.rechargeDrawer=false;
      this.row = null;
      this.init()
    },
    // 关闭遮罩层
    closeCover(){
      this.showCover=false;
      this.init();
    },
    // 阻止事件冒泡
    stop(e){
      e.stopPropagation();
    },
    // 分页
    prevpage(val){
      this.pageIndex=val;
      this.init();
    },
    nextpage(val){
      this.pageIndex=val;
      this.init();
    },
    handleCurrentChange(val){
      this.pageIndex =val;
      this.init();
    },
  },
  
};
</script>
<style scoped lang='scss'>
 /* 搜索 */
.search{
  margin-bottom: 30px;
  .inputs{
    display: flex;
    text-align: left;
    flex-wrap: wrap;
  }
  .wxName,.select,.timeSelect{
    width: 20%;
    margin-right: 20px;
    margin-bottom: 5px;
  }
  .Btns{
    display: flex;
    justify-content: flex-end;
    margin-bottom: 10px;
  }
}
/* 遮罩层 */
.cover{
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.3);
  z-index: 20000;
}
.inside{
  height: 300px;
  width: 500px;
  background: #fff;
  position: absolute;
  top: 100px;
  left:50%;
  margin: 0 0 0 -150px;
  padding: 20px;
  box-sizing: border-box;
}

.inside .title{
  height: 50px;
  line-height: 50px;
  border-bottom:solid 1px #f0f0f0 ;
}

.inside .cardBalance,.inside .sendBalance{
  height: 50px;
  line-height: 50px;
  text-align: left;
  margin-top: 20px;
}

.inside input{
  height: 30px;
  width: 300px;
  padding-left: 10px;
  outline: 0;
  border: #ccc solid 1px;
}

.inside .btn{
  position: absolute;
  right: 0;
  bottom: 0;
  height: 50px;
  width: 100%;
  border-top: solid 1px #f0f0f0 ;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.inside .btn button{
  height: 30px;
  width: 50px;
  border: solid 1px #ccc;
  border-radius: 5px;
  margin-right: 20px;
}

.inside .btn button:nth-child(2){
  background: #40a9ff;
  color: #fff;
}

.cardStatus{
  display: inline-block;
  background:#f6ffed;
  border:solid 1px #b7eb8f;
  padding: 3px;
  color:#52c41a;
  font-size: 14px;
  border-radius: 3px;
}
</style>
