<template>
  <section>
    <!-- 搜索 -->
    <div class="search">
      <div class="inputs">
        <el-input class="wxName" v-model="searchInfo.WeChatName" placeholder="用户昵称"></el-input>
        <el-input class="wxName" v-model="searchInfo.Phone" placeholder="用户手机号"></el-input>
        <el-select class='select' v-model="role" clearable  placeholder="角色">
          <el-option
            v-for="item in roleOptions"
            :key="item.code"
            :label="item.Name"
            :value="item.code">
          </el-option>
        </el-select>
        <div class="Btns">
         <el-button type="primary" @click="toSearch">查询</el-button>
         <el-button  @click="backAll">返回所有</el-button>
        </div>
      </div>
    </div>
    <div class="container">
      <!-- 表格 -->
      <el-table
        :data="wechatUser"
        style="width: 100%"
        class="table"
        fit
      >
      <!-- 数据展示区 -->
        <af-table-column
          label="ID"
          prop="Id"
          align='center'>
        </af-table-column>
        <af-table-column
          label="用户昵称"
          prop="WeChatName"
          align='center'>
        </af-table-column>
        <af-table-column
          label="手机号码"
          prop="Phone"
          align='center'>
        </af-table-column>
        <af-table-column
          label="角色"
          prop="RoleName"
          align='center'>
        </af-table-column>
        <af-table-column
          label="账户余额(元)"
          prop="AccountAmount"
          align='center'>
        </af-table-column>
        <af-table-column
          label="赠送余额(元)"
          prop="GiveAmount"
          align='center'>
        </af-table-column>
        <af-table-column
          label="注册时间"
          prop="CreateTime"
          align='center'>
        </af-table-column>
        <el-table-column label="操作" fixed="right" align='center'  v-if="loginRoleId == 3" min-width="200">
          <template slot-scope="scope">
            <el-button size="mini" @click="open(scope.$index, scope.row)">添加余额</el-button>
            <el-button size="mini" @click="handleEdit(scope.$index, scope.row)">设置角色</el-button>
          </template>
        </el-table-column>
      </el-table>
      <!--分页-->
      <el-col :span="24" class="toolbar" >
        <el-pagination
          layout="total, prev, pager, next, jumper"
          @current-change="handleCurrentChange"
          @prev-click="prevpage"
          @next-click="nextpage"
          :page-size="10"
          :total="total"
          style="float:right;"
        ></el-pagination>
      </el-col>
      <!-- 修改角色 -->
      <el-drawer
        size="50%"
        title="修改角色"
        :visible.sync="editDrawer"
        :direction="direction"
        :before-close="handleClose">
        <edit v-if="editDrawer" @close='handleClose' @success="handleDrawerSuccess(form)  "  ref='newForm' :id="id" :row="row"></edit>
      </el-drawer>
    </div>
    <!-- 遮罩层 -->
    <div class="cover" v-if="showCover" @click="closeCover">
      <div class="inside" @click="stop">
        <p class="title">添加余额</p>
        <p class="cardBalance"><span>账户余额（元）：</span><input type="text" v-model="row.AccountAmount"></p>
        <p class="sendBalance"><span>赠送余额（元）：</span><input type="text" v-model="row.GiveAmount"></p>
        <p class="btn"><button @click="cancel">取消</button><button @click="confirmAdd">确定</button></p>
      </div>
    </div>
  </section>
</template>

<script>
import {getWechatUser,addAmount,getWechatDictionariesList} from '@/api/public.js';
import edit from './edit.vue';
export default {
  data() {
    return {
      // 用户信息
      wechatUser:[],
      showCover:false, // 添加余额弹框是否显示
      // 分页信息
      pageIndex:1,
      pageSize:10,
      total:0,
      // 编辑组件数据
      editDrawer:false,
      id:'',
      row:'',
      direction:'rtl',
      // 角色选项
      roleOptions:[
          {
              value:'0',
              label:'用户',
          },
          {
              value:'1',
              label:'代理',
          },
          {
              value:'2',
              label:'物业',
          },
          {
              value:'3',
              label:'合伙人',
          },
          {
              value:'-1',
              label:'管理员',
          },
          {
              value:'11',
              label:'安装人员',
          }
      ],
      role:'',
      wechatId:'',
      searchInfo:{
        Role:-2,
        WeChatName:'',
        Phone:'',
      },
      loginRoleId:sessionStorage.getItem('roleId'), // 当前登陆账号角色
    }
  },

  components: {edit},

  computed: {},

  mounted() {
    this.getRoleList();
  },

  methods: {
    async getRoleList(){
      await getWechatDictionariesList({sign:'WXUserRole'}).then(res=>{
        if(res.Success){
          this.roleOptions = res.Data;
          this.$nextTick(()=>{
            this.init();
          })
        }
      })
    },
    // 数据初始化
    async init(){
      await getWechatUser({pageIndex:this.pageIndex,pageSize:this.pageSize,data:this.searchInfo}).then(res=>{
        if(res.Success){
          this.wechatUser=res.Data.data;
          this.total=res.Data.totalCount;
          this.wechatUser.forEach(element => {
            element.CreateTime=this.timeToTime(element.CreateTime.substring(6,19));
            // let ind = this.roleOptions.indexOf(element.Role);
            // element.RoleName = this.roleOptions[ind].Name;
            this.roleOptions.filter( (each)=>{if(each.code == element.Role){element.RoleName = each.Name;console.log(each.Name,element.RoleName,555)}})
            for(let key in element){
              element[key] = !element[key] && element[key] !=0 ? '--' : element[key]
            }
          });
        }
      })
    },
    // 时间戳转时间
    timeToTime(time){
       const date = new Date(Number(time))
       const Y = date.getFullYear() // 年
       const M = date.getMonth() + 1 < 10 ? '0'+(date.getMonth()+1) : date.getMonth()+1 // 月
       const D = date.getDate() < 10 ? '0' + date.getDate() : date.getDate() // 日
       const H = date.getHours() < 10 ? '0' + date.getHours() : date.getHours() // 时
       const m = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes() // 分
       const S = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds() // 秒 
       const Time=`${Y}-${M}-${D} ${H}:${m}:${S}`;
       return Time;
    },
    // 条件查询
    toSearch(){
      console.log(this.role,888)
      this.searchInfo.Role = this.role !== '' ? this.role : -2;
      this.pageIndex=1;
      this.init();
    },
    // 清除查询条件
    backAll(){
      this.searchInfo={
        Role:-2,
        WeChatName:'',
        Phone:'',
      };
      this.pageIndex=1;
      this.role='';
      this.init();
    },
    // 打开添加余额
    open(index,row) {
      this.showCover=true;
      this.row=row;
    },
    // 取消添加余额
    cancel(){
      this.showCover=false;
      this.init();
    },
    // 确定添加余额
    async confirmAdd(){
      await addAmount(this.row).then(res=>{
        if(res.Success){
          this.$message({
            message: "添加成功",
            type: "success",
          });
          setTimeout(() => {
            this.showCover=false;
          }, 1000);
        }else{
          this.$message({
            message: "添加失败，请重试",
            type: "fail",
          });
        }
      })
    },
    // 关闭遮罩层
    closeCover(){
      this.showCover=false;
      this.init();
    },
    // 阻止事件冒泡
    stop(e){
      e.stopPropagation();
    },
    // 打开编辑
    handleEdit(index,row){
      this.row=row;
      this.editDrawer=true;
      this.id=index;
    },
    // 编辑成功
    handleDrawerSuccess(){
      this.editDrawer=false;
      this.init();
    },
    // 关闭
    handleClose(){
      this.editDrawer=false;
      this.init();
    },
    // 选择角色
    selectRole(){},
    // 分页
    prevpage(val){
      this.pageIndex=val;
      this.init();
    },
    nextpage(val){
      this.pageIndex=val;
      this.init();
    },
    handleCurrentChange(val){
      this.pageIndex =val;
      this.init();
    },
  },
};
</script>
<style lang="scss" scoped >
/* 遮罩层 */
.cover{
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.3);
  z-index: 20000;
}
.inside{
  height: 300px;
  width: 500px;
  background: #fff;
  position: absolute;
  top: 100px;
  left:50%;
  margin: 0 0 0 -150px;
  padding: 20px;
  box-sizing: border-box;
}

.inside .title{
  height: 50px;
  line-height: 50px;
  border-bottom:solid 1px #f0f0f0 ;
}

.inside .cardBalance,.inside .sendBalance{
  height: 50px;
  line-height: 50px;
  text-align: left;
  margin-top: 20px;
}

.inside input{
  height: 30px;
  width: 300px;
  padding-left: 10px;
  outline: 0;
  border: #ccc solid 1px;
}

.inside .btn{
  position: absolute;
  right: 0;
  bottom: 0;
  height: 50px;
  width: 100%;
  border-top: solid 1px #f0f0f0 ;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.inside .btn button{
  height: 30px;
  width: 50px;
  border: solid 1px #ccc;
  border-radius: 5px;
  margin-right: 20px;
}

.inside .btn button:nth-child(2){
  background: #40a9ff;
  color: #fff;
}

 /* 搜索 */
.search{
  margin-bottom: 30px;
  .inputs{
    text-align: left;
    display: flex;
  }
  .wxName,.select,.timeSelect{
    width: 20%;
    margin-right: 20px;
  }
  .Btns{
    display: flex;
    justify-content: flex-end;
    margin-bottom: 10px;
  }
}
</style>
