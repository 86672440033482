<template>
  <section>
    <div class="user">
    <el-button type="primary" @click="handleAdd">添加</el-button>
    </div>
    <div class="container">
      <el-table
       :data="userInfo"
       style="width: 100%;"
       row-key="id"
      >
        <af-table-column
        prop="Id"
        align='center'
        label="ID">
        </af-table-column>
        <af-table-column
        prop="LoginName"
        align='center'
        label="登录账户">
        </af-table-column>
        <af-table-column
        prop="UserName"
        align='center'
        label="用户名">
        </af-table-column>
        <af-table-column
        prop="RoleName"
        align='center'
        label="角色">
        </af-table-column>
        <af-table-column
        prop="IsLocked"
        align='center'
        label="是否冻结">
        </af-table-column>
        <af-table-column
        prop="CreatorName"
        align='center'
        label="创建人名称">
        </af-table-column>
        <af-table-column
        prop="CreateTime"
        align='center'
        label="创建时间">
        </af-table-column>
        <af-table-column
        prop="ModifierName"
        align='center'
        label="修改人名称">
        </af-table-column>
        <af-table-column
        prop="ModifiyTime"
        align='center'
        label="修改时间">
        </af-table-column>
        <el-table-column label="操作" fixed="right" align='center' v-if="loginRoleId == 3" min-width="220">
          <template slot-scope="scope">
            <el-button
              size="mini"
              @click="handleLock(scope.$index, scope.row)">{{scope.row.IsLocked=='是'?'解冻':'冻结'}}</el-button>
            <el-button
              size="mini"
              @click="handleEdit(scope.$index, scope.row)">修改</el-button>
              <el-button
              size="mini"
              @click="handleDel(scope.$index, scope.row)">删除</el-button>
            <el-button
              size="mini"
              @click="handleAdd(scope.$index, scope.row)" v-if="scope.row.Level==='1'">添加</el-button>
          </template>
        </el-table-column>
      </el-table>
      <!--分页-->
      <el-col :span="24" class="toolbar" >
        <el-pagination
          layout="total, prev, pager, next, jumper"
          @current-change="handleCurrentChange"
          @prev-click="prevpage"
          @next-click="nextpage"
          :page-size="10"
          :total="total"
          style="float:right;"
        ></el-pagination>
      </el-col>
        <!-- 新增菜单 -->
        <el-drawer
         title="新增用户"
         :visible.sync="addDrawer"
         :direction="direction"
         :before-close="handleClose"
        >
          <add v-if="addDrawer" @close='handleClose' @success="handleDrawerSuccess(form)  "  ref='newForm' :id="id" :row="row"></add>
        </el-drawer>
        <!-- 修改菜单 -->
        <el-drawer
         title="修改"
         :visible.sync="editDrawer"
         :direction="direction"
         :before-close="handleClose"
        >
          <edit v-if="editDrawer" @close='handleClose' @success="handleDrawerSuccess(form)  "  ref='newForm' :id="id" :row="row"></edit>
        </el-drawer>
    </div>
  </section>
</template>

<script>
  import {getUser,deleteUser,lockUser} from '@/api/public.js';
  import add from './add.vue';
  import edit from './edit.vue';
  let _this;

export default {
  data() {
    return {
      userInfo: [],
      addDrawer:false,
      editDrawer:false,
      id:'',
      row:'',
      pageIndex:1,
      pageSize:10,
      direction:'rtl',
      total:0,
      loginRoleId:sessionStorage.getItem('roleId'), // 当前登陆账号角色
    }
  },

  components: {add,edit},

  computed: {},

  mounted() {
    _this=this;
    this.init();
  },
  methods: {
    // 页面初始化
    async init(){
      await getUser({pageIndex:_this.pageIndex,pageSize:_this.pageSize}).then(res=>{
        if(res.Success){
          this.userInfo=res.Data.data;
          this.total=res.Data.totalCount;
          this.userInfo.forEach((element) => {
            element.CreateTime=this.timeToTime(element.CreateTime.substring(6,19));
            element.ModifiyTime=this.timeToTime(element.ModifiyTime.substring(6,19));
            element.IsDeleted=element.IsDeleted == 0 ? '否' : '是';
            element.IsLocked=element.IsLocked ? '是' : '否';
            for(let key in element){
              element[key] = (!element[key] && element[key] !=0) || element[key] == '' ? '--' : element[key]
            }
          });
        }
      });
    },
    // 时间戳转时间
    timeToTime(time){
       const date = new Date(Number(time))
       const Y = date.getFullYear() // 年
       const M = date.getMonth() + 1 < 10 ? '0'+(date.getMonth()+1) : date.getMonth()+1 // 月
       const D = date.getDate() < 10 ? '0' + date.getDate() : date.getDate() // 日
       const H = date.getHours() < 10 ? '0' + date.getHours() : date.getHours() // 时
       const m = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes() // 分
       const S = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds() // 秒 
       const Time=`${Y}-${M}-${D} ${H}:${m}:${S}`;
       return Time;
    },
    // 打开新增
    handleAdd(index,row){
      this.addDrawer=true;
    },
    // 打开修改
    handleEdit(index,row){
      this.editDrawer=true;
      this.id=index;
      this.row=row;
    },
    // 关闭
    handleClose(){
      this.addDrawer=false;
      this.editDrawer=false;
      this.init();
    },
    // 删除
    async handleDel(index,row){
      await this.$confirm('确认删除该用户吗？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          deleteUser({Id:row.Id}).then(res=>{
            if(res.Success){
              this.$message({
                message: "删除成功",
                type: "success",
              });
              this.init();
            }
          })
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });          
        });
    },
    // 冻结、解冻菜单
    handleLock(inde,row){
      this.$confirm('确认冻结/解冻该用户吗？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        }).then(() => {
          let islock=row.IsLocked=='否'?true:false;
          lockUser({Id:row.Id,IsLocked:islock}).then(res=>{
            if(res.Success){
              this.$message({
                message: "冻结/解冻成功",
                type: "success",
              });
              this.init();
            }
          })
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消'
          });          
        });
    },
    // 修改、新增、成功后
    handleDrawerSuccess(){
      this.addDrawer=false;
      this.editDrawer=false;
      this.init();
    },
    // 分页
    prevpage(val){
      this.pageIndex=val;
      this.init();
    },
    nextpage(val){
      this.pageIndex=val;
      this.init();
    },
    handleCurrentChange(val){
      this.pageIndex =val;
      this.init();
    },
  },
};
</script>
<style scoped>
  .user{
    width: 100%;
    background-color: white;
    height: 60px;
    width: 100%;
    padding: 10px 50px;
    position: relative;
  }
  .user button{
    position: absolute;
    right:20px;
    top:10px;
  }
</style>
