<template>
  <section>
    <el-form ref="form" label-position="right" status-icon :model="form" :rules="rules" label-width="25%" style="width:95%;overflow:auto">
      <el-form-item label="模板名称" prop="TemplateName">
        <el-input placeholder="请输入模板名称" v-model="form.TemplateName">
        </el-input>
      </el-form-item>
      <el-form-item label="模式类别" prop="TemplateType">
        <el-select v-model="form.TemplateType" placeholder="请选择" @change='selectMode'>
           <el-option
             v-for="item in typepOtions"
             :key="item.value"
             :label="item.label"
             :value="item.value">
           </el-option>
        </el-select>
      </el-form-item>  
      <el-form-item v-if="isPowerMode" label="功率限制时长" prop="TimeLength">
        <el-select v-model="form.TimeLength" placeholder="请选择" multiple @change="selectTimeLength">
           <el-option
             v-for="item in durationOptions"
             :key="item.value"
             :label="item.label"
             :value="item.value"
            >
           </el-option>
        </el-select>
      </el-form-item>
      <el-form-item v-if="isPowerMode" label="功率限制金额" prop="AmountLength">
        <el-select v-model="form.AmountLength" placeholder="请选择"  multiple @change="selectAmount">
           <el-option
             v-for="item in moneyOptions"
             :key="item.value"
             :label="item.label"
             :value="item.value"
            >
           </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="最低消费金额(元)" prop="LowestPayAmount">
        <el-input placeholder="请输入最低消费金额" type='number' v-model="form.LowestPayAmount">
        </el-input>
      </el-form-item>
      <el-form-item label="最低进入时间(分钟)" prop="LowestTime">
        <el-input placeholder="请输入最低进入时间" type='number' v-model="form.LowestTime">
        </el-input>
      </el-form-item>
      <el-form-item v-if="form.TemplateType=='功率模式'" label="最大功率(W)" prop="MaxPower">
        <el-input placeholder="请输入最大功率" type='number' v-model="form.MaxPower">
        </el-input>
      </el-form-item>
      <el-form-item v-if="form.TemplateType=='电量模式'" label="电费(元/度)" prop="ElectricityCost">
        <el-input placeholder="请输入保留两位小数的电费" type='number' v-model="form.ElectricityCost">
        </el-input>
      </el-form-item> 
      <el-form-item label="是否充满自停" prop="IsLeave">
         <el-radio-group v-model="form.IsLeave">
           <el-radio-button label="否"></el-radio-button>
           <el-radio-button label="是"></el-radio-button>
         </el-radio-group>
      </el-form-item>
      <el-form-item label="是否预付" prop="IsPayAdvance">
         <el-radio-group v-model="form.IsPayAdvance">
           <el-radio-button label="否"></el-radio-button>
           <el-radio-button label="是"></el-radio-button>
         </el-radio-group>
      </el-form-item>
      <el-form-item label="退款方式" prop="RefundMode">
         <el-radio-group v-model="form.RefundMode">
           <el-radio-button label="退回余额"></el-radio-button>
           <el-radio-button label="原路返回"></el-radio-button>
         </el-radio-group>
      </el-form-item>
      <!-- 功率模式梯度模板 -->
      <el-form-item v-if="isPowerMode && form.TemplateType !=='时间模式'" label="梯度模板" prop="PowerGradientTemplate" class="gradientTemplate">
        <div v-for="(item,index) in PowerGradientTemplate" :key="index" class='eachTemplate'>
          <p><span>小于</span><el-input type="number" v-model="item.w"></el-input><span>W</span></p>
          <p><el-input type="number" v-model="item.yuan"></el-input><span>元/小时</span></p>
          <b @click="delTemplate(index,'power')">×</b>
        </div>
        <el-button  class="button-new-tag" size="small" @click="showInput">+ 添加梯度</el-button>
      </el-form-item>
      <!-- 时间模式梯度模板 -->
      <el-form-item v-if="!isPowerMode" label="梯度模板" prop="TimeGradientTemplate" class="gradientTemplate">
        <div v-for="(item,index) in TimeGradientTemplate" :key="index" class='eachTemplate'>
          <p><el-input type="number" v-model="item.hour"></el-input><span>小时</span></p>
          <p><el-input type="number" v-model="item.dollor"></el-input><span>元</span></p>
          <b @click="delTemplate(index,'time')">×</b>
        </div>
        <el-button  class="button-new-tag" size="small" @click="addTimeTemplate">+ 添加梯度</el-button>
      </el-form-item>
      <el-form-item label="备注" prop="note">
         <el-input
           type="textarea"
           :rows="2"
           placeholder="请输入内容"
           v-model="form.Remarks">
         </el-input>
      </el-form-item>
      <el-form-item>
        <el-button @click="close('form')">取消</el-button>
        <el-button type="primary" @click="edit('form')" :plain='true'>立即修改</el-button>
      </el-form-item>
    </el-form>
  </section>
</template>

<script>
let _this;
import {updateBatteryConsumeTemplate,selectWechatUser} from '@/api/public.js';
let arr=[],arr2=[];
export default {
  props:{row:{type:Object}},
  data() {
    return {
      agents:[],
      // 模式类别选项
      typepOtions:[
        {value: '0',label: '功率模式'},
        {value: '1',label: '时间模式'},
        {value: '2',label: '电量模式'}
      ],
      isPowerMode:false,
      TemplateType:'',
      // 功率限制时长选项
      durationOptions:[
        {value: '选项1',label: '1小时'},
        {value: '选项2',label: '2小时'},
        {value: '选项3',label: '3小时'},
        {value: '选项4',label: '4小时'},
        {value: '选项5',label: '5小时'},
        {value: '选项6',label: '6小时'},
        {value: '选项7',label: '7小时'},
        {value: '选项8',label: '8小时'},
        {value: '选项9',label: '9小时'},
        {value: '选项10',label: '10小时'},
        {value: '选项11',label: '11小时'},
        {value: '选项12',label: '12小时'},
      ],
      // 功率限制金额选项
      moneyOptions:[
        {value: '选项1',label: '1元'},
        {value: '选项2',label: '2元'},
        {value: '选项3',label: '3元'},
        {value: '选项4',label: '4元'},
        {value: '选项5',label: '5元'},
        {value: '选项6',label: '6元'},
        {value: '选项7',label: '7元'},
        {value: '选项8',label: '8元'},
        {value: '选项9',label: '9元'},
        {value: '选项10',label: '10元'},
        {value: '选项11',label: '11元'},
        {value: '选项12',label: '12元'},
      ],
      form:{ParentId:''}, // 表单数据
      imageUrl:'',
      value:[],
      options:[],
      show:true,
      rules: {
        TemplateName: [
          { required: true, message: '模板名称不得为空', trigger: 'blur' }
        ],
        TemplateType: [
          { required: true, message: '模板类别不得为空', trigger: 'change' }
        ],
        TimeLength: [
          { required: true, message: '功率限制时长不得为空', trigger: 'change' }
        ],
        AmountLength: [
          { required: true, message: '功率限制金额不得为空', trigger: 'change' }
        ],
        LowestPayAmount: [
          { required: true, message: '最低消费金额不得为空', trigger: 'blur' }
        ],
        LowestTime: [
          { required: true, message: '最低进入时间不得为空', trigger: 'blur' }
        ],
        MaxPower: [
          { required: true, message: '最大功率不得为空', trigger: 'blur' }
        ],
        ElectricityCost: [
          { required: true, message: '电费不得为空', trigger: 'blur' },
          { pattern: /(^[1-9]([0-9]+)?(\.[0-9]{1,2})?$)|(^(0){1}$)|(^[0-9]\.[0-9]([0-9])?$)/, message: '请输入正确格式,可保留两位小数' }
        ],
        IsLeave: [
          { required: true, message: '请选择是否充满自离', trigger: 'change' }
        ],
        IsPayAdvance: [
          { required: true, message: '请选择是否预付', trigger: 'change' }
        ],
        RefundMode: [
          { required: true, message: '请选择退款方式', trigger: 'change' }
        ],
      },
      // 功率模板数据
      PowerGradientTemplate:[],
      // 时间模式模板数据
      TimeGradientTemplate:[],
      // 标签数据
      dynamicTags: [],
      inputVisible: false,
      inputValue: '',
      inputValue1:'',
    };
  },

  components: {},

  computed: {},

  mounted() {
    _this=this;
    this.form=this.row;
    this.init();
    arr=this.form.TimeLength;
    arr2=this.form.AmountLength;
    this.PowerGradientTemplate=JSON.parse(this.form.PowerGradientTemplate);
    this.TimeGradientTemplate=JSON.parse(this.form.TimeGradientTemplate);
    if(this.form.TemplateType=='功率模式' || this.form.TemplateType=='电量模式'){
      this.isPowerMode=true;
    }
  },

  methods: {
    async init(){
      await selectWechatUser({Role:1}).then(res=>{
        if(res.Success){
          this.agents=res.Data;
        }
      })
    },
    // 关闭
    close(){
      _this.$emit('close');
    },
    // 选择模式
    selectMode(e){
      if(e=='0' || e=='2'){
        this.isPowerMode=true;
      }else {
        this.isPowerMode=false;
      }
    },
    // 选择功率限制时长
    selectTimeLength(e){
      arr=[];
      e.forEach(element => {
        this.durationOptions.forEach(ele=>{
          if(element == ele.value || element == ele.label){
            arr.push(ele.label)
          }
        })
      });
    },
    // 选择功率限制金额
    selectAmount(e){
      arr2=[];
      e.forEach(element => {
        this.moneyOptions.forEach(ele=>{
          if(element == ele.value || element == ele.label){
            arr2.push(ele.label)
          }
        })
      });
    },
    // 添加时间梯度模板
    addTimeTemplate(){
      this.TimeGradientTemplate=this.TimeGradientTemplate == null ? [] : this.TimeGradientTemplate;
      this.TimeGradientTemplate.push({hour:'',dollor:''})
    },
    // 添加功率梯度模板
    showInput() {
      this.PowerGradientTemplate=this.PowerGradientTemplate == null ? [] : this.PowerGradientTemplate;
      this.PowerGradientTemplate.push({w:'',yuan:''})
    },
    // 删除模板
    delTemplate(index,mode){
      if(mode=='time'){
        this.TimeGradientTemplate.splice(index,1);
      }else{
        this.PowerGradientTemplate.splice(index,1);
      }
    },
     // 修改
     edit(form) {
       if(_this.form.IsLocked==='否'){
         _this.form.IsLocked=false;
       }else if(_this.form.IsLocked==='是'){
         _this.form.IsLocked=true;
       }
       this.form.IsLeave = this.form.IsLeave == '是' ? 1 : 0; // 是否充满自停
       this.form.IsPayAdvance = this.form.IsPayAdvance == '是' ? 1 : 0; // 是否预付
       this.form.RefundMode = this.form.RefundMode == '原路返回' ? 1 : 0; // 退款方式
       this.form.TimeLength= arr==[] || !arr?this.form.TimeLength:arr.join();//限制时长
       this.form.AmountLength= arr2==[] || !arr2?this.form.AmountLength:arr2.join();//限制金额
       if(this.isPowerMode){
         this.form.PowerGradientTemplate=JSON.stringify(this.PowerGradientTemplate);
         this.form.TimeGradientTemplate=[];
       }else{
         this.form.PowerGradientTemplate=[];
         this.form.TimeGradientTemplate=JSON.stringify(this.TimeGradientTemplate);
       }
       this.form.TemplateType=this.form.TemplateType=='功率模式'?0:(this.form.TemplateType=='时间模式'?1:2);
        _this.$refs["form"].validate(valid => {
          if (valid) {
            updateBatteryConsumeTemplate(_this.form)
              .then((res) => {
                if (res.Success) {
                  _this.$message({
                    message: "修改成功",
                    type: "success",
                  });
                  setTimeout(() => {
                    _this.$emit("success");
                    _this.$emit('close');
                  }, 1000);
                }else{
                  _this.$message({
                    message: "修改失败，请重试",
                    type: "fail",
                  });
                }
              })
              .catch(error => {});
          } else {
            return false;
          }
      });
    },
  }
};
</script>
<style lang="scss" scoped>
 .el-select{
   width: 100%;
   
 }

 .gradientTemplate{
   .el-input{
     border-radius: 0 !important;
   }
   .el-button{
     border:dashed #c0c4cc 1px;
     width:100%;
     outline:none;
   }
   p{
     display: flex;
     justify-content: flex-start;
     width: 49%;
     height: 40px;
     display: inline-block;
     box-sizing: border-box;
     span{
       display: inline-block;
       border:solid 1px #dbdee5 ;
       width:20%;
       height: 100%;
       box-sizing: border-box;
     }
   }
 } 

  // 标签

 .el-drawer{
   overflow:auto !important;
 }
 .avatar-uploader .el-upload {
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }
  .avatar-uploader-icon {
    border: 1px dashed #ccc;
    border-radius: 50%;
    overflow:hidden;
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }
  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }

  // 模板
  .eachTemplate{
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    align-items: center;
    p{
      width: 45%;
      display: flex;
      .el-input{
        flex: 1;
        outline: none;
      }
      span{
        display: inline-block;
        width: 30%;
        border: solid #dbdee5 1px;
        font-size: 13px;
      }
    }
    b{
      display: inline-block;
      height: 13px;
      width: 13px;
      line-height: 13px;
      border-radius: 50%;
      border: 2px rgb(240, 12, 12) solid;
      color: rgb(240, 12, 12);
      font-weight: 900;
      &:hover{
        cursor:pointer;
      }
    }
  }
</style>
