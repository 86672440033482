<template>
  <section>
    <!-- 搜索 -->
    <div class="search">
      <div class="inputs">
        <el-input class="wxName" v-model="searchInfo.data.Phone" placeholder="用户电话"></el-input>
        <el-input class="wxName" v-model="searchInfo.data.Licenseplate" placeholder="车牌号"></el-input>
        <el-input class="wxName" v-model="searchInfo.data.OwerName" placeholder="用户名称"></el-input>
        <div class="Btns">
           <el-button type="primary" @click="toSearch">查询</el-button>
           <el-button type="primary" @click="backAll">返回所有</el-button>
        </div>
      </div>
    </div>
    <div class="container">
      <el-table
        :data="parkingLockUser"
        style="width: 100%" 
        class="table"
        fit
      >
        <!-- 数据展示区 -->
        <el-table-column
          label="ID"
          prop="Id"
          align='center'
          width='100px'>
        </el-table-column>
        <af-table-column
          label="用户信息"
          prop="nickname"
          align='center'>
           <template slot-scope="scope"><p>{{scope.row.OwerName?scope.row.OwerName:'--'}}</p><p>{{scope.row.Phone?scope.row.Phone:'--'}}</p></template>
        </af-table-column>
        <af-table-column
          label="车牌号"
          prop="Licenseplate"
          align='center'>
        </af-table-column>
        <af-table-column
          label="申请中社区"
          prop="Communityapply"
          align='center'>
        </af-table-column>
        <af-table-column
          label="信用评分"
          prop="CreditScore"
          align='center'>
        </af-table-column>
        <af-table-column
          label="审核人"
          prop="AuditUser"
          align='center'>
        </af-table-column>
        <af-table-column
          label="审核结果"
          prop="AuditResult"
          align='center'>
          <template slot-scope="scope">
            <p>{{ scope.row.AuditResult == 1 ? '审核通过' : (scope.row.AuditResult == 2 ? '审核未通过' : '未审核') }}</p>
          </template>
        </af-table-column>
        <el-table-column label="操作" fixed="right" align='center' min-width="280">
          <template slot-scope="scope">
            <el-button v-if="scope.row.AuditResult == 0" size="mini" @click="audit(scope.$index,scope.row,1)">通过</el-button>
            <el-button v-if="scope.row.AuditResult == 0" size="mini" @click="audit(scope.$index,scope.row,2)">拒绝</el-button>
            <el-button size="mini" @click="handleEdit(scope.$index,scope.row)">编辑</el-button>
            <el-button size="mini" @click="handleDel(scope.$index,scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 修改角色 -->
      <el-drawer
        title="编辑"
        :visible.sync="editDrawer"
        :direction="direction">
        <edit v-if="editDrawer" @success="handleDrawerSuccess(form)  "  ref='newForm' :row="row"></edit>
      </el-drawer>
      <!--分页-->
      <el-col :span="24" class="toolbar" >
        <el-pagination
          layout="total, prev, pager, next, jumper"
          @current-change="handleCurrentChange"
          @prev-click="prevpage"
          @next-click="nextpage"
          :page-size="10"
          :total="total"
          style="float:right;"
        ></el-pagination>
      </el-col>
    </div>
  </section>
</template>

<script>
import {getWeChatOwerInfo,auditUpWeChatOwerInfo} from '@/api/public.js';
import edit from './edit.vue';

export default {
  data() {
    return {
      // 用户信息
      parkingLockUser:[],
      // 分页、查询信息
      total:0,
      searchInfo:{
        pageIndex:1,
        pageSize:10,
        data:{
          Phone:'',
          Licenseplate:'',
          OwerName:'',
        }
      },
      editDrawer:false,
      row:'',
      direction:'rtl'
    }
  },

  components: {edit},

  computed: {},

  mounted() {
    this.init();
  },

  methods: {
    // 数据初始化
    async init(){
      await getWeChatOwerInfo(this.searchInfo).then(res=>{
        if(res.Success){
          this.parkingLockUser=res.Data.data;
          this.total=res.Data.totalCount;
          // this.parkingLockUser.forEach(element=>{
          // })
        }
      })
    },
    // 条件查询
    toSearch(){
      this.pageIndex=1;
      this.init();
    },
    // 清除查询条件
    backAll(){
      this.searchInfo={
        pageIndex:1,
        pageSize:10,
        data:{
          Phone:'',
          Licenseplate:'',
          OwerName:'',
        }
      };
      this.init();
    },
    // 时间戳转时间
    timeToTime(time){
       const date = new Date(Number(time));
       const Y = date.getFullYear(); // 年
       const M = date.getMonth() + 1 < 10 ? '0'+(date.getMonth()+1) : date.getMonth()+1; // 月
       const D = date.getDate() < 10 ? '0' + date.getDate() : date.getDate(); // 日
       const H = date.getHours() < 10 ? '0' + date.getHours() : date.getHours(); // 时
       const m = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes(); // 分
       const S = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds(); // 秒 
       const Time=`${Y}-${M}-${D} ${H}:${m}:${S}`;
       return Time;
    },
    // 打开编辑弹出层
    handleEdit(index,row){
      this.row = row;
      this.editDrawer = true;
    },
    // 编辑成功
    handleDrawerSuccess(){
      this.editDrawer = false;
      this.init();
    },
    // 审核
    audit(index,row,type){
      this.$confirm('确认执行该操作吗？').then( async () => {
        await auditUpWeChatOwerInfo({Id:row.Id,AuditResult:type}).then(res=>{
          if(res.Success){
            this.$message({
              message:'操作成功',
              type:'success'
            })
          }else{
            this.$message({
              message:'操作失败，请重试',
              type:'fail'
            })
          }
        })
        this.init();
      })
    },
    handleDel(index,row){
      this.$confirm('确认删除改申请吗？').then(async ()=>{
        await deleteWeChatOwerInfo({Id:row.Id}).then(res=>{
          if(res.Success){
            this.$message({
              message:'操作成功',
              type:'success'
            })
          }else{
            this.$message({
              message:'操作失败，请重试',
              type:'fail'
            })
          }
        })
      })
    },
    // 分页
    prevpage(val){
      this.pageIndex=val;
      this.init();
    },
    nextpage(val){
      this.pageIndex=val;
      this.init();
    },
    handleCurrentChange(val){
      this.pageIndex =val;
      this.init();
    },
  },
  
};
</script>
<style scoped lang='scss'>
 /* 搜索 */
.search{
  margin-bottom: 30px;
  .inputs{
    display: flex;
    text-align: left;
  }
  .wxName,.select,.timeSelect{
    width: 15%;
    margin-right: 20px;
  }
  .Btns{
    display: flex;
    justify-content: flex-end;
    margin-bottom: 10px;
  }
}
/* 遮罩层 */
.cover{
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.3);
  z-index: 20000;
}
.inside{
  height: 300px;
  width: 500px;
  background: #fff;
  position: absolute;
  top: 100px;
  left:50%;
  margin: 0 0 0 -150px;
  padding: 20px;
  box-sizing: border-box;
}

.inside .title{
  height: 50px;
  line-height: 50px;
  border-bottom:solid 1px #f0f0f0 ;
}

.inside .cardBalance,.inside .sendBalance{
  height: 50px;
  line-height: 50px;
  text-align: left;
  margin-top: 20px;
}

.inside input{
  height: 30px;
  width: 300px;
  padding-left: 10px;
  outline: 0;
  border: #ccc solid 1px;
}

.inside .btn{
  position: absolute;
  right: 0;
  bottom: 0;
  height: 50px;
  width: 100%;
  border-top: solid 1px #f0f0f0 ;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.inside .btn button{
  height: 30px;
  width: 50px;
  border: solid 1px #ccc;
  border-radius: 5px;
  margin-right: 20px;
}

.inside .btn button:nth-child(2){
  background: #40a9ff;
  color: #fff;
}

.cardStatus{
  display: inline-block;
  background:#f6ffed;
  border:solid 1px #b7eb8f;
  padding: 3px;
  color:#52c41a;
  font-size: 14px;
  border-radius: 3px;
}
</style>
