<template>
  <section>
    <div class="add">
      <el-button type="primary" @click="handleAdd">添加</el-button>
    </div>
     <div class="container">
       <el-table
        :data="withdrawalNumInfo"
        style="width: 100%" 
        class="table"
        fit
      >
        <!-- 数据展示区 -->
        <el-table-column
          label="ID"
          prop="Id"
          align='center'
          width='60px'>
        </el-table-column>
        <el-table-column
          label="渠道"
          prop="Channel"
          align='center'
          >
        </el-table-column>
        <el-table-column
          label="银行名称"
          prop="BankName"
          align='center'>
        </el-table-column>
        <el-table-column
          label="支行名称"
          prop="SubBranchName"
          align='center'>
        </el-table-column>
        <el-table-column
          label="银行卡号"
          prop="BankNum"
          align='center'>
        </el-table-column>
        <el-table-column
          label="卡主名称"
          prop="BankOwnerName"
          align='center'>
        </el-table-column>
        <el-table-column
          label="支付宝账号"
          prop="AlipayNumber"
          align='center'>
        </el-table-column>
        <el-table-column
          label="支付宝账号名称"
          prop="AlipayName"
          align='center'>
        </el-table-column>
        <el-table-column
          label="所属用户"
          prop="CustomerName"
          align='center'>
        </el-table-column>
        <el-table-column
          label="微信昵称"
          prop="WeChatName"
          align='center'>
        </el-table-column> 
        <el-table-column
          label="创建时间"
          prop="CreateTime"
          align='center'>
        </el-table-column>
        
        <el-table-column label="操作" fixed="right" align='center'>
          <template slot-scope="scope">
            <el-button size="mini" @click="handleEdit(scope.$index, scope.row)">编辑</el-button>
            <!-- <el-button size="mini" @click="handleReview(scope.$index, scope.row)">详情</el-button> -->
            <!-- <el-button size="mini" @click="handleReview(scope.$index, scope.row)">删除</el-button> -->
          </template>
      </el-table-column>
      </el-table>
      <!--分页-->
      <el-col :span="24" class="toolbar" >
        <el-pagination
          layout="total, prev, pager, next, jumper"
          @current-change="handleCurrentChange"
          @prev-click="prevpage"
          @next-click="nextpage"
          :page-size="10"
          :total="total"
          style="float:right;"
        ></el-pagination>
      </el-col>
      <!-- 新增设备 -->
      <el-drawer
        title="新增账号"
        :visible.sync="addDrawer"
        :direction="direction"
        :before-close="handleClose">
        <add v-if="addDrawer" @close='handleClose' @success="handleDrawerSuccess(form)  "  ref='newForm' :id="id" :row="row"></add>
      </el-drawer>
      <!-- 修改运营状态 -->
      <el-drawer
        title="修改账号"
        :visible.sync="editDrawer"
        :direction="direction"
        :before-close="handleClose">
        <edit v-if="editDrawer" @close='handleClose' @success="handleDrawerSuccess(form)  "  ref='newForm' :id="id" :row="row"></edit>
      </el-drawer>
    </div>
  </section>
</template>

<script>
import {getWithdrawalNum} from '@/api/public.js';
import add from './add.vue';
import edit from './edit.vue';
export default {
  data() {
    return {
      addDrawer:false,
      editDrawer:false,
      id:'',
      row:'',
      direction:'rtl',
      // 分页信息
      pageIndex:1,
      pageSize:10,
      total:0,
      // 账号信息
      withdrawalNumInfo:[],
    }
  },

  components: {add,edit},

  computed: {},

  mounted() {
    this.init();
  },

  methods: {
    // 数据初始化
    async init(){
      await getWithdrawalNum({pageIndex:this.pageIndex,pageSize:this.pageSize}).then((res)=>{
        if(res.Success){
          this.withdrawalNumInfo=res.Data.data;
          this.total=res.Data.totalCount;
          this.withdrawalNumInfo.forEach(element => {
            element.CreateTime=this.timeToTime(element.CreateTime.substring(6,19));
            element.Channel=element.Channel == 0 ? '银行卡' : '支付宝';
            // 遍历对象，没有值的显示 / 
            for (let i in element) {
              element[i]=element[i] ? element[i] : '/';
            }
          });
        }
      })
    },
    // 时间戳转时间
    timeToTime(time){
       const date = new Date(Number(time));
       const Y = date.getFullYear() // 年
       const M = date.getMonth() + 1 < 10 ? '0'+(date.getMonth()+1) : date.getMonth()+1 // 月
       const D = date.getDate() < 10 ? '0' + date.getDate() : date.getDate() // 日
       const H = date.getHours() < 10 ? '0' + date.getHours() : date.getHours() // 时
       const m = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes() // 分
       const S = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds() // 秒 
       const Time=`${Y}-${M}-${D} ${H}:${m}:${S}`;
       return Time;
    },
    // 打开编辑
    handleEdit(index,row){
      this.row=row;
      this.editDrawer=true;
      this.id=index;
    },
    // 打开新增
    handleAdd(){
      this.addDrawer=true;
    },
    // 新增/修改成功
    handleDrawerSuccess(){
      this.addDrawer=false;
      this.editDrawer=false;
      this.init();
    },
    // 关闭
    handleClose(){
      this.$confirm('关闭窗口将清空表单内容，是否关闭？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(()=>{
        this.addDrawer=false;
        this.editDrawer=false;
        this.init();
      })
    },
    // 分页
    prevpage(val){
      this.pageIndex=val;
      this.init();
    },
    nextpage(val){
      this.pageIndex=val;
      this.init();
    },
    handleCurrentChange(val){
      this.pageIndex=val;
      this.init();
    },
  },
};
</script>
<style scoped>
  /* 添加 */
  .add{
    width: 100%;
    background-color: white;
    height: 60px;
    width: 100%;
    padding: 10px 50px;
    position: relative;
  }
  .add button{
    position: absolute;
    right:20px;
    top:10px;
  }
</style>
