<template>
  <div>
     <!-- 搜索 -->
    <div class="search">
      <div class="inputs">
        <!-- 订单状态 -->
        <!-- <el-select class='select' v-model="searchInfo.Data.sign" clearable placeholder="所属类型">
          <el-option
            v-for="item in typeOptions"
            :key="item.id"
            :label="item.label"
            :value="item.id">
          </el-option>
        </el-select> -->
        <el-input class="wxName" v-model="searchInfo.Data.remarks" placeholder="请输入字典备注"></el-input>
        <el-input class="wxName" v-model="searchInfo.Data.Name" placeholder="请输入字典名称"></el-input>
        <el-input class="wxName" v-model="searchInfo.Data.sign" placeholder="请输入所属类型"></el-input>
        <div class="Btns">
         <el-button type="primary" @click="toSearch">查询</el-button>
         <el-button  @click="backAll">返回所有</el-button>
        </div>
      </div>
    </div>
    <div class="add">
      <el-button type="primary" @click="handleAdd(null,'add')">添加</el-button>
    </div>
    <div class="container">
      <el-table
        :data="tableList"
        style="width: 100%" 
        class="table"
        fit
      >
        <!-- 数据展示区 -->
        <el-table-column
          label="ID"
          prop="Id"
          align='center'
          width='60px'>
        </el-table-column>
        <el-table-column
          label="字典名称"
          prop="Name"
          align='center'>
        </el-table-column>
        <el-table-column
          label="字典值"
          prop="code"
          align='center'>
        </el-table-column>
        <el-table-column
          label="字典备注"
          prop="remarks"
          align='center'>
        </el-table-column>
        <el-table-column
          label="所属类型"
          prop="sign"
          align='center'>
        </el-table-column>
        <el-table-column
          label="拓展描述"
          prop="explain"
          align='center'>
        </el-table-column>
        <el-table-column label="操作" fixed="right" align='center'>
          <template slot-scope="scope">
            <el-button size="mini" @click="handleAdd(scope.row,'edit')">编辑</el-button>
            <el-button size="mini" @click="handleDel(scope.row)">删除</el-button>
          </template>
      </el-table-column>
      </el-table>
      <!--分页-->
      <el-col :span="24" class="toolbar" >
        <el-pagination
          layout="total, prev, pager, next, jumper"
          @current-change="handleCurrentChange"
          @prev-click="prevpage"
          @next-click="nextpage"
          :page-size="10"
          :total="total"
          style="float:right;"
        ></el-pagination>
      </el-col>
      <!-- 新增菜单 -->
      <el-drawer
        :title="row ? '编辑' : '新增'"
        :visible.sync="addDrawer"
        :direction="direction"
        :before-close="handleClose"
        size='50%'
        style="overflow:auto">
        <add v-if="addDrawer" @close='handleClose' ref='newForm' :id="id" :row="row"></add>
      </el-drawer>
    </div>
  </div>
</template>

<script>
import { getWechatDictionaries,delWechatDictionaries,getWechatDictionariesList } from '@/api/public.js';
import add from './add.vue';
export default {
  data() {
    return {
        searchInfo:{
            pageIndex:1,
            pageSize:10,
            Data:{
              remarks:"",
              Name:"",
              sign:"",
            },
        },
        total:0,
        tableList:[],
        addDrawer:false,
        row:null,
        direction:'rtl',
        typeOptions:[],
    }
  },

  components: {add},

  computed: {},

  mounted() {
    this.init();
    this.getTypeList();
  },

  methods: {
    toSearch(){
      this.searchInfo.pageIndex = 1;
      this.init();
    },
    backAll(){
      this.searchInfo = {
          pageIndex:1,
          pageSize:10,
          Data:{
            remarks:"",
            Name:"",
            sign:"",
          },
      };
      this.init();
    },
    init(){
        getWechatDictionaries(this.searchInfo).then(res=>{
            if(res.Success){
                this.tableList = res.Data.data;
                this.total = res.Data.totalCount;
                this.tableList.forEach(element => {
                  for(let i=0;i<this.typeOptions.length;i++){
                    if(element.sign == this.typeOptions[i].code){
                      element.signName = this.typeOptions[i].Name;
                      break;
                    }
                  }
                });
            }else{
                this.tableList = []
            }
        })
    },
    getTypeList(){
        getWechatDictionariesList().then(res=>{
            if(res.Success){
                this.typeOptions = res.Data;
            }
        })
    },
    // 编辑
    handleAdd(row,type){
        this.row = row;
        this.addDrawer = true;
    },
    handleClose(){
        this.addDrawer = false;
        this.row = null;
    },
    // 删除
    handleDel(row){
        this.$confirm('确认删除吗？').then( async () => {
           await delWechatDictionaries({Id:row.Id}).then(res=>{
            if(res.Success){
              this.$message({
                message: "删除成功",
                type: "success",
              });
              this.init();
            }
          })
        })
    },
    // 分页
    prevpage(val){
      this.searchInfo.pageIndex=val;
      this.init();
    },
    nextpage(val){
      this.searchInfo.pageIndex=val;
      this.init();
    },
    handleCurrentChange(val){
      this.searchInfo.pageIndex =val;
      this.init();
    },
  },
};
</script>
<style scoped lang="scss">
  @import "@/static/default";
/* 搜索 */
.search{
  margin-bottom: 30px;
    .inputs{
      display: flex;
    }
    .wxName,.select,.timeSelect{
      width: 200px;
      margin-right: 20px;
    }
    .Btns{
      display: flex;
      justify-content: flex-end;
      margin-bottom: 10px;
    }
  }
  /* 添加 */
  .add{
    width: 100%;
    background-color: white;
    height: 60px;
    width: 100%;
    padding: 10px 50px;
    position: relative;
  }
  .add button{
    position: absolute;
    right:20px;
    top:10px;
    width: 100px;
  }
</style>
