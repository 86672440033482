<template>
  <section>
    <el-form ref="form" label-position="right" status-icon :model="form" :rules="rules" label-width="100px" style="width:95%;overflow:auto">
      <el-form-item label="绑定小区" prop="NeighbourhoodId">
        <el-select v-model="form.NeighbourhoodId" placeholder="请选择" @change="selectNeighbourhood" >
          <el-option
            v-for="item in options"
            :key="item.Id"
            :label="item.NeighbourhoodName"
            :value="item.Id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button @click="close('form')">取消</el-button>
        <el-button type="primary" @click="edit('form')" :plain='true'>立即绑定</el-button>
      </el-form-item>
    </el-form>
  </section>
</template>

<script>
let _this;
import {getNeibourhood,bandingNeighbourhoodBatteryCar,PlBandingNeighbourhoodBatteryCar} from '@/api/public.js';

export default {
  props:{row:{type:Object}},
  data() {
    return {
      form:{
        NeighbourhoodId:'',
      },
      value:[],
      options:[],
      value:'',
      rules:{
        NeighbourhoodId: [
          { required: true, message: '请选择绑定小区', trigger: 'change', }
        ],
      }
    };
  },

  components: {},

  computed: {},

  mounted() {
    _this=this;
    this.form.NeighbourhoodId=this.row?this.row.NeighbourhoodId:null;
    this.form.Id=this.row.Id;
    _this.init();
  },

  methods: {
    init(){
      getNeibourhood({pageIndex:1,pageSize:1000}).then((res)=>{
        if(res.Success){
          this.options=res.Data.data;
          this.total=res.Data.totalCount;
        }
      })
    },
    close(){
       _this.$emit('close');
    },
    selectNeighbourhood(e){
        this.form.NeighbourhoodId = e;
        this.value=e;
    },
     //  修改
    edit(form) {
       _this.$refs["form"].validate(valid => {
          if (valid) {
            if(this.row.isPL){
              PlBandingNeighbourhoodBatteryCar({Data1:this.row.Ids,Data2:this.form.NeighbourhoodId}).then(res=>{
                if (res.Success) {
                  _this.$message({
                    message: "批量绑定成功",
                    type: "success",
                  });
                  setTimeout(() => {
                    _this.$emit("success");
                    _this.$emit('close');
                    _this.init();
                  }, 1000);
                }else{
                  _this.$message({
                    message: "批量绑定失败，请重试",
                    type: "fail",
                  });
                }
              })
            }else{
              bandingNeighbourhoodBatteryCar(_this.form)
              .then((res) => {
                if (res.Success) {
                  _this.$message({
                    message: "绑定成功",
                    type: "success",
                  });
                  setTimeout(() => {
                    _this.$emit("success");
                    _this.$emit('close');
                    _this.init();
                  }, 1000);
                }else{
                  _this.$message({
                    message: "绑定失败，请重试",
                    type: "fail",
                  });
                }
              })
              .catch(error => {});
            }
            
          } else {
            return false;
          }
      });
    },
  }
};
</script>
<style lang="scss" scoped>
 .el-drawer{
   overflow:auto !important;
 }
 .avatar-uploader .el-upload {
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }
  .avatar-uploader-icon {
    border: 1px dashed #ccc;
    border-radius: 50%;
    overflow:hidden;
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }
  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }
</style>
