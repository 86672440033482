<template>
  <section>
    <div>
      <p class="title">
        <span>设备数据</span>
        <!-- <el-button @click="openLarge" size="mini" type="primary">查看大屏</el-button> -->
      </p>
      <div class="data">
        <p class="eachData"><span>总收益(元)</span><b>{{Number(sumInfo.sumOrderAmount).toFixed(2)}}</b></p>
        <p class="eachData"><span>订单总数</span><b>{{sumInfo.countOrderNum}}</b></p>
        <p class="eachData"><span>本月收益(元)</span><b>{{Number(sumInfo.Balance).toFixed(2)}}</b></p>
        <p class="eachData"><span>今日收益(元)</span><b>{{Number(sumInfo.sumjrOrderAmount).toFixed(2)}}</b></p>
        <p class="eachData"><span>今日订单数</span><b>{{sumInfo.countjrOrderNum}}</b></p>
        <p class="eachData"><span>枪口数</span><b>{{sumInfo.sumTotalElectricity}}</b></p>
        <p class="eachData"><span>运行设备数</span><b>{{sumInfo.countEquipmentNum}}</b></p>
        <p class="eachData"><span>离线设备数</span><b>{{sumInfo.countOperateNum}}</b></p>
      </div>
    </div>
    <div class="container">
      <!-- 收益 -->
      <div class="report">
        <div class="reportOne" id="income">
        </div>
        <el-select v-model="incomeValue" placeholder="请选择" @change='chooseIncome' style="height:auto">
          <el-option
            v-for="item in timeLong"
            :key="item.value"
            :label="item.label"
            :value="item.value"
            >
          </el-option>
        </el-select>
      </div>
      <!-- 订单 -->
      <div class="report">
        <div class=" reportTwo" id="order"></div>
        <el-select v-model="orderValue" placeholder="请选择" @change='chooseOrder' style="height:auto">
          <el-option
            v-for="item in timeLong"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
      <!-- 插座 -->
      <div class="report">
        <div class="reportThree" id="charge"></div>
      </div>
      <!-- 设备 -->
      <div class="report">
        <div class="reportFour" id="equipment"></div>
        <el-select v-model="equipValue" placeholder="请选择"  @change='chooseEquip' style="height:auto">
          <el-option
            v-for="item in timeLong"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
    </div>
  </section>
</template>

<script>
import * as echarts from 'echarts';
var inComeChart,orderChart,chargeChart,equipmentChart;
import {getSumInfo,incomeChart,OrderChart,portChart,EquipmentChart} from '@/api/public.js';
let that;
export default {
  data() {
    return {
      updateTime:'2022/05/28 12:12:43',
      sumInfo:{},
      incomeTime:[], // 收入图表x轴
      incomeData:[], // 收入图表y轴
      incomeLong:0,
      incomeValue:'最近七天',
      orderTime:[], // 订单图表x轴
      orderData:[], // 订单图表y轴
      orderLong:0,
      orderValue:'最近七天',
      portData:[], // 插座图表数据
      equipTime:[], //设备图表x轴
      equipData:[], //设备图表y轴
      equipLong:0,
      equipValue:'最近七天',
      timeLong:[{
        value:0,
        label:'最近七天',
      },{
        value:1,
        label:'最近七周',
      }]
    }
  },

  components: {},

  computed: {},

  mounted() {
    that=this;
    this.init();
  },

  methods: {
    // 打开大屏
    openLarge(){
      let token=sessionStorage.getItem('token');
      let role=sessionStorage.getItem('roleId');
      let url=`http://ledianxinnengyuan.com/largeScreenHtml/#/index?token=${token}&role=${role}`;
      window.open(url,"_blank");
    },
    async init(){
      // 展示数据
      await getSumInfo().then(res=>{
        if(res.Success){
          this.sumInfo=res.Data;
        }
      })
      // 收入
      await incomeChart({Data:this.incomeLong}).then(res=>{
        if(res.Success){
          this.incomeTime=[];
          this.incomeData=[];
          res.Data.forEach(element => {
            this.incomeTime.push(element.Datetime);
            this.incomeData.push(element.Data);
          });
        }
      })
      // 订单
      await OrderChart({Data:this.orderLong}).then(res=>{
        if(res.Success){
          this.orderTime=[];
          this.orderData=[];
          res.Data.forEach(element => {
            this.orderTime.push(element.Datetime);
            this.orderData.push(element.Data);
          });
        }
      })
      // 插座
      await portChart().then(res=>{
        if(res.Success){
          this.portData=res.Data;
        }
      })
      // 设备
      await EquipmentChart({Data:this.equipLong}).then(res=>{
        if(res.Success){
          this.equipTime=[];
          this.equipData=[];
          res.Data.forEach(element => {
            this.equipTime.push(element.Datetime);
            this.equipData.push(element.Data);
          });
        }
      })
      this.$nextTick(()=>{
        this.income();
        this.orderReport();
        this.chargeStatus();
        this.equipment();
      })
    },
    // 收入统计
    income(){
      var incomeChartDom = document.getElementById('income');
      inComeChart = echarts.init(incomeChartDom);
      setTimeout(()=>{
        var incomeOption = {
          title: {
            text: '收入增长趋势(不含当天数据)',
          },
          tooltip: { // 鼠标悬浮时显示当下数据详情
            trigger: 'axis'
          },
          xAxis: {
            type: 'category',
            data: that.incomeTime
          },
          yAxis: {
            type: 'value',
          },
          series: [
            {
              name:'金额',
              data: this.incomeData,
              type: 'line',
              smooth: true,
            }
          ]
        };
        incomeOption && inComeChart.setOption(incomeOption);
      },500)
    },
    // 订单统计
    orderReport(){
      var orderChartDom = document.getElementById('order');
      orderChart = echarts.init(orderChartDom);
      setTimeout(()=>{
        var orderOption = {
          title: {
            text: '订单新增趋势(不含当天数据)',
          },
          tooltip: {// 鼠标悬浮时显示当下数据详情
            trigger: 'axis'
          },
          xAxis: {
            type: 'category',
            data: that.orderTime,
          },
          yAxis: {
            type: 'value'
          },
          series: [
            {
              name:'订单量',
              data: that.orderData,
              type: 'line',
              smooth: true,
            }
          ]
        };
        orderOption && orderChart.setOption(orderOption);
      },500)  
      
    },
    // 插座状态
    chargeStatus(){
      var chargeChartDom = document.getElementById('charge');
      chargeChart = echarts.init(chargeChartDom);
      setTimeout(()=>{
        var chargeOption = {
          title: {
            text: '插座状态',
          },
          tooltip: {
            trigger: 'item'
          },
          series: [
            {
              name: '状态',
              type: 'pie',
              radius: '50%',
              data: this.portData,
              emphasis: {
                itemStyle: {
                  shadowBlur: 10,
                  shadowOffsetX: 0,
                  shadowColor: 'rgba(0, 0, 0, 0.5)'
                }
              }
            }
          ]
        };
        chargeOption && chargeChart.setOption(chargeOption);
      },500)
      
    },
    // 新设备趋势
    equipment(){
      var equipmentChartDom = document.getElementById('equipment');
      equipmentChart = echarts.init(equipmentChartDom);
      setTimeout(()=>{
        var equipmentOption = {
          title: {
            text: '新设备趋势(不含当天数据)',
          },
          tooltip: {// 鼠标悬浮时显示当下数据详情
            trigger: 'axis'
          },
          xAxis: {
            type: 'category',
            data:that.equipTime
          },
          yAxis: {
            type: 'value'
          },
          series: [
            {
              name:'新增设备',
              data: that.equipData,
              type: 'line',
              smooth: true,
            }
          ]
        };
  
        window.onresize = function() { // 一个页面只能有一个监听页面的 window.onresize 事件获取浏览器大小改变的事件，因此将三个图表放在一个事件内监听
          chargeChart.resize();
          orderChart.resize();
          inComeChart.resize();
          equipmentChart.resize();
        };
        equipmentOption && equipmentChart.setOption(equipmentOption);
      },500)
    },
    // 收入选择不同时间，数据不同
    async chooseIncome(e){
      this.incomeLong=e;
      await incomeChart({Data:this.incomeLong}).then(res=>{
        if(res.Success){
          this.incomeTime=[];
          this.incomeData=[];
          res.Data.forEach(element => {
            this.incomeTime.push(element.Datetime);
            this.incomeData.push(element.Data);
          });
        }
      })
      this.income();
    },
    // 订单选择不同时间，数据不同
    async chooseOrder(e){
      this.orderLong=e;
      await OrderChart({Data:this.orderLong}).then(res=>{
        if(res.Success){
          this.orderTime=[];
          this.orderData=[];
          res.Data.forEach(element => {
            this.orderTime.push(element.Datetime);
            this.orderData.push(element.Data);
          });
        }
      })
      this.orderReport();
    },
    // 设备选择不同时间，数据不同
    async chooseEquip(e){
      this.equipLong=e;
      await EquipmentChart({Data:this.equipLong}).then(res=>{
        if(res.Success){
          this.equipTime=[];
          this.equipData=[];
          res.Data.forEach(element => {
            this.equipTime.push(element.Datetime);
            this.equipData.push(element.Data);
          });
        }
      })
      this.equipment();
    }
  },
};
</script>
<style lang='scss' scoped>
.title{
    width: 100%;
    background-color: white;
    height: 60px;
    padding: 0 30px;
    position: relative;
    box-sizing: border-box;
    line-height: 60px;
    margin-bottom: 2px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .el-button{
      height: 40px;
    }
}
.data{
  display: flex;
  justify-content: space-around;
  background-color: white;
  padding: 30px 0;
  margin-bottom: 20px;
}
.data .eachData{
  display: flex;
  flex-direction: column;
}
.eachData span{
  margin-bottom: 10px;
  color: #8c8c8c;
  font-size: 16px;
}
.eachData b{
  font-size: 24px;
  font-weight: 400;
}

.container{
  /* background-color: #fff !important; */
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  .report{
    width: calc(50% - 40px);
    background: #fff;
    height: 300px;
    margin-bottom: 20px;
    padding: 10px;
    position: relative;
    div{
      width: 100%;
      height: 100%;
    }
    .el-select{
      width: 25%;
      position: absolute;
      top:10px;
      right: 30px;
    }
  }
}

</style>

