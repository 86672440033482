<template>
  <section>
    <el-form ref="form" label-position="right" status-icon :model="form" :rules="rules" label-width="100px" style="width:95%;overflow:auto">
      <el-form-item label="物业名称" prop="WeChatName" >
        <el-select v-model="form.WeChatUserId" placeholder="请选择物业" style="width:100%;" @change="selectWeChatUserId" >
          <el-option
            v-for="item in WeChatUserIdOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="场所名称" prop="NeighbourhoodName" >
        <el-input placeholder="请输入场所名称" v-model="form.NeighbourhoodName">
        </el-input>
      </el-form-item>
      <el-form-item label="所在省" prop="Provinces" >
        <el-select v-model="form.Provinces" placeholder="请选择省份" style="width:100%;" @change="chooseProvince" >
          <el-option
            v-for="item in Provinces"
            :key="item.name"
            :label="item.name"
            :value="item.name">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="所在市" prop="City" >
        <el-select v-model="form.City" placeholder="请选择所在市" style="width:100%;" @change="chooseCity" >
          <el-option
            v-for="item in City"
            :key="item.name"
            :label="item.name"
            :value="item.name">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="所在区" prop="Area" >
        <el-select v-model="form.Area" placeholder="请选择所在区" style="width:100%;" @change="chooseArea" >
          <el-option
            v-for="item in Area"
            :key="item.name"
            :label="item.name"
            :value="item.name">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="所在街道" prop="Street" >
        <el-select v-model="form.Street" placeholder="请选择街道" style="width:100%;" >
          <el-option
            v-for="item in Street"
            :key="item.name"
            :label="item.name"
            :value="item.name">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="详细地址" prop="Address" >
          <el-input placeholder="请输入详细地址" v-model="form.Address">
          </el-input>
      </el-form-item>
      <el-form-item label="投入成本" prop="InputCost" >
          <el-input placeholder="请输入投入成本" type="Number" v-model="form.InputCost">
          </el-input>
      </el-form-item>
      <el-form-item label="安装日期" prop="InstallDate" >
        <el-date-picker
          v-model="form.InstallDate"
          type="date"
          placeholder="选择安装日期">
        </el-date-picker>
      </el-form-item>
      <el-form-item>
        <el-button @click="close('form')">取消</el-button>
        <el-button type="primary" @click="edit('form')" :plain='true'>立即修改</el-button>
      </el-form-item>
    </el-form>
  </section>
</template>

<script>
let _this;
import {updateNeibourhood,getWechatUser,getAddressList} from '@/api/public.js';

export default {
  props:{row:{type:Object}},
  data() {
    return {
      form:{
        InputCost:0,
      },
      rules: {
         WeChatUserId: [
          { required: true, message: '请选择物业', trigger: 'change', }
        ],
        NeighbourhoodName: [
          { required: true, message: '请填写场所名称', trigger: 'blur', }
        ],
        Provinces: [
          { required: true, message: '请选择所在省', trigger: 'change', }
        ],
        City: [
          { required: true, message: '请选择所在市', trigger: 'change', }
        ],
        Area: [
          { required: true, message: '请选择所在区', trigger: 'change', }
        ],
        Street: [
          { required: true, message: '请选择所在街道', trigger: 'change', }
        ],
        Address: [
          { required: true, message: '请填写详细地址', trigger: 'blur', }
        ],
      },
      WeChatUserIdOptions:[],
      value:'',
      Provinces:[],
      City:[],
      Area:[],
      Street:[],
    };
  },

  components: {},

  computed: {},

  mounted() {
    _this=this;
    this.form=this.row;
    this.init();
  },

  methods: {
    async init(){
      await getWechatUser({pageIndex:1,pageSize:1000}).then(res=>{
        if(res.Success){
          res.Data.data.forEach((element,index) => {
            if(element.Role == 2){
              this.WeChatUserIdOptions.push({value:element.Id,label:element.WeChatName});
            }
          });
        }
      })
      this.Provinces=[];
      this.City=[];
      await getAddressList().then(res=>{
        res.forEach(element=>{
            this.Provinces.push(element);
        })
      })
      for(let i=0;i<this.Provinces.length;i++){
        if(this.form.Provinces==this.Provinces[i].name){
          let p=this.Provinces[i].children;
          p.forEach(ele=>{
            this.City.push(ele)
          })
        }
      }
      this.Area=[];
      for(let i=0;i<this.City.length;i++){
        if(this.form.City==this.City[i].name){
          let c=this.City[i].children;
          c.forEach(ele=>{
            this.Area.push(ele)
          })
        }
      }
      this.Street=[];
      for(let i=0;i<this.Area.length;i++){
        if(this.form.Area==this.Area[i].name){
          let a=this.Area[i].children;
          a.forEach(ele=>{
            this.Street.push(ele)
          })
        }
      }
    },
    chooseProvince(e){
      this.City=[];
      for(let i=0;i<this.Provinces.length;i++){
        if(e==this.Provinces[i].name){
          let p=this.Provinces[i].children;
          p.forEach(ele=>{
            this.City.push(ele)
          })
        }
      }
    },
    chooseCity(e){
      this.Area=[];
      for(let i=0;i<this.City.length;i++){
        if(e==this.City[i].name){
          let c=this.City[i].children;
          c.forEach(ele=>{
            this.Area.push(ele)
          })
        }
      }
    },
    chooseArea(e){
      this.Street=[];
      for(let i=0;i<this.Area.length;i++){
        if(e==this.Area[i].name){
          let a=this.Area[i].children;
          a.forEach(ele=>{
            this.Street.push(ele)
          })
        }
      }
    },
    close(){
       _this.$emit('close');
    },
    // 选择物业
    selectWeChatUserId(e){
      this.WeChatUserIdOptions.forEach(element=>{
        if(e==element.value){
          this.form.WeChatName=element.label;
        }
      })
      this.form.WeChatUserId=e;
    },
     //  添加
    edit(form) {
       _this.$refs["form"].validate(valid => {
          if (valid) {
            updateNeibourhood(_this.form)
              .then((res) => {
                if (res.Success) {
                  _this.$message({
                    message: "修改成功",
                    type: "success",
                  });
                  setTimeout(() => {
                    _this.$emit("success");
                    _this.init();
                  }, 1000);
                }else{
                  _this.$message({
                    message: "修改失败，请重试",
                    type: "fail",
                  });
                }
              })
              .catch(error => {});
          } else {
            return false;
          }
      });
    },
    
  }
};
</script>
<style lang="scss" scoped>
.el-date-editor.el-input{
  width: 100% !important;
}
 .el-drawer{
   overflow:auto !important;
 }
 .avatar-uploader .el-upload {
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }
  .avatar-uploader-icon {
    border: 1px dashed #ccc;
    border-radius: 50%;
    overflow:hidden;
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }
  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }
</style>
