<template>
  <div class="container">
    <el-table
      :data="faultList"
      style="width: 100%" 
      class="table"
      fit
    >
      <!-- 数据展示区 -->
      <el-table-column
        label="设备编号"
        prop="EquipmentNum"
        align='center'>
      </el-table-column>
      <el-table-column
        label="场所名称"
        prop="NeighbourhoodName"
        align='center'>
      </el-table-column>
      <el-table-column
        label="场所地址"
        prop="Address"
        align='center'>
      </el-table-column>
      <el-table-column
        label="用户名称"
        prop="WeChatName"
        align='center'>
      </el-table-column>
      <el-table-column
        label="故障描述"
        prop="FaultDescribe"
        align='center'>
      </el-table-column>
      <el-table-column
        label="上报时间"
        prop="CreateTime"
        align='center'>
      </el-table-column>
    </el-table>
    <!--分页-->
    <el-col :span="24" class="toolbar" >
      <el-pagination
        layout="total, prev, pager, next, jumper"
        @current-change="handleCurrentChange"
        @prev-click="prevpage"
        @next-click="nextpage"
        :page-size="pageSize"
        :total="total"
        style="float:right;"
      ></el-pagination>
    </el-col>
  </div>
</template>

<script>
import {getFaultReportInfo} from '@/api/public.js';
export default {
  data() {
    return {
        faultList:[], // 上报列表
        pageIndex:1, // 当前页码
        pageSize:10, // 每页数据条数
        total:0, // 数据总数
    }
  },

  components: {},

  computed: {},

  mounted() {
    this.init();
  },

  methods: {
    // 获取上报列表
    init(){
        getFaultReportInfo({pageIndex:this.pageIndex,pageSize:this.pageSize}).then(res=>{
            if(res.Success){
              this.faultList=res.Data.data;
              this.total=res.Data.totalCount;
              this.faultList.forEach(element=>{
                element.CreateTime=this.timeToTime(element.CreateTime.substring(6,19));
                for(let key in element){
                  if( !element[key] && element[key] !== 0){
                    element[key]='--';
                  }
                }
              })
            }
        })
    },
    // 时间戳转时间
    timeToTime(time){
       const date = new Date(Number(time))
       const Y = date.getFullYear() // 年
       const M = date.getMonth() + 1 < 10 ? '0'+(date.getMonth()+1) : date.getMonth()+1 // 月
       const D = date.getDate() < 10 ? '0' + date.getDate() : date.getDate() // 日
       const H = date.getHours() < 10 ? '0' + date.getHours() : date.getHours() // 时
       const m = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes() // 分
       const S = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds() // 秒 
       const Time=`${Y}-${M}-${D} ${H}:${m}:${S}`;
       return Time;
    },
    // 分页
    handleCurrentChange(val){
      this.pageIndex=val;
      this.init();
    },
    prevpage(val){
      this.pageIndex=val;
      this.init();
    },
    nextpage(val){
      this.pageIndex=val;
      this.init();
    },
  },
};
</script>
<style scoped lang="scss">
.container{
    background-color: #fff !important;
    padding:0 32px;
    .toolbar{
      background: #fff;
      width:calc(100% + 64px);
      padding:16px 32px 26px 0;
      margin-left:-32px;
    }
  }
</style>
