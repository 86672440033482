<template>
  <section>
    <el-form ref="form" label-position="right" status-icon :model="form" :rules="rules" label-width="100px" style="width:95%;overflow:auto">
      <el-form-item label="设备编号" prop="EquipmentLockNum" >
        <el-input placeholder="请输入设备编号" v-model="form.EquipmentLockNum"></el-input>
      </el-form-item>
      <el-form-item label="通讯地址" prop="port" >
        <el-input placeholder="请输入通讯地址" v-model="form.port"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button @click="close('form')">取消</el-button>
        <el-button type="primary" @click="add('form')" :plain='true'>提交</el-button>
      </el-form-item>
    </el-form>
  </section>
</template>

<script>
let _this;
import {addEquipmentFloorLockInfo} from '@/api/public.js';
export default {
  data() {
    return {
        form:{
            EquipmentLockNum:'',
            port:'',
        },
        rules: {
           EquipmentLockNum: [
             { required: true, message: '设备编号不得为空', trigger: 'blur', }
           ],
           port: [
             { required: true, message: '通讯地址不得为空', trigger: 'blur', }
           ],
         },
    }
  },

  components: {},

  computed: {},

  mounted() {
    _this=this;
  },

  methods: {
    add(form) {
       _this.$refs["form"].validate(valid => {
          if (valid) {
            addEquipmentFloorLockInfo(_this.form)
              .then((res) => {
                if (res.Success) {
                  _this.$message({
                    message: "提交成功",
                    type: "success",
                  });
                  setTimeout(() => {
                    _this.$emit("success");
                    _this.$emit('close');
                    _this.init();
                  }, 1000);
                }else{
                  _this.$message({
                    message: "提交失败，请重试",
                    type: "fail",
                  });
                }
              })
              .catch(error => {});
          } else {
            return false;
          }
      });
    },
  },
};
</script>
<style scoped></style>
