<template>
  <div class="container">
    <div class="detail">
        <h3>订单详情</h3>
        <p><span>订单编号</span><span>{{detailInfo.OrderNum}}</span></p>
        <p><span>订单状态</span><span>{{detailInfo.Process}}</span></p>
        <p class="short"><span>订单类型</span><span>{{detailInfo.OrderType}}</span></p>
        <p><span>支付类型</span><span>{{detailInfo.PayType === 0 ? '微信支付' : (detailInfo.PayType === 1 ? '余额支付':(detailInfo.PayType === 2 ? '内部员工' :'刷卡充电'))}}</span></p>
        <p><span>消费模板</span><span>{{detailInfo.TemplateName}}</span></p>
        <p class="short"><span>预收金额</span><span>{{detailInfo.OrderAmount}}元</span></p>
        <p><span>结算金额</span><span>{{detailInfo.RealAmount}}元</span></p>
        <p><span>耗电量</span><span>{{detailInfo.TotalElectricity}}</span></p>
        <p class="short"><span>开始时间</span><span>{{detailInfo.BeginTime}}</span></p>
        <p><span>结束时间</span><span>{{detailInfo.EndTime}}</span></p>
        <p><span>结束原因</span><span>{{detailInfo.StopWhy}}</span></p>
        <p class="short"><span>设备编号</span><span>{{detailInfo.EquipmentNum}}</span></p>
        <p><span>设备名称</span><span>{{detailInfo.Address}}</span></p>
        <p><span>充电端口</span><span>{{detailInfo.EquipmentPort}}号</span></p>
        <p class="short"><span>用户类型</span><span>{{detailInfo.PayType===3?'卡用户':'手机用户'}}</span></p>
        <p><span>联系人/昵称</span><span>{{detailInfo.PayType === 3 ? detailInfo.Attribution : detailInfo.WeChatName}}</span></p>
        <p><span>卡号/电话</span><span>{{detailInfo.PayType === 3 ? detailInfo.CardId : detailInfo.Phone}}</span></p>
        <p class="short"><span>所属代理</span><span>{{detailInfo.CustomerName}}</span></p>
    </div>
    <div class="charts" style="">
        <el-tabs v-model="activeName">
          <el-tab-pane label="折线图" name="first">
            <div id="power"></div>
            <div id="voltage"></div>
            <div id="electricCurrent"></div>
          </el-tab-pane>
          <el-tab-pane label="分润信息" name="second">
            <div class="benefit">
              <div>
                <span>用户名称</span>
                <span>用户类型</span>
                <span>分润金额(元)</span>
                <span>分润比例(%)</span>
              </div>
              <div v-for="(item,index) in benefitInfo" :key="index">
                <span>{{item.CustomerName}}</span>
                <span>{{item.Role}}</span>
                <span>{{item.BenefitAmount}}</span>
                <span>{{item.ProfitRatio}}</span>
              </div>
            </div>
          </el-tab-pane>
        </el-tabs>
    </div>
  </div>
</template>

<script>
import * as echarts from 'echarts';
import {selectBatteryOrder,getBatteryPowerChart,getBatteryVoltageChart,getBatteryElectricCurrentChart,getBatteryBenefit} from '@/api/public.js';
let powerChart,voltageChart,electricChart;
let that;
export default {
  props:{row:{type:Object}},
  data() {
    return {
        activeName:'first',
        detailInfo:{},
        benefitInfo:[], // 分润信息
        powerData:[],
        powerTime:[],
        voltageData:[],
        voltageTime:[],
        electricData:[],
        electricTime:[],
    }
  },

  components: {},

  computed: {},

  mounted() {
    that=this;
    this.init();
    this.power();
    this.voltage();
    this.electric();
  },

  methods: {
    async init(){
        await selectBatteryOrder({OrderNum:this.row.orderNum}).then(res=>{
            if(res.Success){
                this.detailInfo=res.Data[0];
                this.detailInfo.BeginTime=this.timeToTime(this.detailInfo.BeginTime.substring(6,19));
                this.detailInfo.EndTime=this.detailInfo.Process == 2 && this.detailInfo.OrderState == 2 ? this.timeToTime(this.detailInfo.EndTime.substring(6,19)) : '/';
                this.detailInfo.Process=this.detailInfo.Process==0 ? '待支付' : (this.detailInfo.OrderState==1 && this.detailInfo.Process== 0 ? '已支付' : (this.detailInfo.OrderState==1 && this.detailInfo.Process==1?'充电中':'充电完成') );
                this.detailInfo.OrderAmount=this.detailInfo.OrderAmount==0?'待结算':this.detailInfo.OrderAmount;
                this.detailInfo.TotalElectricity = this.detailInfo.TotalElectricity==null?'待结算':this.detailInfo.TotalElectricity;
                let type=this.detailInfo.OrderNum.substring(this.detailInfo.OrderNum.length-1,this.detailInfo.OrderNum.length);
                if(type==1){
                  this.detailInfo.OrderType='时间模式';
                }else if(type==2){
                  this.detailInfo.OrderType='功率模式充满自离';
                }else if(type==3){
                  this.detailInfo.OrderType='功率模式时间计费';
                }else if(type==4){
                  this.detailInfo.OrderType='功率模式金额计费';
                }else if(type==5){
                  this.detailInfo.OrderType='电量模式金额计费';
                }else if(type==6){
                  this.detailInfo.OrderType='电量模式时间计费';
                }else if(type==7){
                  this.detailInfo.OrderType='电量模式充满自离';
                }else{
                  this.detailInfo.OrderType='/';
                }
            }
        })
        await getBatteryPowerChart({OrderNum:this.row.orderNum}).then(res=>{
            if(res.Success){
                this.powerData=[];
                this.powerTime=[]
                res.Data.forEach(element => {
                    element.name=this.timeToTime(element.name.substring(6,19));
                    this.powerData.push(element.value);
                    this.powerTime.push(element.name)
                });
            }
        })
        await getBatteryVoltageChart({OrderNum:this.row.orderNum}).then(res=>{
            if(res.Success){
                this.voltageData=[];
                this.voltageTime=[]
                res.Data.forEach(element => {
                    element.name=this.timeToTime(element.name.substring(6,19));
                    this.voltageData.push(element.value);
                    this.voltageTime.push(element.name)
                });
            }
        })
        await getBatteryElectricCurrentChart({OrderNum:this.row.orderNum}).then(res=>{
            if(res.Success){
                this.electricData=[];
                this.electricTime=[]
                res.Data.forEach(element => {
                    element.name=this.timeToTime(element.name.substring(6,19));
                    this.electricData.push(element.value);
                    this.electricTime.push(element.name)
                });
            }
        })
        await getBatteryBenefit({OrderNum:this.row.orderNum}).then(res=>{
            if(res.Success){
                this.benefitInfo=res.Data;
                this.benefitInfo.forEach(element=>{
                    element.Role=element.Role == 1 ? '代理商' :(element.Role == 2 ? '物业' : '系统');
                    element.CustomerName=!element.CustomerName? '系统' :element.CustomerName;
                })
            }
        })
    },
    // 功率统计
    power(){
      var powerChartDom = document.getElementById('power');
      powerChart = echarts.init(powerChartDom);
      setTimeout(()=>{
        var powerOption = {
          title: {
            text: '功率趋势',
          },
          tooltip: { // 鼠标悬浮时显示当下数据详情
            trigger: 'axis'
          },
          xAxis: {
            type: 'category',
            data: that.powerTime
          },
          yAxis: {
            type: 'value',
          },
          series: [
            {
              name:'',
              data: this.powerData,
              type: 'line',
              smooth: true,
            }
          ]
        };
        powerOption && powerChart.setOption(powerOption);
      },500)
    },
    // 电压统计
    voltage(){
      var voltageChartDom = document.getElementById('voltage');
      voltageChart = echarts.init(voltageChartDom);
      setTimeout(()=>{
        var voltageOption = {
          title: {
            text: '电压趋势',
          },
          tooltip: { // 鼠标悬浮时显示当下数据详情
            trigger: 'axis'
          },
          xAxis: {
            type: 'category',
            data: that.voltageTime
          },
          yAxis: {
            type: 'value',
          },
          series: [
            {
              name:'',
              data: this.voltageData,
              type: 'line',
              smooth: true,
            }
          ]
        };
        voltageOption && voltageChart.setOption(voltageOption);
      },500)
    },
    // 电流统计
    electric(){
      var electricChartDom = document.getElementById('electricCurrent');
      electricChart = echarts.init(electricChartDom);
      setTimeout(()=>{
        var electricOption = {
          title: {
            text: '电流趋势',
          },
          tooltip: { // 鼠标悬浮时显示当下数据详情
            trigger: 'axis'
          },
          xAxis: {
            type: 'category',
            data: that.electricTime
          },
          yAxis: {
            type: 'value',
          },
          series: [
            {
              name:'',
              data: this.electricData,
              type: 'line',
              smooth: true,
            }
          ]
        };
        electricOption && electricChart.setOption(electricOption);
      },500)
    },
    // 时间戳转时间
    timeToTime(time){
       const date = new Date(Number(time))
       const Y = date.getFullYear() // 年
       const M = date.getMonth() + 1 < 10 ? '0'+(date.getMonth()+1) : date.getMonth()+1 // 月
       const D = date.getDate() < 10 ? '0' + date.getDate() : date.getDate() // 日
       const H = date.getHours() < 10 ? '0' + date.getHours() : date.getHours() // 时
       const m = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes() // 分
       const S = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds() // 秒 
       const Time=`${Y}-${M}-${D} ${H}:${m}:${S}`;
       return Time;
    },
  },
};
</script>
<style scoped lang='scss'>
  .benefit{
    display: flex;
    flex-direction: column;
    div{
      width: 100%;
      box-sizing: border-box;
      display: flex;
      margin-bottom: 0 !important;
      height: 40px!important;
      &:nth-child(1){
        background: #fafafa;
      }
      span{
        display: inline-block;
        width: 25%;
        border: solid 1px #f0f0f0;
        height: 40px;
        line-height: 40px;
        font-size: 14px;
      }
    }
  }
  .container[data-v-40622109]{
      overflow: auto;
      height: 100%;
  }
  .el-tabs__header /deep/ .el-tabs__content{
    overflow: auto !important;
  }
  /deep/ .el-tabs__content{
    overflow:auto !important;
  }
   /deep/  .el-drawer__wrapper{
    z-index: 4000 !important;
  }
  .detail{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 90%;
    margin: 0 auto;
    margin-bottom: 50px;
    font-size: 14px;
    h3{
        width: 100%;
        height: 70px;
        line-height: 70px;
        text-align: left;
    }
    p{
        // line-height: 16px;
        box-sizing: border-box;
        width: 35%;
        display: flex;
        span{
            display: inline-block;
            background: #fff;
            border:solid #f0f0f0 1px;
            width: 70%;
            box-sizing: border-box;
            height: 40px;
            line-height: 40px;
            &:nth-child(1){
                background: #fafafa;
                width: 30%;
            }
        }
    }
    .short{
        width: 30%;
    }
  }
  .charts{
    width: 90%;
    margin: 0 auto;
    height: 100%;
    div{
        width: 100%;
        height: 350px;
        margin-bottom: 30px;
    }
  }
</style>
