<template>
<section>
  <!-- 搜索 -->
  <div class="search">
    <div class="inputs">
      <el-input class="wxName" v-model="searchInfo.CustomerName" placeholder="请输入所属用户"></el-input>
      <el-date-picker
         v-model="time"
         type="datetimerange"
         range-separator="至"
         start-placeholder="开始时间"
         end-placeholder="结束时间"
         @change='chooseTime'
         class='timeSelect'>
      </el-date-picker>
      <div class="Btns">
       <el-button type="primary" @click="toSearch">查询</el-button>
       <el-button  @click="backAll">返回所有</el-button>
    </div>
    </div>
  </div>
  <div class="container">
    <div class="btns">
      <el-button type="primary" @click="educe">分润导出</el-button>
    </div>
    <el-table
      :data="benefitInfo"
      style="width: 100%" 
      class="table"
      fit
    >
      <!-- 数据展示区 -->
      <el-table-column
        label="ID"
        prop="Id"
        align='center'
        width='60px'>
      </el-table-column>
      <el-table-column
        label="订单编号"
        prop="OrderNum"
        align='center'>
      </el-table-column>
      <el-table-column
        label="订单金额(元)"
        prop="OrderAmount"
        align='center'>
      </el-table-column>
      <el-table-column
        label="分润金额(元)"
        prop="BenefitAmount"
        align='center'>
      </el-table-column>
      <el-table-column
        label="分润比例(%)"
        prop="ProfitRatio"
        align='center'>
      </el-table-column>
      <el-table-column
        label="所属用户"
        prop="CustomerName"
        align='center'>
      </el-table-column>
      <el-table-column
        label="用户角色"
        prop="Role"
        align='center'>
      </el-table-column>
    </el-table>
    <!--分页-->
    <el-col :span="24" class="toolbar" >
      <el-pagination
        layout="total, prev, pager, next, jumper"
        @current-change="handleCurrentChange"
        @prev-click="prevpage"
        @next-click="nextpage"
        :page-size="10"
        :total="total"
        style="float:right;"
      ></el-pagination>
    </el-col>
  </div>
</section>
</template>

<script>
import{ getBenefit,exportBenefit } from '@/api/public.js';
import {baseUrl} from '@/api/url.js';
export default {
  data() {
    return {
      // 分润明细数据
      benefitInfo:[],
      // 分页信息
      pageIndex:1,
      pageSize:10,
      total:0,
      time:'',
      searchInfo:{
        SBeginTime:'',
        SEndTime:'',
        CustomerName:'',
      },
    }
  },

  components: {},

  computed: {},

  mounted() {
    this.init();
  },

  methods: {
    // 数据初始化
    async init(){
      await getBenefit({pageIndex:this.pageIndex,pageSize:this.pageSize,data:this.searchInfo}).then((res)=>{
        if(res.Success){
          this.benefitInfo=res.Data.data?res.Data.data:[];
          this.total=res.Data.totalCount;
          this.benefitInfo.forEach(element => {
            element.CreateTime=this.timeToTime(element.CreateTime.substring(6,19));
            element.Role=element.Role == 0 ? '用户' : (element.Role == 1 ? '代理商' : (element.Role == 2 ? '物业' : (element.Role == 3 ? '合伙人' : '管理员' )));
          });
        }
      })
    },
    // 时间戳转时间
    timeToTime(time){
       const date = new Date(Number(time))
       const Y = date.getFullYear() // 年
       const M = date.getMonth() + 1 < 10 ? '0'+(date.getMonth()+1) : date.getMonth()+1 // 月
       const D = date.getDate() < 10 ? '0' + date.getDate() : date.getDate() // 日
       const H = date.getHours() < 10 ? '0' + date.getHours() : date.getHours() // 时
       const m = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes() // 分
       const S = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds() // 秒 
       const Time=`${Y}-${M}-${D} ${H}:${m}:${S}`;
       return Time;
    },
    // 中国标准时间转年月日
    timeChange(time,ifend){
      var chinaStandard=time;
        var date = new Date(chinaStandard);
        var y = date.getFullYear();
        var m = date.getMonth() + 1;
        m = m < 10 ? ('0' + m) : m;
        var d = date.getDate();
        d = d < 10 ? ('0' + d) : d;
        var h = date.getHours();
        var minute = date.getMinutes();
        minute = minute < 10 ? ('0' + minute) : minute;
        var second = date.getSeconds();
        second = second < 10 ? ('0' + second) : second;
        let Time;
        if(ifend){
          Time = y + '/' + m + '/' + d+' 23:59:59';
        }else{
          Time = y + '/' + m + '/' + d+' '+h+':'+minute+ ':' + second;
        }
        return Time;
    },
    // 时间选择
    chooseTime(e){
      this.searchInfo.SBeginTime= this.timeChange(e[0]);
      this.searchInfo.SEndTime= this.timeChange(e[1]);
    },
    // 条件查询
    toSearch(){
      this.pageIndex=1;
      this.init();
    },
    // 清除查询条件
    backAll(){
      this.pageIndex=1;
      this.searchInfo={};
      this.time='';
      this.init();
    },
    // 导出 
    async educe(){
      await exportBenefit({CustomerName:this.searchInfo.CustomerName}).then(res=>{
        if(res.Success){
          window.open(baseUrl+'/'+res.Data);
        }
      })
    },
    // 分页
    prevpage(val){
      this.pageIndex=val;
      this.init();
    },
    nextpage(val){
      this.pageIndex=val;
      this.init();
    },
    handleCurrentChange(val){
      this.pageIndex =val;
      this.init();
    },
  },
};
</script>
<style scoped lang='scss'>
  @import "@/static/default";
/* 搜索 */
.search{
  margin-bottom: 30px;
    .inputs{
      display: flex;
    }
    .wxName,.select{
      width: 150px;
      margin-right: 20px;
    }
    .timeSelect{
      margin-right: 20px;
    }
    .Btns{
      display: flex;
      justify-content: flex-end;
      margin-bottom: 10px;
    }
  }
// 按钮
  .btns{
    width: 100%;
    background-color: white;
    height: 60px;
    width: 100%;
    padding: 10px 50px;
    position: relative;
    button{
      position: absolute;
      right:20px;
      top:10px;
    }
  }
/* 内容 */
  .container{
    background-color: #fff !important;
    padding:0 32px;
    .table{
    margin-top:24px !important;
    }
    .toolbar{
      background: #fff;
      width:calc(100% + 64px);
      padding:16px 32px 26px 0;
      margin-left:-32px;
    }
  }
</style>
