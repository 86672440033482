<template>
  <section>
    <el-form ref="form" label-position="right" status-icon :model="form" :rules="rules" label-width="100px" style="width:95%;overflow:auto">
      <el-form-item label="设备编号" prop="EquipmentNum" >
        <el-input placeholder="请输入设备编号" v-model="form.EquipmentNum">
        </el-input>
      </el-form-item>
      <el-form-item label="设备类型" prop="EquipmentType" >
        <el-select v-model="form.EquipmentType" placeholder="请选择" @change='selectType'>
           <el-option
             v-for="item in typepOtions"
             :key="item.value"
             :label="item.label"
             :value="item.value">
           </el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button @click="close('form')">取消</el-button>
        <el-button type="primary" @click="add('form')" :plain='true'>立即添加</el-button>
      </el-form-item>
    </el-form>
  </section>
</template>

<script>
let _this;
import {addEquipment} from '@/api/public.js';

export default {
//   props:{row:{type:Object}},
  data() {
    return {
      form:{
          EquipmentNum:'',
          EquipmentType:'',
      },
      typepOtions:[
        {value:2,label:'单枪慢充桩'}
      ],
      rules: {
        EquipmentNum: [
          { required: true, message: '设备编号不得为空', trigger: 'blur', }
        ],
        EquipmentType: [
          { required: true, message: '请选择设备类型', trigger: 'change', }
        ],
      },
    };
  },

  components: {},

  computed: {},

  mounted() {
    _this=this;
  },

  methods: {
    close(){
       _this.$emit('close');
    },
    // 选择设备类型
    selectType(e){
      this.form.EquipmentType=e;
    },
     //  添加
    add(form) {
       _this.$refs["form"].validate(valid => {
          if (valid) {
            addEquipment(_this.form)
              .then((res) => {
                if (res.Success) {
                  _this.$message({
                    message: "添加成功",
                    type: "success",
                  });
                  setTimeout(() => {
                    _this.$emit("success");
                    _this.$emit('close');
                    _this.init();
                  }, 1000);
                }else{
                  _this.$message({
                    message: "添加失败，请重试",
                    type: "fail",
                  });
                }
              })
              .catch(error => {});
          } else {
            return false;
          }
      });
    },
    
  }
};
</script>
<style lang="scss" scoped>
 .el-drawer{
   overflow:auto !important;
 }
 .avatar-uploader .el-upload {
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }
  .avatar-uploader-icon {
    border: 1px dashed #ccc;
    border-radius: 50%;
    overflow:hidden;
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }
  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }
</style>
