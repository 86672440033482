<template>
  <section>
    <el-form ref="form" label-position="right" status-icon :model="form" label-width="25%" style="width:95%;overflow:auto">
      <el-form-item label="模板名称" prop="TemplateName">
        <el-input v-model="form.TemplateName" disabled>
        </el-input>
      </el-form-item>
      <el-form-item label="收费挡位（元）" prop="AmountGears">
        <el-select v-model="form.AmountGears"  multiple disabled>
           <el-option
             v-for="item in moneyOptions"
             :key="item.value"
             :label="item.label"
             :value="item.value"
            >
           </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="尖电电费费率(元/度)" prop="PointedAmount">
        <el-input v-model="form.PointedAmount" disabled>
        </el-input>
      </el-form-item>
      <el-form-item label="峰电电费费率(元/度)" prop="PeakAmount">
        <el-input v-model="form.PeakAmount" disabled>
        </el-input>
      </el-form-item>
      <el-form-item label="平电电费费率(元/度)" prop="FlatAmount">
        <el-input v-model="form.FlatAmount" disabled>
        </el-input>
      </el-form-item>
      <el-form-item label="谷电电费费率(元/度)" prop="ValleyAmount">
        <el-input v-model="form.ValleyAmount" disabled>
        </el-input>
      </el-form-item>
      <el-form-item label="定价挡位" prop="TimePeriodRate">
        <p v-for="(item,index) in form.TimePeriodRate" :key="index">
            {{item.startTime+'时—'+item.endTime+'时&nbsp;&nbsp;&nbsp;'+Number(item.ele)/100+'元/度'}}
        </p>
      </el-form-item>
      <el-form-item label="服务费(元/度)" prop="ServiceAmount">
        <el-input v-model="form.ServiceAmount" disabled>
        </el-input>
      </el-form-item>
      <el-form-item label="折损率（%）" prop="ElectricalLossRate">
        <el-input v-model="form.ElectricalLossRate" disabled>
        </el-input>
      </el-form-item>
      <el-form-item label="备注" prop="Remarks">
        <el-input v-model="form.Remarks" disabled>
        </el-input>
      </el-form-item>
    </el-form>
  </section>
</template>

<script>
let _this;
import {selectConsumeTemplate} from '@/api/public.js';
let arr=[],arr2=[];
export default {
  props:{row:{type:Object}},
  data() {
    return {
      // 模式类别选项
      typepOtions:[
        {value: '1',label: '时间模式'},
        {value: '0',label: '功率模式'},
        {value: '2',label: '电量模式'}
      ],
      // 是否为功率模式或电量模式
      isPowerMode:false,
      // 模板类型
      TemplateType:'',
      // 功率限制时长选项
      durationOptions:[
        {value: '选项1',label: '1小时'},
        {value: '选项2',label: '2小时'},
        {value: '选项3',label: '3小时'},
        {value: '选项4',label: '4小时'},
        {value: '选项5',label: '5小时'},
        {value: '选项6',label: '6小时'},
        {value: '选项7',label: '7小时'},
        {value: '选项8',label: '8小时'},
      ],
      // 功率限制金额选项
      moneyOptions:[
        {label:10,value:10,},
        {label:20,value:20,},
        {label:30,value:30,},
        {label:40,value:40,},
        {label:50,value:50,},
        {label:60,value:60,},
        {label:70,value:70,},
        {label:80,value:80,},
        {label:90,value:90,},
        {label:100,value:100,},
        {label:150,value:150,},
      ],
      form:{ParentId:''}, // 表单数据
      imageUrl:'',
      value:[],
      options:[],
      show:true,
      // 功率模板数据
      PowerGradientTemplate:[],
      // 时间模式模板数据
      TimeGradientTemplate:[],
      // 标签数据
      dynamicTags: [],
      inputVisible: false,
      inputValue: '',
      inputValue1:'',
    };
  },

  components: {},

  computed: {},

  mounted() {
    _this=this;
    this.init();
  },

  methods: {
    // 关闭
    close(){
      _this.$emit('close');
    },
    async init(){
      await selectConsumeTemplate({Id:this.row.Id}).then(res=>{
        if(res.Success){
          this.form = res.Data[0];
          this.form.TimePeriodRate = JSON.parse(this.form.TimePeriodRate);
          this.form.AmountGears = JSON.parse(this.form.AmountGears);
        }else{
          this.$message({
            message:'获取失败，请重试',
            type:'fail',
          })
          this.close();
        }
      })
    },
    // 选择模式
    selectMode(e){
      if(e=='0' || e=='2'){
        this.isPowerMode=true;
      }else {
        this.isPowerMode=false;
      }
    },
    // 选择功率限制时长
    selectTimeLength(e){
      arr=[];
      e.forEach(element => {
        this.durationOptions.forEach(ele=>{
          if(element == ele.value || element == ele.label){
            arr.push(ele.label)
          }
        })
      });
    },
    // 选择功率限制金额
    selectAmount(e){
      arr2=[];
      e.forEach(element => {
        this.moneyOptions.forEach(ele=>{
          if(element == ele.value || element == ele.label){
            arr2.push(ele.label)
          }
        })
      });
    },
    // 添加时间梯度模板
    addTimeTemplate(){
      this.TimeGradientTemplate.push({hour:'',dollor:''})
    },
    // 添加功率梯度模板
    showInput() {
      this.PowerGradientTemplate.push({w:'',yuan:''})
    },
    // 删除模板
    delTemplate(index,mode){
      if(mode=='time'){
        this.TimeGradientTemplate.splice(index,1);
      }else{
        this.PowerGradientTemplate.splice(index,1);
      }
    },
     // 修改
     edit(form) {
       if(_this.form.IsLocked==='否'){
         _this.form.IsLocked=false;
       }else if(_this.form.IsLocked==='是'){
         _this.form.IsLocked=true;
       }
       this.form.IsLeave = this.form.IsLeave == '是' ? 1 : 0; // 是否充满自停
       this.form.IsPayAdvance = this.form.IsPayAdvance == '是' ? 1 : 0; // 是否预付
       this.form.RefundMode = this.form.RefundMode == '原路返回' ? 1 : 0; // 退款方式
       this.form.TimeLength=arr==[]?this.form.TimeLength.join():arr.join();//限制时长
       this.form.AmountLength=arr2==[]?this.form.AmountLength.join():arr2.join();//限制金额
       if(this.isPowerMode){
         this.form.PowerGradientTemplate=this.PowerGradientTemplate;
         this.form.TimeGradientTemplate=[];
       }else{
         this.form.PowerGradientTemplate=[];
         this.form.TimeGradientTemplate=this.TimeGradientTemplate;
       }
        _this.$refs["form"].validate(valid => {
          if (valid) {
            selectConsumeTemplate(_this.form)
              .then((res) => {
                if (res.Success) {
                  _this.$message({
                    message: "修改成功",
                    type: "success",
                  });
                  setTimeout(() => {
                    _this.$emit("success");
                    _this.$emit('close');
                  }, 1000);
                }else{
                  _this.$message({
                    message: "修改失败，请重试",
                    type: "fail",
                  });
                }
              })
              .catch(error => {});
          } else {
            return false;
          }
      });
    },
  }
};
</script>
<style lang="scss" scoped>
 .el-select{
   width: 100%;
   
 }

 .gradientTemplate{
   .el-input{
     border-radius: 0 !important;
   }
   .el-button{
     border:dashed #c0c4cc 1px;
     width:100%;
     outline:none;
   }
   p{
     display: flex;
     justify-content: flex-start;
     width: 49%;
     height: 40px;
     display: inline-block;
     box-sizing: border-box;
     span{
       display: inline-block;
       border:solid 1px #dbdee5 ;
       width:20%;
       height: 100%;
       box-sizing: border-box;
     }
   }
 } 

  // 标签

 .el-drawer{
   overflow:auto !important;
 }
 .avatar-uploader .el-upload {
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }
  .avatar-uploader-icon {
    border: 1px dashed #ccc;
    border-radius: 50%;
    overflow:hidden;
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }
  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }

  // 模板
  .eachTemplate{
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    align-items: center;
    p{
      width: 45%;
      display: flex;
      .el-input{
        flex: 1;
        outline: none;
      }
      span{
        display: inline-block;
        width: 30%;
        border: solid #dbdee5 1px;
        font-size: 13px;
      }
    }
    b{
      display: inline-block;
      height: 13px;
      width: 13px;
      line-height: 13px;
      border-radius: 50%;
      border: 2px rgb(240, 12, 12) solid;
      color: rgb(240, 12, 12);
      font-weight: 900;
      &:hover{
        cursor:pointer;
      }
    }
  }
</style>
