<template>
  <section>
    <div class="search">
      <div class="inputs">
        <!-- 输入框搜索 -->
        <el-input class="wxName" v-model="searchInfo.data.CustomerName" placeholder="所属用户名称"></el-input>
        <!-- 时间选择 -->
        <el-date-picker
         v-model="time"
         type="datetimerange"
         range-separator="至"
         start-placeholder="开始查询时间"
         end-placeholder="结束查询时间"
         @change='chooseTime'
         class='timeSelect'>
        </el-date-picker>
        <div class="Btns">
         <el-button type="primary" @click="toSearch">查询</el-button>
         <el-button  @click="backAll">返回所有</el-button>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="btns">
        <el-button type="primary" @click="educe">提现导出</el-button>
      </div>
      <el-table
        :data="orderInfo"
        style="width: 100%" 
        class="table"
        fit
      >
      <!-- 数据展示区 -->
        <el-table-column
          label="所属用户名称"
          prop="CustomerName"
          align='center'>
        </el-table-column>
        <el-table-column
          label="合计提现金额（元）"
          prop="sumWithdrawalAmount"
          align='center'>
        </el-table-column>
        <el-table-column
          label="合计提现手续费（元）"
          prop="sumPoundage"
          align='center'>
        </el-table-column>
        <el-table-column
          label="提现状态"
          prop="ApplyState"
          align='center'>
        </el-table-column>
        <el-table-column
          label="用户账号提现金额（元）"
          prop="WithdrawalAmount"
          align='center'>
        </el-table-column>
        <el-table-column
          label="提现类型"
          prop="Channel"
          align='center'>
        </el-table-column>
        <el-table-column
          label="提现账号"
          prop="Bank"
          align='center'>
        </el-table-column>
      </el-table>
    </div>
  </section>
</template>

<script>
import {getWithdrawalApplyReport,withdrawalApplyReportExport} from '@/api/public.js';
import {baseUrl} from '@/api/url.js';
export default {
  data() {
    return {
      // 订单列表信息
      orderInfo:[],
      // 查询条件
      searchInfo:{
        data:{
          BeginTime:'',
          EndTime:'',
          CustomerName:'',
        },
      },
      time:'',
    }
  },

  components: {},

  computed: {},

  mounted() {
    this.init();
  },

  methods: {
    // 初始化
    async init(){
      await getWithdrawalApplyReport(this.searchInfo.data).then(res=>{
        if(res.Success){
          this.orderInfo=res.Data;
          this.orderInfo.forEach(element => {
           element.ApplyState=element.ApplyState==1?'待打款':(element.ApplyState==2?'已打款':'撤销');
           element.Channel=element.Channel==0?'银行卡':'支付宝';
           let start=element.Bank.indexOf('(');
           element.Bank=element.Bank.substr(0,start);
          });
        }
      })
    },
    // 时间戳转时间
    timeToTime(time){
       const date = new Date(Number(time))
       const Y = date.getFullYear() // 年
       const M = date.getMonth() + 1 < 10 ? '0'+(date.getMonth()+1) : date.getMonth()+1 // 月
       const D = date.getDate() < 10 ? '0' + date.getDate() : date.getDate() // 日
       const H = date.getHours() < 10 ? '0' + date.getHours() : date.getHours() // 时
       const m = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes() // 分
       const S = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds() // 秒 
       const Time=`${Y}-${M}-${D} ${H}:${m}:${S}`;
       return Time;
    },
    // 中国标准时间转年月日
    timeChange(time,ifend){
      var chinaStandard=time;
        var date = new Date(chinaStandard);
        var y = date.getFullYear();
        var m = date.getMonth() + 1;
        m = m < 10 ? ('0' + m) : m;
        var d = date.getDate();
        d = d < 10 ? ('0' + d) : d;
        var h = date.getHours();
        var minute = date.getMinutes();
        minute = minute < 10 ? ('0' + minute) : minute;
        var second = date.getSeconds();
        second = second < 10 ? ('0' + second) : second;
        let Time;
        if(ifend){
          Time = y + '/' + m + '/' + d+' 23:59:59';
        }else{
          Time = y + '/' + m + '/' + d+' '+h+':'+minute+ ':' + second;
        }
        return Time;
    },
    // 导出
    async educe(){
      await withdrawalApplyReportExport(this.searchInfo.data).then(res=>{
        if(res.Success){
          window.open(baseUrl+'/'+res.Data);
        }else{
          this.$message({
            message: "查询不到该类数据",
            type: "fail",
          });
        }
      })
    },
    
    // 时间选择
    chooseTime(e){
      this.searchInfo.data.BeginTime= this.timeChange(e[0]);
      this.searchInfo.data.EndTime= this.timeChange(e[1]);
    },
    // 条件查询
    toSearch(){
      this.init();
    },
    // 清除查询条件
    backAll(){
      this.searchInfo.data={
        BeginTime:'',
        EndTime:'',
        CustomerName:'',
      };
      this.time='';
      this.init();
    },
  },
};
</script>
<style scoped lang='scss'>

 .search{
  margin-bottom: 30px;
    .inputs{
      display: flex;
    }
    .wxName,.select,.timeSelect{
      width: 20%;
      margin-right: 20px;
    }
    .timeSelect{
      width: 30%;
    }
    .Btns{
      display: flex;
      justify-content: flex-end;
      margin-bottom: 10px;
    }
  }
  .search .wxName[data-v-0dade7ca], .search .select[data-v-0dade7ca], .search .timeSelect[data-v-0dade7ca]{
    margin-right: 5px;
  }
  
// 按钮
  .btns{
    width: 100%;
    background-color: white;
    height: 60px;
    width: 100%;
    padding: 10px 50px;
    position: relative;
    button{
      position: absolute;
      right:20px;
      top:10px;
    }
  }
  

.el-table >>> .cell {
  white-space: pre-line;
}
.el-drawer.rtl{
  overflow-y: auto !important;
}

.equipName{
  margin-bottom: 10px;
}
.equipNumber{
  color: #40a9ff;
}
.number{
  display: inline-block;
  height: 20px;
  width: 20px;
  padding: 0 2px;
  border: solid 1px #91d5ff;
  color:#40a9ff;
  background: #e6f7ff;
  border-radius: 3px;
  margin-left: 10px;
  text-align: center;
  font-size: 14px;
}
.el-tabs__content{
  overflow:auto !important;
}
.orderStatus{
  background:#40a9ff;
  color:#fff;
  display: inline-block;
  padding: 3px 5px;
  border-radius: 5px;
}

.stopWay{
  color: #40a9ff;
  margin-top: 10px;
}
</style>
