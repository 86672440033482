<template>
    <section>
      <!-- 搜索 -->
      <div class="search">
        <div class="inputs">
          <el-date-picker
             v-model="time"
             type="datetimerange"
             range-separator="至"
             start-placeholder="开始时间"
             end-placeholder="结束时间"
             @change='chooseTime'
             class='timeSelect'>
          </el-date-picker>
          <el-select class='select' v-model="searchInfo.PayType" clearable placeholder="支付类型">
            <el-option
              v-for="item in payTypeOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
          <el-select class='select' v-model="searchInfo.NeighbourhoodId" clearable placeholder="所属场所">
            <el-option
              v-for="item in neighborOptions"
              :key="item.Id"
              :label="item.NeighbourhoodName"
              :value="item.Id">
            </el-option>
          </el-select>
          <div class="Btns">
            <el-button @click="search(0)" :style="day">按日统计</el-button>
            <el-button @click="search(1)" :style="month">按月统计</el-button>
            <el-button type="primary" @click="toSearch">查询</el-button>
            <el-button type="primary" @click="backAll">返回所有</el-button>
            <el-button type="primary" @click="educe">导出</el-button>
          </div>
        </div>
      </div>
      <div class="container">
        <el-table
          :data="benefitInfo"
          style="width: 100%" 
          class="table"
          fit
        >
          <!-- 数据展示区 -->
          <el-table-column
            label="统计时间"
            prop="BeginTime"
            align='center'>
          </el-table-column>
          <el-table-column
            label="统计订单量"
            prop="OrderNum1"
            align='center'>
          </el-table-column>
          <el-table-column
            label="统计订单收益"
            prop="ActualAmount"
            align='center'>
          </el-table-column>
          <el-table-column
            label="统计订单耗电费(元)"
            prop="ElectricityAmount"
            align='center'>
          </el-table-column>
          <el-table-column
            label="统计订单服务费(元)"
            prop="ServiceAmount"
            align='center'>
          </el-table-column>
          <el-table-column
            label="统计订单耗电量"
            prop="OrderElectricNum"
            align='center'>
          </el-table-column>
          <el-table-column
            label="统计应支出分润"
            prop="BenefitAmount"
            align='center'>
          </el-table-column>
        </el-table>
        <!--分页-->
        <el-col :span="24" class="toolbar" >
          <el-pagination
            layout="total, prev, pager, next, jumper"
            @current-change="handleCurrentChange"
            @prev-click="prevpage"
            @next-click="nextpage"
            :page-size="10"
            :total="total"
            style="float:right;"
          ></el-pagination>
        </el-col>
      </div>
    </section>
    </template>
    
    <script>
    import{ getDataBenefitbyday,exportBenefitbyday,getNeibourhood} from '@/api/public.js';
    import {baseUrl} from '@/api/url.js';
    export default {
      data() {
        return {
          // 分润明细数据
          benefitInfo:[],
          // 分页信息
          pageIndex:1,
          pageSize:10,
          total:0,
          time:'',
          searchInfo:{
            SBeginTime:'',
            SEndTime:'',
            Benbyday:0,
          },
          day:'background:#ccc',
          month:'',
          payTypeOptions:[
            {value:0,label:'微信支付'},
            {value:3,label:'刷卡支付'},
          ],
          neighborOptions:[],
        }
      },
    
      components: {},
    
      computed: {},
    
      mounted() {
        this.init();
      },
    
      methods: {
        // 数据初始化
        async init(){
          await getDataBenefitbyday({pageIndex:this.pageIndex,pageSize:this.pageSize,data:this.searchInfo}).then((res)=>{
            if(res.Success){
              this.benefitInfo=res.Data.data?res.Data.data:[];
              this.total=res.Data.totalCount;
              this.benefitInfo.forEach(element => {
                element.BeginTime=this.timeToTime(element.BeginTime.substring(6,19));
              });
            }
          })
          await getNeibourhood({pageIndex:1,pageSize:100000,data:{}}).then(res=>{
            if(res.Success){
              this.neighborOptions=res.Data.data;
            }
          })
        },
        // 时间戳转时间
        timeToTime(time){
           const date = new Date(Number(time))
           const Y = date.getFullYear() // 年
           const M = date.getMonth() + 1 < 10 ? '0'+(date.getMonth()+1) : date.getMonth()+1 // 月
           const D = date.getDate() < 10 ? '0' + date.getDate() : date.getDate() // 日
           const H = date.getHours() < 10 ? '0' + date.getHours() : date.getHours() // 时
           const m = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes() // 分
           const S = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds() // 秒 
           const Time=`${Y}-${M}-${D} ${H}:${m}:${S}`;
           return Time;
        },
        // 中国标准时间转年月日
        timeChange(time,ifend){
          var chinaStandard=time;
            var date = new Date(chinaStandard);
            var y = date.getFullYear();
            var m = date.getMonth() + 1;
            m = m < 10 ? ('0' + m) : m;
            var d = date.getDate();
            d = d < 10 ? ('0' + d) : d;
            var h = date.getHours();
            var minute = date.getMinutes();
            minute = minute < 10 ? ('0' + minute) : minute;
            var second = date.getSeconds();
            second = second < 10 ? ('0' + second) : second;
            let Time;
            if(ifend){
              Time = y + '/' + m + '/' + d+' 23:59:59';
            }else{
              Time = y + '/' + m + '/' + d+' '+h+':'+minute+ ':' + second;
            }
            return Time;
        },
        // 时间选择
        chooseTime(e){
          this.searchInfo.SBeginTime= this.timeChange(e[0]);
          this.searchInfo.SEndTime= this.timeChange(e[1]);
        },
        // 条件查询
        toSearch(){
          this.pageIndex=1;
          this.init();
        },
        search(type){
          if(type===0){
            this.day='background:#ccc';
            this.month='';
            this.year='';
          }else if(type==1){
            this.day='';
            this.month='background:#ccc';
            this.year='';
          }
          this.pageIndex=1;
          this.searchInfo.Benbyday=type
          this.init();
        },
        async educe(){
          await exportBenefitbyday(this.searchInfo).then(res=>{
            if(res.Success){
              window.open(baseUrl+'/'+res.Data);
            }else{
              this.$message({
                message: "查询不到该类数据",
                type: "fail",
              });
            }
          })
        },
        // 清除查询条件
        backAll(){
          this.pageIndex=1
          this.searchInfo={};
          this.time='';
          this.day='background:#ccc';
          this.month='';
          this.init();
        },
        // 分页
        prevpage(val){
          this.pageIndex=val;
          this.init();
        },
        nextpage(val){
          this.pageIndex=val;
          this.init();
        },
        handleCurrentChange(val){
          this.pageIndex =val;
          this.init();
        },
      },
    };
    </script>
    <style scoped lang='scss'>
      @import "@/static/default";
    /* 搜索 */
    .search{
      margin-bottom: 30px;
        .inputs{
          display: flex;
        }
        .wxName,.select{
          width: 150px;
          margin-right: 20px;
        }
        .timeSelect{
          margin-right: 20px;
        }
        .Btns{
          display: flex;
          justify-content: flex-end;
          margin-bottom: 10px;
        }
      }
    // 按钮
      .btns{
        width: 100%;
        background-color: white;
        height: 60px;
        width: 100%;
        padding: 10px 50px;
        position: relative;
        button{
          position: absolute;
          right:20px;
          top:10px;
        }
      }
    /* 内容 */
      .container{
        background-color: #fff !important;
        padding:0 32px;
        .table{
        margin-top:24px !important;
        }
        .toolbar{
          background: #fff;
          width:calc(100% + 64px);
          padding:16px 32px 26px 0;
          margin-left:-32px;
        }
      }
    </style>
    