<template>
  <section>
    <el-form ref="form" label-position="right" status-icon :model="form" :rules="rules" label-width="100px" style="width:95%;overflow:auto">
      <el-form-item label="角色" prop="Role" >
        <el-select v-model="form.Role" placeholder="请选择" @change="selectRole" style="width:100%">
          <el-option
            v-for="item in options"
            :key="item.code"
            :label="item.Name"
            :value="item.code">   
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="客户名称" prop="CustomerName" >
        <el-input placeholder="请输入客户名称" v-model="form.CustomerName">
        </el-input>
      </el-form-item>
      <el-form-item label="卡可用类型" prop="CarType" v-if="form.Role == 5">
        <el-select v-model="form.CarType" placeholder="请选择"  style="width:100%">
          <el-option
            v-for="item in CarTypeOptions"
            :key="item.code"
            :label="item.Name"
            :value="item.code">   
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="优惠类型" prop="PreferentialType" v-if="form.Role == 5">
        <el-select v-model="form.PreferentialType" placeholder="请选择"  style="width:100%">
          <el-option
            v-for="item in PreferentialTypeOptions"
            :key="item.code"
            :label="item.Name"
            :value="item.code">   
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="小区" prop="Nid" v-if="form.Role == 5">
        <el-select v-model="form.Nid" placeholder="请选择"  style="width:100%">
          <el-option
            v-for="item in NidOptions"
            :key="item.Id"
            :label="item.NeighbourhoodName"
            :value="item.Id">   
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button @click="close('form')">取消</el-button>
        <el-button type="primary" @click="edit('form')" :plain='true'>立即提交</el-button>
      </el-form-item>
    </el-form>
  </section>
</template>

<script>
let _this;
import {updateWechatUser,getWechatDictionariesList,getNidList} from '@/api/public.js';

export default {
  props:{row:{type:Object}},
  data() {
    return {
      form:{},
      rules: {
        Role: [
          { required: true, message: "请选择角色", trigger: "blur" },
        ],
        CustomerName: [
          { required: true, message: "客户名称不得为空", trigger: "blur" },
        ],
        CarType: [
          { required: true, message: "请选择卡可用类型", trigger: "change" },
        ],
        PreferentialType: [
          { required: true, message: "请选择优惠类型", trigger: "change" },
        ],
        Nid: [
          { required: true, message: "请选择可用小区", trigger: "change" },
        ],
      },
      userRole:sessionStorage.getItem('roleId'),
      options:[],
      CarTypeOptions:[],
      PreferentialTypeOptions:[],
      NidOptions:[],
      value:'',
    };
  },

  components: {},

  computed: {},

  mounted() {
    _this=this;
    this.getOptions('WXUserRole');
    this.getOptions('CarType');
    this.getOptions('WXUserMod');
    this.getNidOptions();
    this.form=this.row;
    this.form.Role = ''+ this.form.Role;
    this.form.CarType = ''+ this.form.CarType;
    this.form.PreferentialType = ''+ this.form.PreferentialType;
  },

  methods: {
    close(){
       _this.$emit('close');
    },
    async getOptions(sign){
      await getWechatDictionariesList({sign:sign}).then(res=>{
        if(res.Success){
          if(sign == 'WXUserRole'){
            this.options = res.Data;
          }else if(sign == 'CarType'){
            this.CarTypeOptions = res.Data;
          }else if(sign == 'WXUserMod'){
            this.PreferentialTypeOptions = res.Data;
          }else if(sign == 'Nid'){
            this.NidOptions = res.Data;
          }
        }
      })
    },
    async getNidOptions(){
        this.NidOptions = [];
        await getNidList().then(res=>{
          if(res.Success){
            this.NidOptions = res.Data;
            if(this.userRole == 3){
              this.NidOptions.unshift({Id:0,NeighbourhoodName:'通用'})
            }
          }else{
            this.NidOptions = [];
          }
        })
      },
    // 选择电表类型
    selectRole(e){
        this.form.Role=e;
    },
     //  修改
    edit(form) {
       _this.$refs["form"].validate(valid => {
          if (valid) {
            updateWechatUser(_this.form)
              .then((res) => {
                if (res.Success) {
                  _this.$message({
                    message: "提交成功",
                    type: "success",
                  });
                  setTimeout(() => {
                    _this.$emit("success");
                    _this.init();
                  }, 1000);
                }else{
                  _this.$message({
                    message: "提交失败，请重试",
                    type: "fail",
                  });
                }
              })
              .catch(error => {});
          } else {
            return false;
          }
      });
    },
    
  }
};
</script>
<style lang="scss" scoped>
 .el-drawer{
   overflow:auto !important;
 }
 .avatar-uploader .el-upload {
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }
  .avatar-uploader-icon {
    border: 1px dashed #ccc;
    border-radius: 50%;
    overflow:hidden;
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }
  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }
</style>
