<template>
    <div class="container">
      <div class="detail">
          <h3>开票信息</h3>
          <p><span>购买方名称</span><b>{{row.TotalRaise}}</b></p>
          <p><span>开票人联系号码</span><b>{{row.TotalPhone}}</b></p>
          <p><span>开票人邮箱</span><b>{{row.TotalEmail}}</b></p>
          <p><span>发票识别号</span><b>{{row.Totaltaxpayer}}</b></p>
          <p><span>发票类型</span><b>{{row.Totaltype}}</b></p>
          <p><span>银行账号</span><b>{{row.Totalaccount}}</b></p>
          <p><span>企业地址</span><b>{{row.address}}</b></p>
          <p><span>订单类型</span><b>{{row.FapiaoType==1?'两轮电动车':'四轮电动车'}}</b></p>
          <p><span>状态</span><b>{{row.Sate==1?'申请中':(row.Sate==2?'已开票':(row.Sate==4?'作废':'未知'))}}</b></p>
      </div>
      <div class="charts" style="overflow: auto;flex: 1;">
          <el-tabs v-model="activeName" style="display: flex;flex-direction: column;height: 100%;">
            <el-tab-pane label="订单信息" name="first" style="height: 100%;">
                <el-table
                  :data="orderList"
                  style="width: 100%;overflow: auto;" 
                  height="100%"
                  class="table"
                  fit
                >
                  <el-table-column
                    label="订单号"
                    prop="OrderNum"
                    align='center'
                    width="250px">
                  </el-table-column>
                  <el-table-column
                    label="实际金额（元）"
                    prop="ActualAmount"
                    align='center'>
                  </el-table-column>
                  <el-table-column
                    label="电费（元）"
                    prop="ElectricityAmount"
                    align='center'>
                  </el-table-column>
                  <el-table-column
                    label="服务费（元）"
                    prop="ServiceAmount"
                    align='center'>
                  </el-table-column>
                  <el-table-column
                    label="退款金额（元）"
                    prop="RefundAmount"
                    align='center'>
                  </el-table-column>
                </el-table>
            </el-tab-pane>
          </el-tabs>
      </div>
    </div>
  </template>
  
  <script>
  import {orderlistWeChatUserToTalBill} from '@/api/public.js';
  export default {
    props:{row:{type:Object}},
    data() {
      return {
          activeName:'first',
          orderList:[], // 订单列表
      }
    },
  
    components: {},
  
    computed: {},
  
    mounted() {
      this.init();
    },
  
    methods: {
        // 获取该申请下的订单
        async init(){
            await orderlistWeChatUserToTalBill({OrderNums:this.row.OrderNums,FapiaoType:this.row.FapiaoType}).then(res=>{
                if(res.Success){
                    this.orderList=res.Data;
                }
            })
        },
    },
  };
  </script>
  <style scoped lang='scss'>
    .container{
        overflow: auto;
        height: 100%;
        display: flex;
        flex-direction: column;
    }
    .el-tabs__header /deep/ .el-tabs__content{
      overflow: auto !important;
    }
    /deep/ .el-tabs__content{
      overflow:auto !important;
      flex: 1;
      margin-bottom: 30px !important;
    }
     /deep/  .el-drawer__wrapper{
      z-index: 4000 !important;
    }
    .detail{
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      width: 90%;
      margin: 0 auto;
      margin-bottom: 50px;
      font-size: 14px;
      box-sizing: border-box;
      h3{
          width: 100%;
          height: 70px;
          line-height: 70px;
          text-align: left;
      }
      p{
          line-height: 16px;
          box-sizing: border-box;
          display: flex;
          width: 50%;
          span{
              display: inline-block;
              background: #fafafa;
              border:solid #f0f0f0 1px;
              width: 30%;
              box-sizing: border-box;
              height: 40px;
              line-height: 40px;
              box-sizing: border-box;
          }
          b{
               display: inline-block;
              background: #fff;
               border:solid #f0f0f0 1px;
               box-sizing: border-box;
               height: 40px;
               line-height: 40px;
               flex: 1;
           }
      }
    }
    .charts{
      width: 90%;
      margin: 0 auto;
      height: 100%;
    }
  </style>
  