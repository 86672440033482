<template>
  <section>
    <!-- 搜索 -->
    <div class="search">
      <div class="inputs">
        <el-input class="wxName" v-model="searchInfo.EquipmentNum" placeholder="设备编号"></el-input>
        <el-input class="wxName" v-model="searchInfo.NeighbourhoodName" placeholder="场所名称"></el-input>
        <el-select class='select' v-model="state" clearable  placeholder="设备状态">
          <el-option
            v-for="item in statusOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
        <el-select class='select' v-model="searchInfo.Typecode" clearable placeholder="设备类型">
          <el-option
            v-for="item in equipTypeOptions"
            :key="item.code"
            :label="item.Name"
            :value="item.code">
          </el-option>
        </el-select>
        <el-select class='select' v-model="operate" clearable placeholder="运营状态">
          <el-option
            v-for="item in operateOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
        <div class="Btns">
         <el-button type="primary" @click="toSearch">查询</el-button>
         <el-button  @click="backAll">返回所有</el-button>
      </div>
      </div>
    </div>
    <div class="add">
      <el-button type="primary" @click="handleAdd">添加</el-button>
      <el-button type="primary" @click="generateQrcode">生成二维码</el-button>
      <el-button type="primary" @click="createQrStr">批量设置屏显二维码</el-button>
      <el-button  @click="handlePLbind">批量绑定</el-button>
    </div>
     <div class="container">
       <el-table
        :data="equipmentInfo"
        style="width: 100%"
        class="table"
        fit
        @selection-change="handleSelectionChange"
      >
        <af-table-column
          type="selection"
          align='center'
        >
        </af-table-column>
        <!-- 数据展示区 -->
        <af-table-column
          label="ID"
          prop="Id"
          align='center'>
        </af-table-column>
        <af-table-column
          label="设备编号"
          prop="EquipmentNum"
          align='center'>
          <template slot-scope="scope">
            <p style="display:flex;align-items:center;color:#40a9ff;">
              {{scope.row.EquipmentNum}}
              <img style="margin-left: 3px; height:15px;width:15px" src="@/static/img/qrCode.png" alt="" @click="openQrCode(scope.$index, scope.row)">
            </p>
          </template>
        </af-table-column>
        <af-table-column
          label="设备类型"
          prop="TypeName"
          align='center'>
        </af-table-column>
        <af-table-column
          label="设备状态"
          prop="EquipmentState"
          align='center'>
        </af-table-column>
        <af-table-column
          label="端口状态"
          prop="PortState"
          align='center'
          width='170px'>
          <template slot-scope="scope">
            <el-tooltip class="item" effect="dark" content="空闲" placement="top">
              <el-button>{{scope.row.free}}</el-button>
            </el-tooltip>
            <el-tooltip class="item" effect="dark" content="充电中" placement="top">
              <el-button>{{scope.row.take}}</el-button>
            </el-tooltip>
            <el-tooltip class="item" effect="dark" content="待确认订单" placement="top">
              <el-button>{{scope.row.waiting}}</el-button>
            </el-tooltip>
            <el-tooltip class="item" effect="dark" content="已充满" placement="top">
              <el-button>{{scope.row.full}}</el-button>
            </el-tooltip>
            <el-tooltip class="item" effect="dark" content="定时预约" placement="top">
              <el-button>{{scope.row.booking}}</el-button>
            </el-tooltip>
          </template>
        </af-table-column>
        <af-table-column
          label="运营状态"
          prop="IsOperate"
          align='center'>
        </af-table-column>
        <af-table-column
          label="所在场所"
          prop="NeighbourhoodName"
          align='center'>
        </af-table-column>
        <af-table-column
          label="场所地址"
          prop="NeighbourhoodAddress"
          align='center'>
        </af-table-column>
        <af-table-column
          label="所属代理"
          prop="AgentName"
          align='center'>
        </af-table-column>
        <af-table-column
          label="备注"
          prop="Remarks"
          align='center'>
        </af-table-column>
        <el-table-column label="操作" fixed="right" align='center' min-width="180">
          <template slot-scope="scope">
            <el-button size="mini" @click="handleCheck(scope.$index, scope.row)">查看订单</el-button>
            <el-popover
              placement="top"
              trigger="click"
              width="auto">
              <div style="text-align: right; margin: 0;display: flex;justify-content: space-around;flex-wrap: wrap;">
                <span class="spanBtn" @click="handleNet(scope.$index, scope.row)">转网</span>
                <span class="spanBtn" @click="handleConnect(scope.$index, scope.row)">通信</span>
                <span class="spanBtn" @click="handleEdit(scope.$index, scope.row)">{{scope.row.isopen}}</span>
                <span class="spanBtn" @click="handleBind(scope.$index, scope.row)">绑定</span>
              </div>
              <el-button style="margin-left: 10px;" slot="reference" size="mini">更多</el-button>
            </el-popover>
          </template>
      </el-table-column>
      </el-table>
      <!--分页-->
      <el-col :span="24" class="toolbar" >
        <el-pagination
          layout="total, prev, pager, next, jumper"
          @current-change="handleCurrentChange"
          @prev-click="prevpage"
          @next-click="nextpage"
          :page-size="10"
          :total="total"
          style="float:right;"
        ></el-pagination>
      </el-col>
      <!-- 新增设备 -->
      <el-drawer
        title="新增设备"
        :visible.sync="addDrawer"
        :direction="direction"
        :before-close="handleClose">
        <add v-if="addDrawer" @close='handleClose' @success="handleDrawerSuccess(form)  "  ref='newForm' :id="id" :row="row"></add>
      </el-drawer>
      <!-- 绑定场所 -->
      <el-drawer
        title="绑定场所"
        :visible.sync="bindDrawer"
        :direction="direction"
        :before-close="handleClose">
        <edit v-if="bindDrawer" @close='handleClose' @success="handleDrawerSuccess(form)  "  ref='newForm' :id="id" :row="row"></edit>
      </el-drawer>
    </div>
    <!-- 设备转网 -->
    <el-drawer
        title="设备转网"
        :visible.sync="netWorkDrawer"
        :direction="direction"
        :before-close="handleClose">
        <netWork v-if="netWorkDrawer" @close='handleClose' @success="handleDrawerSuccess(form)  "  ref='newForm' :id="id" :row="row"></netWork>
      </el-drawer>
    <!-- 查看二维码弹框 -->
    <div class="cover" v-if="showQRcode" @click="hideCover">
      <div class="inside" @click="stopPop">
        <p>{{this.num}}</p>
        <img :src="qrCode" alt="二维码暂未生成，请点击页面‘生成二维码按钮’完成生成">
      </div>
    </div>
  </section>
</template>

<script>
import {getEquipment,updateOperate,createQRcode,updateqrStr,GetEquipmentOnlink,GetEquipmentSite} from '@/api/public.js';
import edit from './edit.vue';
import add from './add.vue';
import netWork from './netWork.vue';
import {baseUrl} from '@/api/url.js';
export default {
  data() {
    return {
      addDrawer:false, // 是否显示新增设备弹窗
      bindDrawer:false, // 是否显示绑定场所弹窗
      netWorkDrawer:false,// 是否显示转网弹窗
      id:'',
      row:'',
      direction:'rtl',
      plId:'',
      // 分页信息
      pageIndex:1,
      pageSize:10,
      total:0,
      // 设备信息
      equipmentInfo:[],
      searchInfo:{ // 查询条件
        EquipmentState:-1,
        IsOperate:-1,
        Typecode:'',
      },
      operate:'',
      state:'',
      statusOptions:[ // 设备状态选项
        {value:0,label:'待激活'},
        {value:1,label:'启用'},
        {value:2,label:'下线'},
      ],
      operateOptions:[ // 运营状态选项
        {value:0,label:'停用'},
        {value:1,label:'启用'},
      ],
      equipTypeOptions:[],
      qrCode:'', // 当前查看的设备二维码url
      num:'',
      showQRcode:false, // 是否显示二维码弹窗
    }
  },

  components: {add,edit,netWork},

  computed: {},

  mounted() {
    this.init();
    this.getEquipType();
  },

  methods: {
    // 获取设备类型列表
    getEquipType(){
      GetEquipmentSite({sign:'Etype'}).then(res=>{
        if(res.Success){
          this.equipTypeOptions = res.Data;
        }else{
          this.equipTypeOptions = [];
        }
      })
    },
    // 初始化
    async init(){
      await getEquipment({pageIndex:this.pageIndex,pageSize:this.pageSize,data:this.searchInfo}).then((res)=>{
        if(res.Success){
          this.equipmentInfo=res.Data.data;
          this.total=res.Data.totalCount;
          this.equipmentInfo.forEach(element => {
            element.EquipmentState = element.EquipmentState == 0 ? '待激活' :(element.EquipmentState == 1 ? '启用' : '下线');
            element.isopen = element.IsOperate == 1 ? '停用' : '启用';
            element.IsOperate = element.IsOperate == 0 ? '停用' : '启用';
            element.EquipmentType = element.EquipmentType == 2 ? '单枪慢充桩' : '';// 设备类型暂时定为默认值为2
            // 端口状态
            element.free = 0; // 空闲
            element.take = 0; // 充电中
            element.waiting = 0 // 待确认
            element.full = 0 // 已充满
            element.booking = 0; // 定时预约
            let portState = element.PortState?element.PortState.split(',') : [];
            portState.forEach(ele=>{
              if(ele == 0){
                element.free++;
              }else if(ele == 1){
                element.take++;
              }else if(ele == 2){
                element.waiting++;
              }else if(ele == 3){
                element.full++;
              }else{
                element.booking++;
              }
            })
            for (const key in element) {
              element[key] = !element[key] && element[key] !== 0 ? '--' : element[key];
            }
          });
        }
      })
      
    },
    // 生成二维码
    async generateQrcode(){
      await createQRcode().then(res=>{
        if(res.Success){
          this.$message({
            message:`共需生成${res.Data.countnum}个，成功生成${res.Data.truenum}个`,
            type:'type',
          })
          this.init();
        }
      })
    },
    // 显屏二维码
    async createQrStr(){
      await updateqrStr().then(res=>{
        if(res.Success){
          this.$message({
            message:`共需生成${res.Data.countnum}个，成功生成${res.Data.truenum}个`,
            type:'type',
          })
          this.init();
        }
      })
    },
    // 条件查询
    toSearch(){
      this.pageIndex=1;
      this.searchInfo.EquipmentState = this.state !==''  ? this.state : -1; 
      this.searchInfo.IsOperate = this.operate !==''  ? this.operate : -1;
      this.init();
    },
    // 清除查询条件
    backAll(){
      this.searchInfo={
        EquipmentState:-1,
        IsOperate:-1,
        Typecode:'',
      };
      this.state = '';
      this.operate = '';
      this.pageIndex=1;
      this.init();
    },
    // 修改设备运营状态
    async handleEdit(index,row){
      let isOperate=row.IsOperate == '启用' ? 0 : 1;
      await updateOperate({Id:row.Id,IsOperate:isOperate})
        .then((res) => {
          if (res.Success) {
            this.$message({
              message: "修改成功",
              type: "success",
            });
            this.init();
          }else{
            this.$message({
              message: "修改失败，请重试",
              type: "fail",
            });
          }
        })
        .catch(error => {});
    },
    // 打开二维码弹框
    openQrCode(index,row){
      this.qrCode=baseUrl+row.QRcodeUrl;
      this.num=row.EquipmentNum;
      this.showQRcode=true;
    },
    // 关闭二维码弹框
    hideCover(){
      this.showQRcode=false;
    },
    // 阻止事件冒泡
    stopPop(e){
      e.stopPropagation()();
    },
    // 批量选择
    handleSelectionChange(val) {
      let Ids=[];
      val.forEach(ele=>{
        Ids.push(ele.Id);
        this.plId=Ids.join(',');
      })
    },
    // 打开新增
    handleAdd(){
      this.addDrawer=true;
    },
    // 打开转网
    handleNet(index,row){
      this.row=row;
      this.netWorkDrawer=true;
    },
    // 查看订单
    handleCheck(index,row){
      this.$router.push({ 
        path: `/orderManagement/orderList`,// 路由路径
        query: { // 参数
          EquipmentNum: row.EquipmentNum,
        }
      });
    },
    // 关闭
    handleClose(){
      this.addDrawer=false;
      this.bindDrawer=false;
      this.orderDrawer=false;
      this.netWorkDrawer=false;
      this.init();
    },
    handleDrawerSuccess(){
      this.addDrawer=false;
      this.bindDrawer=false;
      this.orderDrawer=false;
      this.netWorkDrawer=false;
    },
    // 打开绑定
    handleBind(index,row){
      this.row=row;
      this.bindDrawer=true;
    },
    // 批量绑定
    handlePLbind(){
      this.row={Ids:this.plId,isPL:true};
      this.bindDrawer=true;
    },
    // 设备测试通信
    handleConnect(index,row){
      const loading = this.$loading({
	        lock: true,
	        text: '通信中',
	        spinner: 'el-icon-loading',
          background:"rgba(0,0,0,0.5)"
	    })
      GetEquipmentOnlink({EquipmentNum:row.EquipmentNum}).then(res=>{
        if(res.Success){
          this.$message({
            message:`通讯成功`,
            type:'success',
          })
          loading.close();
        }else{
          this.$message({
            message:`通讯失败`,
            type:'fail'
          })
          loading.close();
        }
      })
    },
    // 分页
    prevpage(val){
      this.pageIndex=val;
      this.init();
    },
    nextpage(val){
      this.pageIndex=val;
      this.init();
    },
    handleCurrentChange(val){
      this.pageIndex=val;
      this.init();
    },
  },
};
</script>
<style scoped lang='scss'>
.el-popover{
  width: 240px !important;
}
.spanBtn{
  width: 40%;
  cursor: pointer;
  display: inline-block;
  color: #40a9ff;
  text-align: center;
  padding-bottom: 5px;
}
// 遮罩层
.cover{
  height:100%;
  width: 100%;
  background: rgba($color: #000000, $alpha: 0.3);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2000;
  display: flex;
  justify-content: center;
  align-items: center;
  .inside{
    height: 400px;
    width: 300px;
    background: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    p{
      font-size: 20px;
      font-weight: 500;
      margin-bottom: 20px;
    }
  }
}
 /* 搜索 */
.search{
  margin-bottom: 30px;
  .inputs{
    text-align: left;
    display: flex;

  }
  .wxName,.select,.timeSelect{
    width: 15%;
    margin-right: 20px;
  }
  .timeSelect{
    width: 20%;
  }
  .Btns{
    display: flex;
    justify-content: flex-end;
    margin-bottom: 10px;
  }
}
/* 添加 */
.add{
    width: 100%;
    background-color: white;
    height: 60px;
    width: 100%;
    padding: 10px 50px;
    position: relative;
  }
  .add button{
    // position: absolute;
    // right:20px;
    // top:10px;
    float: right;
    margin:0 10px 0 0 ;
    // width: 100px;
    text-align: center;
    &:last-child{
      position: absolute;
      left:20px;
      top:10px;
    }
  }

  .el-tooltip{
    display: inline-block;
    padding: 0 6px;
    margin-right: 5px;
    border-radius: 3px;
    margin-left: 0 !important;
    &:nth-child(1){
      color: #88d75f;
      border: #88d75f solid 1px;
      background: #f6ffed;
    }
    &:nth-child(2){
      color: #3aa1ff;
      border: #3aa1ff solid 1px;
      background: #e6f7ff;
    }
    &:nth-child(3){
      color: #7ad3ee;
      border: #7ad3ee solid 1px;
      background: #e6f7ff;
    }
    &:nth-child(4){
      color: #ff696b;
      border: #ff696b solid 1px;
      background: #fff2f0;
    }
    
    &:nth-child(5){
      color: #88d75f;
      border: #88d75f solid 1px;
      background: #e6f7ff;
    }
  }

  .container{
    img{
      &:hover{
        cursor: pointer;
      }
    }
  }
</style>
