<template>
  <section>
    <!-- 搜索 -->
    <div class="search">
      <div class="Btns">
         <el-button type="primary" @click="toSearch">查询</el-button>
         <el-button  @click="backAll">返回所有</el-button>
      </div>
      <div class="inputs">
        <el-input class="wxName" v-model="searchInfo.NeighbourhoodName" placeholder="场所名称"></el-input>
        <el-input class="wxName" v-model="searchInfo.Provinces" placeholder="省份"></el-input>
        <el-input class="wxName" v-model="searchInfo.City" placeholder="所属市"></el-input>
        <el-input class="wxName" v-model="searchInfo.Area" placeholder="所属区"></el-input>
        <el-input class="wxName" v-model="searchInfo.Street" placeholder="街道"></el-input>
        <el-input class="wxName" v-model="searchInfo.Address" placeholder="详细地址"></el-input>
      </div>
    </div>
    <div class="add">
        <el-button type="primary" @click="handleAdd">添加</el-button>
    </div>
    <div class="container">
      <el-table
       :data="neighbourInfo"
       style="width: 100%" 
       class="table"
       fit
      >
        <!-- 数据展示区 -->
        <af-table-column
          label="ID"
          prop="Id"
          align='center'>
        </af-table-column>
        <af-table-column
          label="场所名称"
          prop="NeighbourhoodName"
          align='center'>
        </af-table-column>
        <af-table-column
          label="安装日期"
          prop="InstallDate"
          align='center'>
        </af-table-column>
        <af-table-column
          label="所属物业"
          prop="WeChatName"
          align='center'>
        </af-table-column>
        <af-table-column
          label="所属代理"
          prop="AgentName"
          align='center'>
        </af-table-column>
        <af-table-column
          label="详细地址"
          prop="Address"
          align='center'>
          <template slot-scope="scope">
            <span>{{scope.row.Provinces+scope.row.City+scope.row.Area+scope.row.Street+scope.row.Address}}</span>
          </template>
        </af-table-column>
        <af-table-column
          label="创建时间"
          prop="CreateTime"
          align='center'>
        </af-table-column>
        <el-table-column label="操作" fixed="right" align='center' v-if="loginRoleId == 3" min-width="280">
          <template slot-scope="scope">
            <el-button size="mini" @click="handleEdit(scope.$index, scope.row)">编辑</el-button>
            <el-button size="mini" @click="handleDetail(scope.$index, scope.row)">详情</el-button>
            <el-button size="mini" @click="handleDel(scope.$index, scope.row)">删除</el-button>
            <el-popover
              placement="top"
              trigger="click"
              width="auto">
              <div style="text-align: right; margin: 0;display: flex;justify-content: space-around;flex-wrap: wrap;">
                <span class="spanBtn" @click="handleTemplate(scope.$index, scope.row)">绑定模板</span>
                <span class="spanBtn" @click="handleAgent(scope.$index, scope.row)">绑定代理</span>
                <span class="spanBtn" @click="handleBind(scope.$index, scope.row)">绑定物业</span>
                <span class="spanBtn" @click="handleBenefit(scope.$index, scope.row)">设置分润</span>
                <span class="spanBtn" @click="handleRate(scope.$index, scope.row)">设置费率</span>
                <span class="spanBtn" @click="handleBatteryBenefit(scope.$index, scope.row)">两轮电动车分润</span>
              </div>
              <el-button style="margin-left: 10px;" slot="reference" size="mini">更多</el-button>
            </el-popover>
            <!-- <el-button size="mini" @click="handleTemplate(scope.$index, scope.row)">绑定模板</el-button>
            <el-button size="mini" @click="handleAgent(scope.$index, scope.row)">绑定代理</el-button>
            <el-button size="mini" @click="handleBind(scope.$index, scope.row)">绑定物业</el-button>
            <el-button size="mini" @click="handleBenefit(scope.$index, scope.row)">设置分润</el-button>
            <el-button size="mini" @click="handleRate(scope.$index, scope.row)">设置费率</el-button>
            <el-button size="mini" @click="handleBatteryBenefit(scope.$index, scope.row)">两轮电动车分润</el-button> -->
          </template>
        </el-table-column>
      </el-table>
      <!--分页-->
      <el-col :span="24" class="toolbar" >
        <el-pagination
          layout="total, prev, pager, next, jumper"
          @current-change="handleCurrentChange"
          @prev-click="prevpage"
          @next-click="nextpage"
          :page-size="10"
          :total="total"
          style="float:right;"
        ></el-pagination>
      </el-col>
      <!-- 新增设备 -->
      <el-drawer
        title="新增场所"
        :visible.sync="addDrawer"
        :direction="direction"
        :before-close="handleClose">
        <add v-if="addDrawer" @close='handleClose' @success="handleDrawerSuccess(form)  "  ref='newForm' :id="id" :row="row"></add>
      </el-drawer>
      <!-- 修改场所 -->
      <el-drawer
        title="修改场所"
        :visible.sync="editDrawer"
        :direction="direction"
        :before-close="handleClose">
        <edit v-if="editDrawer" @close='handleClose' @success="handleDrawerSuccess(form)  "  ref='newForm' :id="id" :row="row"></edit>
      </el-drawer>
      <!-- 场所详情 -->
      <el-drawer
        title="场所聚合数据"
        :visible.sync="detailDrawer"
        :direction="direction"
        :before-close="handleClose"
        size="80%">
        <detail v-if="detailDrawer" @close='handleClose' @success="handleDrawerSuccess(form)  "  ref='newForm' :id="id" :row="row"></detail>
      </el-drawer>
      <!--绑定物业 -->
      <el-drawer title="绑定" :visible.sync="bindDrawer" :direction="direction" :before-close="handleClose">
        <bind v-if="bindDrawer" @close='handleClose' @success="handleDrawerSuccess(form)  "  ref='newForm' :id="id" :row="row"></bind>
      </el-drawer>
      <!--设置分润 -->
      <el-drawer title="设置分润" :visible.sync="benefitDrawer" :direction="direction" :before-close="handleClose" size="35%">
        <benefit v-if="benefitDrawer" @close='handleClose' @success="handleDrawerSuccess(form)  "  ref='newForm' :id="id" :row="row"></benefit>
      </el-drawer>
      <el-drawer title="设置分润" :visible.sync="batteryBenefitDrawer" :direction="direction" :before-close="handleClose" size="35%">
        <batteryBenefit v-if="batteryBenefitDrawer" @close='handleClose' @success="handleDrawerSuccess(form)  "  ref='newForm' :id="id" :row="row"></batteryBenefit>
      </el-drawer>
      <!--设置费率 -->
      <el-drawer title="设置费率" :visible.sync="rateDrawer" :direction="direction" :before-close="handleClose" size="35%" >
        <rate v-if="rateDrawer" @close='handleClose' @success="handleDrawerSuccess(form)  "  ref='newForm' :id="id" :row="row"></rate>
      </el-drawer>
      <!--绑定模板 -->
      <el-drawer title="绑定模板" :visible.sync="templateDrawer" :direction="direction" :before-close="handleClose" size="35%" >
        <consumTemplate v-if="templateDrawer" @close='handleClose' @success="handleDrawerSuccess(form)  "  ref='newForm' :id="id" :row="row"></consumTemplate>
      </el-drawer>
    </div>
  </section>
</template>

<script>
import{ getNeibourhood,deleteNeibourhood } from '@/api/public.js';
import add from './add.vue';
import edit from './edit.vue';
import bind from './bind.vue';
import benefit from './benefitRules.vue';
import batteryBenefit from './batteryBenefit.vue';
import detail from './detail.vue';
import rate from './rate.vue';
import consumTemplate from './consumTemplate.vue';
export default {
  data() {
    return {
      neighbourInfo:[],// 社区信息
      options:[], // 街道选项
      street:'', // 选中的街道
      pageIndex:1,// 当前页码
      pageSize:10, // 每页数据条数
      total:0, // 数据总数
      // 弹框组件数据
      addDrawer:false, // 是否显示新增弹框
      editDrawer:false, // 是否显示修改弹框
      detailDrawer:false, // 是否显示详情弹框
      bindDrawer:false, // 是否显示绑定弹框
      benefitDrawer:false, // 是否显示设置分润弹框
      batteryBenefitDrawer:false, // 是否显示设置分润弹框
      rateDrawer:false, // 是否显示设置费率弹框
      templateDrawer:false, // 是否显示绑定模板弹框
      id:'', // 当前操作数据的id
      row:'',// 当前操作数据的具体信息
      direction:'rtl', // 弹窗打开位置
      searchInfo:{}, // 搜索条件
      loginRoleId:sessionStorage.getItem('roleId'), // 当前登陆账号角色
    }
  },

  components: {add,edit,bind,benefit,detail,rate,consumTemplate,batteryBenefit},

  computed: {},

  mounted() {
    this.init();
  },

  methods: {
    // 数据初始化
    async init(){
      await getNeibourhood({pageIndex:this.pageIndex,pageSize:this.pageSize,data:this.searchInfo}).then((res)=>{
        if(res.Success){
          this.neighbourInfo=res.Data.data;
          this.neighbourInfo.forEach(element => {
            let create = this.timeToTime(element.CreateTime.substring(6,19));
            let install = this.timeToTime(element.InstallDate.substring(6,19));
            element.CreateTime = `${create.Y}-${create.M}-${create.D} ${create.H}:${create.m}:${create.S}`;
            element.InstallDate=`${install.Y}-${install.M}-${install.D}`
            // 分别判断该场所的消费模板、代理、物业是否绑定了
            element.TemplateName=element.TemplateName?element.TemplateName:'暂未设置';
            element.AgentName=element.AgentName?element.AgentName:'暂未绑定';
            element.WeChatName=element.WeChatName?element.WeChatName:'暂未绑定';
          });
          this.total=res.Data.totalCount;
        }
      })
    },
    // 条件查询
    toSearch(){
      this.pageIndex=1;
      this.init();
    },
    // 清除查询条件
    backAll(){
      this.searchInfo={};
      this.pageIndex=1;
      this.init();
    },
    // 时间戳转时间
    timeToTime(time){
       const date = new Date(Number(time))
       const Y = date.getFullYear() // 年
       const M = date.getMonth() + 1 < 10 ? '0'+(date.getMonth()+1) : date.getMonth()+1 // 月
       const D = date.getDate() < 10 ? '0' + date.getDate() : date.getDate() // 日
       const H = date.getHours() < 10 ? '0' + date.getHours() : date.getHours() // 时
       const m = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes() // 分
       const S = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds() // 秒 
      //  const Time=`${Y}-${M}-${D} ${H}:${m}:${S}`;
       return {
        Y,
        M,
        D,H,m,S
       };
    },
    // 打开编辑
    handleEdit(index,row){
      this.row=row;
      this.editDrawer=true;
      this.id=index;
    },
    // 打开详情
    handleDetail(index,row){
      this.row=row;
      this.id=index;
      this.detailDrawer=true;
    },
    // 打开新增
    handleAdd(index,row){
      this.addDrawer=true;
    },
    // 打开绑定物业
    handleBind(index,row){
      this.row=row;
      this.row.Role=2;
      this.bindDrawer=true;
    },
    // 打开绑定代理
    handleAgent(index,row){
      this.row=row;
      this.row.Role=1;
      this.bindDrawer=true;
    },
    // 打开设置费率
    handleRate(index,row){
      this.row=row;
      this.rateDrawer=true;
      this.id=index;
    },
    // 打开设置分润
    handleBenefit(index,row){
      this.row=row;
      this.benefitDrawer=true;
    },
    handleBatteryBenefit(index,row){
      this.row=row;
      this.batteryBenefitDrawer=true;
    },
    // 打开绑定模板
    handleTemplate(index,row){
      this.row=row;
      this.templateDrawer=true;
    },
    // 新增、绑定、编辑成功
    handleDrawerSuccess(){
      this.addDrawer=false;
      this.editDrawer=false;
      this.bindDrawer=false;
      this.benefitDrawer=false;
      this.detailDrawer=false;
      this.rateDrawer=false;
      this.templateDrawer=false;
      this.batteryBenefitDrawer=false;
      this.init();
    },
    // 关闭
    handleClose(){
      if(this.detailDrawer){
        this.detailDrawer=false;
        this.init();
      }else{
        this.$confirm('关闭窗口将清空表单内容，是否关闭？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(()=>{
          this.addDrawer=false;
          this.editDrawer=false;
          this.bindDrawer=false;
          this.benefitDrawer=false;
          this.rateDrawer=false;
          this.templateDrawer=false;
          this.batteryBenefitDrawer=false;
          this.init();
        })
      }
    },
    // 删除
    async handleDel(index,row){
      await this.$confirm('确认删除该场所吗？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
           deleteNeibourhood({Id:row.Id}).then(res=>{
            if(res.Success){
              this.$message({
                message: "删除成功",
                type: "success",
              });
              this.init();
            }
          })
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });          
        });
    },
    // 分页
    handleCurrentChange(val){
      this.pageIndex=val;
      this.init();
    },
    prevpage(val){
      this.pageIndex=val;
      this.init();
    },
    nextpage(val){
      this.pageIndex=val;
      this.init();
    },
  },
};
</script>
<style scoped lang='scss'>
  @import "@/static/default";
   ::v-deep .el-drawer.rtl{
    overflow: auto !important;
  }

  .el-popover{
  width: 240px !important;
}
.spanBtn{
  width: 40%;
  cursor: pointer;
  display: inline-block;
  color: #40a9ff;
  text-align: center;
  padding-bottom: 5px;
}
/* 搜索 */
.search{
  margin-bottom: 30px;
  .inputs{
    display: flex;
    justify-content: space-between;
  }
  .wxName,.select,.timeSelect{
    width: 15%;
  }
  .timeSelect{
    width: 20%;
  }
  .Btns{
    display: flex;
    justify-content: flex-end;
    margin-bottom: 10px;
  }
}
/* 添加 */
.add{
    width: 100%;
    background-color: white;
    height: 60px;
    width: 100%;
    padding: 10px 50px;
    position: relative;
  }
  .add button{
    position: absolute;
    right:20px;
    top:10px;
    width: 100px;
  }
/* 内容 */
  .container{
    background-color: #fff !important;
    padding:0 32px;
    .toolbar{
      background: #fff;
      width:calc(100% + 64px);
      padding:16px 32px 26px 0;
      margin-left:-32px;
    }
  }
</style>
