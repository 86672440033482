<template>
<section>
  <div class="role">
    <el-button type="primary" @click="handleAdd">添加</el-button>
  </div>
  <div>
    <el-table
    :data="roleData"
    style="width: 100%;"
    row-key="id"
    default-expand-all
    :tree-props="{children: 'children', hasChildren: 'hasChildren'}">
      <el-table-column
        prop="Id"
        align="center"
        label="ID">
      </el-table-column>
      <el-table-column
        prop="RoleName"
        align="center"
        label="角色名称">
      </el-table-column>
      <el-table-column
        align="center"
        prop="IsLocked"
        label="是否锁定">
      </el-table-column>
      <el-table-column
        align="center"
        prop="IsDeleted"
        label="是否删除">
      </el-table-column>
      <el-table-column
        align="center"
        prop="CreatorName"
        label="创建人">
      </el-table-column>
      <el-table-column
        align="center"
        prop="CreateTime"
        label="创建时间">
      </el-table-column>
      <el-table-column
        align="center"
        prop="ModifierName"
        label="修改人">
      </el-table-column>
      <el-table-column
        align="center"
        prop="ModifiyTime"
        label="修改时间">
      </el-table-column>
      <el-table-column label="操作" fixed="right" align='center'>
      <template slot-scope="scope">
          <el-button
          size="mini"
          @click="handleDel(scope.$index, scope.row)">删除</el-button>
      </template>
    </el-table-column>
    </el-table>

    <!--分页-->
    <el-col :span="24" class="toolbar" >
      <el-pagination
        layout="total, prev, pager, next, jumper"
        @current-change="handleCurrentChange"
        @prev-click="prevpage"
        @next-click="nextpage"
        :page-size="10"
        :total="total"
        style="float:right;"
      ></el-pagination>
    </el-col>

    <!-- 新增菜单 -->
    <el-drawer
      title="新增菜单"
      :visible.sync="addDrawer"
      :direction="direction"
      :before-close="handleClose">
      <add v-if="addDrawer" @close='handleClose' @success="handleDrawerSuccess(form)  "  ref='newForm' :id="id" :row="row"></add>
    </el-drawer>
  </div>
</section>
  
</template>

<script>
  import {getRole,deleteRole} from '@/api/public.js';
  import add from './add.vue'

export default {
  data() {
    return {
      roleData: [],
      addDrawer:false,
      id:'',
      row:'',
      pageIndex:1,
      pageSize:10,
      direction:'rtl',
      total:0,
    }
  },

  components: {add},

  computed: {},

  mounted() {
    this.init();
  },

  methods: {
    // 页面初始化
    async init(){
      await getRole({pageIndex:this.pageIndex,pageSize:this.pageSize}).then(res=>{
        if(res.Success){
          this.roleData=res.Data.data;
          this.total=res.Data.totalCount;
          this.roleData.forEach((element) => {
            element.CreateTime=this.timeToTime(element.CreateTime.substring(6,19));
            element.ModifiyTime=this.timeToTime(element.ModifiyTime.substring(6,19));
            element.IsDeleted=element.IsDeleted == '0' ? '否' : '是';
            if(element.IsLocked){
              element.IsLocked='是'
            }else{
              element.IsLocked='否'
            }
          });
        }
      });
    },
    // 时间戳转时间
    timeToTime(time){
       const date = new Date(Number(time))
       const Y = date.getFullYear() // 年
       const M = date.getMonth() + 1 < 10 ? '0'+(date.getMonth()+1) : date.getMonth()+1 // 月
       const D = date.getDate() < 10 ? '0' + date.getDate() : date.getDate() // 日
       const H = date.getHours() < 10 ? '0' + date.getHours() : date.getHours() // 时
       const m = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes() // 分
       const S = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds() // 秒 
       const Time=`${Y}-${M}-${D} ${H}:${m}:${S}`;
       return Time;
    },
    // 打开新增菜单
    handleAdd(index,row){
      this.addDrawer=true;
    },
    // 关闭
    handleClose(){
      this.addDrawer=false;
    },
    // 新增成功
    handleDrawerSuccess(){
      this.addDrawer=false;
      this.init();
    },
    // 删除菜单
    async handleDel(index,row){
      await this.$confirm('确认删除该角色吗？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          deleteRole({Id:row.Id}).then(res=>{
            if(res.Success){
              this.$message({
                message: "删除成功",
                type: "success",
              });
              this.init();
            }
          })
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });          
        });
    },
    // 分页
    prevpage(val){
      this.pageIndex=val;
      this,init();
    },
    nextpage(val){
      this.pageIndex=val;
      this,init();
    },
    handleCurrentChange(val){
      this.pageIndex =val;
      this.init();
    },
  },
};
</script>
<style scoped>
  .role{
    width: 100%;
    background-color: white;
    height: 60px;
    width: 100%;
    padding: 10px 50px;
    position: relative;
  }
  .role button{
    position: absolute;
    right:20px;
    top:10px;
  }</style>
