<template>
    <section>
      <el-form ref="form" label-position="right" status-icon :model="form" :rules="rules" label-width="100px" style="width:95%;overflow:auto">
        <el-form-item label="用户名称" prop="OwerName">
          <el-input placeholder="请输入用户名称" v-model="form.OwerName">
          </el-input>
        </el-form-item>
        <el-form-item label="用户电话" prop="Phone">
          <el-input placeholder="请输入用户电话" v-model="form.Phone">
          </el-input>
        </el-form-item>
        <el-form-item label="车牌号" prop="Licenseplate">
          <el-input placeholder="请输入车牌号" v-model="form.Licenseplate">
          </el-input>
        </el-form-item>
        <el-form-item label="申请小区" prop="Communityapply">
          <el-input  placeholder="请输入申请小区" v-model="form.Communityapply">
          </el-input>
        </el-form-item>
        <!-- <el-form-item label="卡状态" prop="State">
          <el-select class='select' v-model="form.State" placeholder="请选择" @change="selectState">
            <el-option
              v-for="item in statusOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </el-form-item> -->
        <el-form-item>
          <el-button @click="close('form')">取消</el-button>
          <el-button type="primary" @click="add('form')" :plain='true'>提交</el-button>
        </el-form-item>
      </el-form>
    </section>
  </template>
  
  <script>
  let _this;
  import {updateWeChatOwerInfo} from '@/api/public.js';
  //  自定义表单验证规则
  var checkAmount = (rule, value, callback) => {
    if (value<0) {
      return callback(new Error('金额不得小于零'));
    }else{
        callback();
    }
  };
  export default {
    props:{row:{type:Object}},
    data() {
      return {
        form:{ // 表单数据
            OwerName:'',
            Phone:'',
            Licenseplate:'',
            Communityapply:'',
        },
        rules: { // 表单验证
          OwerName: [
            { required: true, message: "用户名称不得为空", trigger: "blur" },
          ],
          Phone: [
            { required: true, message: "用户电话不得为空", trigger: "blur" },
          ],
          Licenseplate: [
            { required: true, message: "车牌号不得为空", trigger: "blur" },
          ],
          Communityapply: [
            { required: true, message: "申请小区不得为空", trigger: "blur" },
            // { validator: checkAmount, trigger: 'blur' }
          ],
        },
        statusOptions:[ // 卡状态选项
          {value:0,label:'正常'},
          {value:1,label:'停用'},
          {value:4,label:'被占用'},
        ],
      };
    },
  
    components: {},
  
    computed: {},
  
    mounted() {
      _this=this;
      this.form = {...this.row}
    },
  
    methods: {
       changeCardNumber(e){ 
        let  num='0x'+e//0x的意思为：在数字前面加上0x，表示十六进制的数
        this.form.CardNum=Number(num);
       },
       close(){
          _this.$emit('success');
       },
       selectState(e){
       },
       //  添加
       add(form) {
          _this.$refs["form"].validate(valid => {
            if (valid) {
                updateWeChatOwerInfo(_this.form)
                .then((res) => {
                  if (res.Success) {
                    _this.$message({
                      message: "提交成功",
                      type: "success",
                    });
                    setTimeout(() => {
                      _this.$emit("success");
                    }, 1000);
                  }else{
                    _this.$message({
                      message: "提交失败,请重试",
                      type: "fail",
                    });
                  }
                })
                .catch(error => {});
            } else {
              return false;
            }
        });
      },
    }
  };
  </script>
  <style lang="scss" scoped>
   .el-drawer{
     overflow:auto !important;
   }
   .el-select{
    width: 100%;
   }
   .avatar-uploader .el-upload {
      cursor: pointer;
      position: relative;
      overflow: hidden;
    }
    .avatar-uploader .el-upload:hover {
      border-color: #409EFF;
    }
    .avatar-uploader-icon {
      border: 1px dashed #ccc;
      border-radius: 50%;
      overflow:hidden;
      font-size: 28px;
      color: #8c939d;
      width: 178px;
      height: 178px;
      line-height: 178px;
      text-align: center;
    }
    .avatar {
      width: 178px;
      height: 178px;
      display: block;
    }
  </style>
  