<template>
    <section>
      <el-form ref="form" label-position="right" status-icon :model="form" :rules="rules" label-width="150px" style="width:95%;overflow:auto">
        <el-form-item label="字典名称" prop="Name" >
          <el-input placeholder="请输入字典名称" v-model="form.Name">
          </el-input>
        </el-form-item>
        <el-form-item label="字典值" prop="code" >
          <el-input placeholder="请输入字典值" v-model="form.code">
          </el-input>
        </el-form-item>
        <el-form-item label="所属类型" prop="sign">
          <el-select class='select' filterable  remote v-model="form.sign" clearable placeholder="请输入类型名称查询" @change="chooseType" :remote-method="remoteMethod" @clear="clear">
            <el-option
              v-for="item in typeOptions"
              :key="item.code"
              :label="item.Name+' - '+item.code"
              :value="item.code">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="字典备注" prop="remarks" >
          <el-input placeholder="请输入字典备注" v-model="form.remarks">
          </el-input>
        </el-form-item>
        <el-form-item label="拓展描述" prop="explain" >
          <el-input placeholder="请输入拓展描述" v-model="form.explain">
          </el-input>
        </el-form-item>
        <el-form-item>
          <el-button @click="close('form')">取消</el-button>
          <el-button type="primary" @click="submit" :plain='true'>提交</el-button>
        </el-form-item>
      </el-form>
    </section>
  </template>
  
  <script>
  let _this;
  import {addWechatDictionaries,editWechatDictionaries,getWechatDictionariesList} from '@/api/public.js';
  export default {
    props:['row'],
    data() {
      return {
        form:{ // 表单数据
          Name:'',
          code:'',
          sign:'',
        },
        rules: { // 表单验证
          Name: [
            { required: true, message: '字典名称不得为空', trigger: 'blur', }
          ],
          code: [
            { required: true, message: '字典值不得为空', trigger: 'change', }
          ],
          sign: [
            { required: true, message: '请选择所属类型', trigger: 'change', }
          ],
        },
        typeOptions:[],
      };
    },
  
    components: {},
  
    computed: {},
  
    mounted() {
      _this=this;
      this.getTypeList();
      this.form = this.row ? this.row :this.form;
    },
  
    methods: {
      close(){
         _this.$emit('close');
      },
      getTypeList(){
        getWechatDictionariesList().then(res=>{
              if(res.Success){
                  this.typeOptions = res.Data;
              }
          })
      },
      chooseType(){},
      clear(){},
      // 远程搜索
    async remoteMethod(query) {
      await getWechatDictionariesList({name:query}).then(res=>{
        if(res.Success){
          this.typeOptions = res.Data;
        }else{
          this.OwnerOptions=[];
        }
      })
    },
      // 提交新增  
      async add(){
        await addWechatDictionaries(this.form).then(res=>{
            if(res.Success){
                this.$message({
                  message: "提交成功",
                  type: "success",
                });
                setTimeout(() => {
                  _this.$emit("close");
                }, 1000);
            }else{
                this.$message({
                  message:res.Message,
                  type: "fail",
                });
            }
        })
      },
      // 提交编辑  
      async edit(){
        await editWechatDictionaries(this.form).then(res=>{
            if(res.Success){
                this.$message({
                  message: "提交成功",
                  type: "success",
                });
                setTimeout(() => {
                  _this.$emit("close");
                }, 1000);
            }else{
                this.$message({
                  message:res.Message,
                  type: "fail",
                });
            }
        })
      },
       // 提交
       submit() {
          _this.$refs["form"].validate(valid => {
            if (valid) {
              this.$confirm('确认提交吗？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
              }).then(()=>{
                if(this.row){
                    this.edit()
                }else{
                    this.add();
                }
              }).catch(() => {});
            } else {
              return false;
            }
        });
      },
      
    }
  };
  </script>
  <style lang="scss" scoped>
  .select{
    width: 100% !important;
  }
   .el-drawer{
     overflow:auto !important;
   }
   .avatar-uploader .el-upload {
      cursor: pointer;
      position: relative;
      overflow: hidden;
    }
    .avatar-uploader .el-upload:hover {
      border-color: #409EFF;
    }
    .avatar-uploader-icon {
      border: 1px dashed #ccc;
      border-radius: 50%;
      overflow:hidden;
      font-size: 28px;
      color: #8c939d;
      width: 178px;
      height: 178px;
      line-height: 178px;
      text-align: center;
    }
    .avatar {
      width: 178px;
      height: 178px;
      display: block;
    }
  </style>
  