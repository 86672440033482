<template>
  <section>
    <div class="cover" v-if='showCover' @click="closeCover">
        <div class="inside" @click="stopPop">
            <p class="title">新增收费规则 <i class="el-icon-error" @click="closeCover"></i></p>
            <p>
                <input type="number" v-model="startTime" :disabled='isDisabled' @keypress="onEnterPress"><span>时—</span>
                <input type="number" v-model="endTime" @keypress="onEnterPress"><span>时</span>
            </p>
            <el-radio-group v-model="rate">
              <el-radio label="尖电"></el-radio>
              <el-radio label="峰电"></el-radio>
              <el-radio label="平电"></el-radio>
              <el-radio label="谷电"></el-radio>
            </el-radio-group>
            <button @click="confirm">确认</button>
        </div>
    </div>
    <el-form ref="form" label-position="right" status-icon :model="form" :rules="rules" label-width="150px" style="width:95%;overflow:auto">
      <el-form-item label="模板名称" prop="TemplateName" >
        <el-input placeholder="请输入模板名称" v-model="form.TemplateName"></el-input>
      </el-form-item>
      <el-form-item label="收费档位(元)" prop="AmountGears" >
        <el-select v-model="form.AmountGears" placeholder="请选择" multiple @change="selectAmountGears" style="width:100%">
           <el-option
             v-for="item in amountGearsOptions"
             :key="item.value"
             :label="item.label"
             :value="item.value"
            >
           </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="尖电电费费率(元/度)" prop="PointedAmount" >
        <el-input placeholder="请输入费率" type="number" v-model="form.PointedAmount"></el-input>
      </el-form-item>
      <el-form-item label="峰电电费费率(元/度)" prop="PeakAmount" >
        <el-input placeholder="请输入费率" type="number" v-model="form.PeakAmount"></el-input>
      </el-form-item>
      <el-form-item label="平电电费费率(元/度)" prop="FlatAmount" >
        <el-input placeholder="请输入费率" type="number" v-model="form.FlatAmount"></el-input>
      </el-form-item>
      <el-form-item label="谷电电费费率(元/度)" prop="ValleyAmount" >
        <el-input placeholder="请输入费率" type="number" v-model="form.ValleyAmount"></el-input>
      </el-form-item>
      <el-form-item label="定价档位" prop="TimePeriodRate" >
        <div class="eachPhase">
            <p v-for="(item,index) in form.TimePeriodRate" :key="index">
                {{item.startTime+'时—'+item.endTime+'时&nbsp;&nbsp;&nbsp;'+item.ele+'元/度'}}
                <i class="el-icon-error" @click="del(index)"></i>
            </p>
            <p style="font-size: 12px;color: #c93232;">(提示：若您修改了尖、峰、平、谷的费率，须重新填写定价档位)</p>
        </div>
        <el-button  class="button-new-tag" size="small" @click="add" style="width:100%">+ 添加</el-button>
      </el-form-item>
      <el-form-item label="服务费(元/度)" prop="ServiceAmount" >
        <el-input placeholder="请输入服务费金额" v-model="form.ServiceAmount" type='number'></el-input>
      </el-form-item>
      <el-form-item label="折损率(%)" prop="ElectricalLossRate" >
        <el-input placeholder="请输入折损率" v-model="form.ElectricalLossRate" type='number'></el-input>
      </el-form-item>
      <el-form-item label="备注" prop="Remarks">
         <el-input
           type="textarea"
           :rows="2"
           placeholder="请输入备注"
           v-model="form.Remarks">
         </el-input>
      </el-form-item>
      <el-form-item>
        <el-button @click="close('form')">取消</el-button>
        <el-button type="primary" @click="edit('form')" :plain='true'>{{template.length==0?'提交':'修改'}}</el-button>
      </el-form-item>
    </el-form>
  </section>
</template>

<script>
import { updateConsumeTemplate } from '@/api/public.js';
let arr=[];
export default {
  props:{row:{type:Object}},
  data() {
    return {
        rate:'',
        // 当前场所所属消费模板
        template:[],
        // 表单信息
        form:{
          TemplateName:'',
          AmountGears:[],
          TimePeriodRate:[],
          ServiceAmount:'',
          ElectricalLossRate:'',
          IsLeave:'',
          Remarks:'',
        },
        // 充电金额选项
        amountGearsOptions:[
          {label:10,value:10,},
          {label:20,value:20,},
          {label:30,value:30,},
          {label:40,value:40,},
          {label:50,value:50,},
          {label:60,value:60,},
          {label:70,value:70,},
          {label:80,value:80,},
          {label:90,value:90,},
          {label:100,value:100,},
          {label:150,value:150,},
        ],
        // 表单验证
        rules: {
        TemplateName: [
          { required: true, message: '模板名称不得为空', trigger: 'blur', }
        ],
        AmountGears: [
          { required: true, message: '收费档位不得为空', trigger: 'change' }
        ],
        TimePeriodRate: [
          { required: true, message: '时间段费率不得为空', trigger: 'change' }
        ],
        PointedAmount: [
            { required: true, message: '尖电费率不得为空', trigger: 'blur' }
          ],
          PeakAmount: [
            { required: true, message: '峰电费率不得为空', trigger: 'blur' }
          ],
          FlatAmount: [
            { required: true, message: '平电费率不得为空', trigger: 'blur' }
          ],
          ValleyAmount: [
            { required: true, message: '谷电费率不得为空', trigger: 'blur' }
          ],
        ServiceAmount: [
          { required: true, message: '服务费不得为空', trigger: 'blur' }
        ],
        ElectricalLossRate: [
            { required: true, message: '折损率不得为空', trigger: 'blur' }
          ],
      },
        showCover:false, // 是否显示遮罩层
        startTime:'', // 开始时间
        endTime:'', // 结束时间
        ele:'', // 电费
        isDisabled:true, // 输入框是否禁用
    }
  },

  components: {},

  computed: {},

  mounted() {
    this.form = this.row;
    this.form.AmountGears = JSON.parse(this.form.AmountGears);
    this.form.TimePeriodRate = JSON.parse(this.form.TimePeriodRate);
    this.form.TimePeriodRate.forEach(element=>{
      element.ele = Number(element.ele) / 100;
    })
    console.log(this.form,222)
  },

  methods: {
    // 取消操作，关闭弹窗
    close(){
       this.$emit('close');
    },
    // 选择充电金额
    selectAmountGears(e){
      arr=[];
      e.forEach(element => {
        this.amountGearsOptions.forEach(ele=>{
          if(element == ele.value){
            arr.push(ele.label)
          }
        })
      });
    },
    // 打开新增时间段费率
    add(){
      if(this.form.TimePeriodRate.length>=8){
          this.$message({
            showClose: true,
            message: '最多新增8个时间段',
            type: 'error',
          });
      }else{
          this.showCover=true;
      }
      if(this.form.TimePeriodRate.length==0 || this.form.TimePeriodRate[0].startTime!==0){ // 第一次添加收费规则
          this.startTime=0;
          this.endTime='';
          this.rate='';
      }else{
          let isOrder=true; // 是否出现时间断层
          let ind; // 断层位置
          for(let i=0;i<this.form.TimePeriodRate.length-1;i++){
              if(this.form.TimePeriodRate[i].endTime != this.form.TimePeriodRate[i+1].startTime){ // 判断所有的时间梯s度是否按顺序闭环相连
                  isOrder=false;
                  ind=i;
                  break;
              }
          }
          if(isOrder){ // 时间梯度按顺序闭环，则新增的开始时间为数组中最后一个对象的结束时间
              let last=this.form.TimePeriodRate.length-1;
              this.isDisabled=true;
              this.startTime=this.form.TimePeriodRate[last].endTime;
              this.endTime='';
              this.rate='';
          }else{ // 时间梯度中间有断层，则新增的开始时间为开始断层的数组项的结束时间
              this.isDisabled=true;
              this.startTime=this.form.TimePeriodRate[ind].endTime;
              this.endTime='';
              this.rate='';
          }
      }
  },
    // 回车键新增时间段费率
    onEnterPress(e){
      if(e.keyCode===13){
        this.confirm();
      }
    },
    // 确认新增时间段费率
    confirm(){ // 用户点击提交表单时,如果时间梯度出现断层，提示：梯度时间不构成连续，请继续完善
        if(parseInt(this.startTime)>24 || parseInt(this.endTime)>24){
            this.$message({
              showClose: true,
              message: '时间不得超过24小时',
              type: 'error',
            });
        }else if(this.startTime==='' || this.endTime=='' || this.rate==''){
            this.$message({
              showClose: true,
              message: '时间或费率类型不得为空',
              type: 'error',
            });
        }else if(parseInt(this.startTime) >= parseInt(this.endTime)){
            this.$message({
              showClose: true,
              message: '开始时间应小于结束时间',
              type: 'error',
            });
        }else{
            let each={
                startTime:this.startTime,
                endTime:this.endTime,
                ele:this.rate == '尖电' ? this.form.PointedAmount : (this.rate == '峰电' ? this.form.PeakAmount : (this.rate == '平电' ? this.form.FlatAmount : (this.rate == '谷电' ? this.form.ValleyAmount : 0))),
            };
            console.log(each,777)
            let isTrue=true; // 是否出现时间断层
            let where; // 断层位置
            if(this.form.TimePeriodRate.length>0 && this.form.TimePeriodRate[0].startTime !=0){ // 时间梯度没有从0点开始
                isTrue=false;
                where=-1;
            }else{
                for(let k=0;k<this.form.TimePeriodRate.length-1;k++){
                    if(this.form.TimePeriodRate[k].endTime != this.form.TimePeriodRate[k+1].startTime){
                        isTrue=false;
                        where=k;
                        break;
                    }
                }
            }
            if(isTrue){ // 时间梯度按顺序闭环，则直接在数组最后新增一项
                this.form.TimePeriodRate.push(each);
                this.showCover=false;
            }else{ // 时间梯度中间出现断层，则在数组出现断层的位置后新增一项
                if(parseInt(each.endTime)<=parseInt(this.form.TimePeriodRate[where+1].startTime)){ // 此时新增的一项的结束时间不得大于它后面一项的开始时间
                    this.form.TimePeriodRate.splice(where+1,0,each);
                    this.showCover=false;
                }else{
                    this.$message({
                      showClose: true,
                      message: '结束时间不得大于下一阶段的开始时间',
                      type: 'error',
                    });
                }
            }
        }
    },
    // 删除时间段费率
    del(index){
        this.form.TimePeriodRate.splice(index,1);
    },
    // 关闭时间段费率遮罩层
    closeCover(){
        this.showCover=false;
    },
    // 阻止事件冒泡
    stopPop(e){
        e.stopPropagation();
    },
    // 提交或修改
    async edit(){
        let data={...this.form};
        data.AmountGears=JSON.stringify(arr);
            this.$refs["form"].validate(valid => {
              if (valid) {
                let isOrder=true; // 是否出现时间断层
                let ind; // 断层位置
                for(let i=0;i<this.form.TimePeriodRate.length-1;i++){
                    if(this.form.TimePeriodRate[i].endTime != this.form.TimePeriodRate[i+1].startTime){ // 判断所有的时间梯度是否按顺序闭环相连
                        isOrder=false;
                        ind=i;
                        break;
                    }
                }
                let len=this.form.TimePeriodRate.length-1;
                if(isOrder && this.form.TimePeriodRate[0].startTime==0 && this.form.TimePeriodRate[len].endTime==24){ // 时间梯度按顺序闭环
                    data.TimePeriodRate.forEach(element=>{
                      element.ele=Math.round(element.ele*100);
                    })
                    data.TimePeriodRate=JSON.stringify(data.TimePeriodRate);
                    updateConsumeTemplate(data)
                    .then((res) => {
                      if (res.Success) {
                        this.$message({
                          message: "提交成功",
                          type: "success",
                        });
                        setTimeout(() => {
                          this.$emit("success");
                        }, 1000);
                      }else{
                        this.$message({
                          message: "提交失败,请重试",
                          type: "fail",
                        });
                      }
                    })
                    .catch(error => {});
                }else{ // 时间梯度中间有断层
                    this.$message({
                      message: "梯度时间不构成24小时连续，请继续完善",
                      type: "error",
                    });
                }
              } else {
                return false;
              }
            });
    },
  },
};
</script>
<style scoped lang='scss'>
// 新增时间梯度收费模板遮罩层
.cover{
    height: 100%;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba($color: #000000, $alpha: 0.5);
    z-index: 2000;
    .inside{
        width: 500px;
        height: 300px;
        background: #fff;
        padding: 20px 0;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        p{
            padding: 0 20px;
            input{
                height: 20px;
                line-height: 20px;
                width: 39%;
            }
            span{
                display: inline-block;
                width: 10%;
            }
            &:nth-child(3){
                display: flex;
                input{
                    flex: 1;
                }
            }
        }
        button{
            height: 30px;
            width: 100px;
            margin: 0 auto;
        }
        .title{
            display: flex;
            height: 40px;
            margin-bottom: 20px;
            border-bottom: solid 1px #ececec;
            align-items: center;
            justify-content: space-between;
        }
        i{
            font-size: 20px;
            cursor: pointer;
        }
    }
}
// 时间梯度收费模板列表
.eachPhase{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    p{
        width: 100%;
        height: 30px;
        line-height: 30px;
    }
    i{
        margin-left: 10px;
        height: 15px;
        width: 15px;
        color: red;
        cursor: pointer;
    }
}
</style>
