<template>
<section>
  <!-- 搜索 -->
  <div class="search">
    <div class="inputs">
      <el-input class="wxName" v-model="searchInfo.TemplateName" placeholder="模板名称"></el-input>
      <el-select class='select' v-model="pay" clearable placeholder="是否预付">
        <el-option
          v-for="item in payOptions"
          :key="item.value"
          :label="item.label"
          :value="item.value">
        </el-option>
      </el-select>
      <el-select class='select' v-model="leave" clearable placeholder="是否充满自离">
        <el-option
          v-for="item in leaveOptions"
          :key="item.value"
          :label="item.label"
          :value="item.value">
        </el-option>
      </el-select>
      <el-select class='select' v-model="refund" clearable placeholder="退款方式">
        <el-option
          v-for="item in refundOptions"
          :key="item.value"
          :label="item.label"
          :value="item.value">
        </el-option>
      </el-select>
      <div class="Btns">
       <el-button type="primary" @click="toSearch">查询</el-button>
       <el-button  @click="backAll">返回所有</el-button>
    </div>
    </div>
  </div>
  <div class="role">
    <el-button type="primary" @click="handleAdd">添加</el-button>
  </div>
  <div>
    <el-table
      :data="consumptionData"
      style="width: 100%;"
      row-key="id"
      default-expand-all
      :tree-props="{children: 'children', hasChildren: 'hasChildren'}"
    >
      <af-table-column
        prop="Id"
        align="center"
        label="ID">
      </af-table-column>
      <af-table-column
        prop="TemplateName"
        align="center"
        label="名称">
      </af-table-column>
      <af-table-column
        align="center"
        prop="TemplateType"
        label="类别">
      </af-table-column>
      <af-table-column
        align="center"
        prop="IsPayAdvance"
        label="是否预付">
      </af-table-column>
      <af-table-column
        align="center"
        prop="IsLeave"
        label="是否充满自离">
      </af-table-column>
      <af-table-column
        align="center"
        prop="RefundMode"
        label="退款方式">
      </af-table-column>
      <af-table-column
        align="center"
        prop="CreateTime"
        label="创建时间">
      </af-table-column>
      <af-table-column
        align="center"
        prop="Remarks"
        label="备注">
      </af-table-column>
      <el-table-column label="操作" fixed="right" align='center' min-width="230">
        <template slot-scope="scope">
          <el-button size="mini" @click="handleEdit(scope.$index, scope.row)">编辑</el-button>
          <el-button size="mini" @click="handleDetail(scope.$index, scope.row)">详情</el-button>
          <el-button size="mini" @click="handleDel(scope.$index, scope.row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>

    <!--分页-->
    <el-col :span="24" class="toolbar" >
      <el-pagination
        layout="total, prev, pager, next, jumper"
        @current-change="handleCurrentChange"
        @prev-click="prevpage"
        @next-click="nextpage"
        :page-size="10"
        :total="total"
        style="float:right;"
      ></el-pagination>
    </el-col>

    <!-- 新增菜单 -->
    <el-drawer
      title="新增菜单"
      :visible.sync="addDrawer"
      :direction="direction"
      :before-close="handleClose"
      size='50%'
      style="overflow:auto">
      <add v-if="addDrawer" @close='handleClose' @success="handleDrawerSuccess(form)  "  ref='newForm' :id="id" :row="row"></add>
    </el-drawer>
    <!-- 修改模板 -->
    <el-drawer
      title="修改模板"
      :visible.sync="editDrawer"
      :direction="direction"
      :before-close="handleClose"
      size='50%'>
      <edit v-if="editDrawer" @close='handleClose' @success="handleDrawerSuccess(form)  "  ref='newForm' :id="id" :row="row"></edit>
    </el-drawer>
    <!-- 模板详情 -->
    <el-drawer
      title="模板详情"
      :visible.sync="detailDrawer"
      :direction="direction"
      :before-close="handleClose"
      size='50%'>
      <detail v-if="detailDrawer" @close='handleClose' @success="handleDrawerSuccess(form)  "  ref='newForm' :id="id" :row="row"></detail>
    </el-drawer>
  </div>
</section>
  
</template>

<script>
  import {getBatteryConsumeTemplateList,deleteBatteryConsumeTemplate,getWechatUser} from '@/api/public.js';
  import add from './add.vue';
  import edit from './edit.vue';
  import detail from './detail.vue'

export default {
  data() {
    return {
      consumptionData: [],
      addDrawer:false,
      editDrawer:false,
      detailDrawer:false,
      id:'',
      row:'',
      direction:'rtl',
      pageIndex:1,
      pageSize:10,
      total:0,
      wechatUsers:[],
      searchInfo:{
        RefundMode:-1,
        IsPayAdvance:-1,
        IsLeave:-1,
      },
      payOptions:[
        {value:0,label:'否'},
        {value:1,label:'是'},
      ],
      leaveOptions:[
        {value:0,label:'否'},
        {value:1,label:'是'},
      ],
      refundOptions:[
        {value:0,label:'退回余额'},
        {value:1,label:'原路返回'},
      ],
      pay:'',
      leave:'',
      refund:'',
    }
  },

  components: {add,edit,detail},

  computed: {},

  mounted() {
    this.init();
  },

  methods: {
    // 页面初始化
    async init(){
      await getBatteryConsumeTemplateList({pageIndex:this.pageIndex,pageSize:this.pageSize,data:this.searchInfo}).then(res=>{
        if(res.Success){
          this.consumptionData=res.Data.data;
          this.total=res.Data.totalCount;
          this.consumptionData.forEach(element => {
            element.CreateTime=this.timeToTime(element.CreateTime.substring(6,19));
            element.IsPayAdvance=element.IsPayAdvance == 0 ? '否' : '是';
            element.IsLeave=element.IsLeave == 0 ? '否' : '是';
            element.RefundMode=element.RefundMode == 0 ? '退回余额' : '原路返回';
            element.IsDeleted=element.IsDeleted == 0 ? '否' : '是';
            element.TemplateType=element.TemplateType == 0 ? '功率模式' :(element.TemplateType==1? '时间模式':'电量模式');
            element.TemplateOwner=element.TemplateOwner == 0 ? '暂无' : element.TemplateOwner;
            if(element.TemplateType=='功率模式' || element.TemplateType=='电量模式'){
              element.TimeLength=element.TimeLength?element.TimeLength.split(','):element.TimeLength;
              element.AmountLength=element.AmountLength?element.AmountLength.split(','):element.AmountLength;
            }
          });
        }
      });
      await getWechatUser({pageIndex:1,pageSize:1000}).then(res=>{
        if(res.Success){
          this.wechatUsers=res.Data.data;
          this.wechatUsers.forEach(ele=>{
            this.consumptionData.forEach(item=>{
              if(ele.Id==item.TemplateOwner){
                item.TemplateOwner=ele.CustomerName;
              }
            })
          })
        }
      })
    },
    // 时间戳转时间
    timeToTime(time){
       const date = new Date(Number(time))
       const Y = date.getFullYear() // 年
       const M = date.getMonth() + 1 < 10 ? '0'+(date.getMonth()+1) : date.getMonth()+1 // 月
       const D = date.getDate() < 10 ? '0' + date.getDate() : date.getDate() // 日
       const H = date.getHours() < 10 ? '0' + date.getHours() : date.getHours() // 时
       const m = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes() // 分
       const S = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds() // 秒 
       const Time=`${Y}-${M}-${D} ${H}:${m}:${S}`;
       return Time;
    },
    // 条件查询
    toSearch(){
      this.searchInfo.IsPayAdvance=this.pay !==''  ? this.pay : -1; 
      this.searchInfo.IsLeave=this.leave !==''  ? this.leave : -1; 
      this.searchInfo.RefundMode=this.refund !==''  ? this.refund : -1; 
      this.pageIndex=1;
      this.init();
    },
    // 清除查询条件
    backAll(){
      this.searchInfo={
        RefundMode:-1,
        IsPayAdvance:-1,
        IsLeave:-1,
      };
      this.pay='';
      this.leave='';
      this.refund=''
      this.init();
    },
    // 打开新增菜单
    handleAdd(index,row){
      this.addDrawer=true;
    },
    // 打开修改模板
    handleEdit(index,row){
      this.row=row;
      this.editDrawer=true;
      this.id=index;
    },
    // 关闭
    handleClose(){
      this.addDrawer=false;
      this.editDrawer=false;
      this.detailDrawer=false;
      this.init();
    },
    // 删除菜单
    async handleDel(index,row){
      await this.$confirm('确认删除该模板吗？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          deleteBatteryConsumeTemplate({Id:row.Id}).then(res=>{
            if(res.Success){
              this.$message({
                message: "删除成功",
                type: "success",
              });
              this.init();
            }
          })
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });          
        });
    },
    // 修改、新增成功关闭抽屉
    handleDrawerSuccess(){
      this.addDrawer=false;
      this.editDrawer=false;
      this.init();
    },
    // 查看详情
    handleDetail(index,row){
      this.row=row;
      this.detailDrawer=true;
    },
    // 分页
    prevpage(val){
      this.pageIndex=val;
      this.init();
    },
    nextpage(val){
      this.pageIndex=val;
      this.init();
    },
    handleCurrentChange(val){
      this.pageIndex =val;
      this.init();
    },
  },
};
</script>
<style scoped lang='scss'>
  /* 搜索 */
.search{
  margin-bottom: 30px;
  .inputs{
    display: flex;
    text-align: left;
  }
  .wxName,.select,.timeSelect{
    width: 18%;
    margin-right: 20px;
  }
  .Btns{
    display: flex;
    justify-content: flex-end;
    margin-bottom: 10px;
  }
}
  .role{
    width: 100%;
    background-color: white;
    height: 60px;
    width: 100%;
    padding: 10px 50px;
    position: relative;
  }
  .role button{
    position: absolute;
    right:20px;
    top:10px;
  }
  ::v-deep .el-drawer.rtl{
    overflow: auto !important;
  }
</style>
