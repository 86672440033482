<template>
  <section>
      <!-- 搜索 -->
      <div class="search">
        
        <div class="inputs">
          <el-select class='select' v-model="searchInfo.ApplyState" clearable  placeholder="请选择申请状态">
            <el-option
              v-for="item in statusOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
          <div class="Btns">
           <el-button type="primary" @click="toSearch">查询</el-button>
           <el-button  @click="backAll">返回所有</el-button>
          </div>
        </div>
      </div>
     <div class="container">
       <el-table
        :data="withdrawalApplyInfo"
        style="width: 100%" 
        class="table"
        fit
      >
        <!-- 数据展示区 -->
        <el-table-column
          label="ID"
          prop="Id"
          align='center'
          width='60px'>
        </el-table-column>
        <el-table-column
          label="申请编号"
          prop="ApplyNum"
          align='center'>
        </el-table-column>
        <el-table-column
          label="申请人id"
          prop="WithdrawalId"
          align='center'>
        </el-table-column>
        <el-table-column
          label="提现金额"
          prop="WithdrawalAmount"
          align='center'>
        </el-table-column>
        <el-table-column
          label="手续费"
          prop="Poundage"
          align='center'>
        </el-table-column>
        <el-table-column
          label="申请状态"
          prop="ApplyState"
          align='center'>
        </el-table-column>
        <el-table-column
          label="申请时间"
          prop="ApplyTime"
          align='center'>
        </el-table-column>
        <el-table-column
          label="更新时间"
          prop="UpdateTime"
          align='center'>
        </el-table-column>
        <el-table-column
          label="备注"
          prop="Remarks"
          align='center'>
        </el-table-column>
        <el-table-column label="操作" fixed="right" align='center'>
          <template slot-scope="scope">
            <el-button size="mini" @click="handleEdit(scope.$index, scope.row,1)" v-if="scope.row.ApplyState=='待审核'">审核</el-button>
            <el-button size="mini" @click="handleEdit(scope.$index, scope.row,2)" v-if="scope.row.ApplyState=='待打款'">打款</el-button>
            <el-button size="mini" @click="handleEdit(scope.$index, scope.row,3)" v-if="scope.row.ApplyState!='撤销' || scope.row.ApplyState=='打款完成'">撤销</el-button>
          </template>
      </el-table-column>
      </el-table>
      <!--分页-->
      <el-col :span="24" class="toolbar" >
        <el-pagination
          layout="total, prev, pager, next, jumper"
          @current-change="handleCurrentChange"
          @prev-click="prevpage"
          @next-click="nextpage"
          :page-size="10"
          :total="total"
          style="float:right;"
        ></el-pagination>
      </el-col>
      <el-drawer
        title="状态修改"
        :visible.sync="editDrawer"
        :direction="direction"
        :before-close="handleClose">
        <edit v-if="editDrawer" @close='handleClose' @success="handleDrawerSuccess(form)  "  ref='newForm' :id="id" :row="row"></edit>
      </el-drawer>
    </div>
  </section>
</template>

<script>
import {getWithdrawalApply,updateApplyState} from '@/api/public.js';
import edit from './edit.vue'
export default {
  data() {
    return {
      pageIndex:1,
      pageSize:10,
      total:'',
      // 申请数据
      withdrawalApplyInfo:[],
      editDrawer:false,
      id:'',
      row:'',
      direction:'rtl',
      searchInfo:{},
      statusOptions:[
        {value:0,label:'待审核'},
        {value:1,label:'待打款'},
        {value:2,label:'打款完成'},
        {value:3,label:'撤销'},
      ]
    }
  },

  components: {edit},

  computed: {},

  mounted() {
    this.init();
  },

  methods: {
    async init(){
      await getWithdrawalApply({pageIndex:this.pageIndex,pageSize:this.pageSize,data:this.searchInfo}).then((res)=>{
        if(res.Success){
          this.withdrawalApplyInfo=res.Data.data;
          this.total=res.Data.totalCount;
          this.withdrawalApplyInfo.forEach(element => {
            element.ApplyTime=this.timeToTime(element.ApplyTime.substring(6,19));
            element.UpdateTime=this.timeToTime(element.UpdateTime.substring(6,19));
            element.ApplyState=element.ApplyState == 0 ? '待审核' : (element.ApplyState == 1 ? '待打款' : (element.ApplyState == 2 ? '打款完成' : '撤销'));
          });
        }
      })
    },
    // 条件查询
    toSearch(){
      this.pageIndex=1;
      this.init();
    },
    // 清除查询条件
    backAll(){
      this.pageIndex=1;
      this.searchInfo={};
      this.init();
    },
    // 时间戳转时间
    timeToTime(time){
       const date = new Date(Number(time))
       const Y = date.getFullYear() // 年
       const M = date.getMonth() + 1 < 10 ? '0'+(date.getMonth()+1) : date.getMonth()+1 // 月
       const D = date.getDate() < 10 ? '0' + date.getDate() : date.getDate() // 日
       const H = date.getHours() < 10 ? '0' + date.getHours() : date.getHours() // 时
       const m = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes() // 分
       const S = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds() // 秒 
       const Time=`${Y}-${M}-${D} ${H}:${m}:${S}`;
       return Time;
    },
    // 修改状态
    async handleEdit(index,row,status){
      await updateApplyState({Id:row.Id,ApplyState:status}).then(res=>{
        if(res.Success){
          this.$message({
            message: "操作成功",
            type: "success",
          });
          this.init()
        }else{
          this.$message({
            message: "操作失败，请重试",
            type: "fail",
          });
        }
      })
    },
    handleClose(){
      this.editDrawer=false;
    },
    handleDrawerSuccess(){
      this.editDrawer=false;
      this.init()
    },
    // 分页
    prevpage(val){
      this.pageIndex=val;
      this.init();
    },
    nextpage(val){
      this.pageIndex=val;
      this.init();
    },
    handleCurrentChange(val){
      this.pageIndex =val;
      this.init();
    },
  },
};
</script>
<style scoped lang='scss'>
   /* 搜索 */
  .search{
    margin-bottom: 30px;
    .inputs{
      text-align: left;
      display: flex;

    }
    .wxName,.select,.timeSelect{
      width: 20%;
      margin-right: 20px;
    }
    .Btns{
      display: flex;
      justify-content: flex-end;
      margin-bottom: 10px;
    }
  }
</style>
