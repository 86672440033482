<template>
    <section>
      <!-- 搜索 -->
      <div class="search">
        <div class="inputs">
          <el-input class="wxName" v-model="searchInfo.Gatewayimei" placeholder="网关设备编号"></el-input>
          <el-input class="wxName" v-model="searchInfo.SlaveSN" placeholder="从机设备号"></el-input>
          <div class="Btns">
           <el-button type="primary" @click="toSearch">查询</el-button>
           <el-button  @click="backAll">返回所有</el-button>
          </div>
        </div>
      </div>
      <!-- <div class="add">
        <el-button type="primary" @click="handleAdd">新增</el-button>
      </div> -->
       <div class="container">
         <el-table
          :data="equipmentInfo"
          style="width: 100%"
          class="table"
          fit
        >
          <!-- 数据展示区 -->
          <af-table-column
            label="ID"
            prop="Id"
            align='center'>
          </af-table-column>
          <af-table-column
            label="网关设备编号"
            prop="Gatewayimei"
            align='center'>
          </af-table-column>
          <af-table-column
            label="从机设备号"
            prop="SlaveSN"
            align='center'>
          </af-table-column>
          <el-table-column
            label="设备当前状态"
            prop="SlaveType"
            align='center'>
            <template slot-scope="scope">
                {{ scope.row.SlaveType == 1 ? '未启动' : (scope.row.SlaveType == 2 ? '启动' : (scope.row.SlaveType == 3 ? '解锁' : (scope.row.SlaveType == 4 ? '锁定' : (scope.row.SlaveType == 5 ? '解锁中' :(scope.row.SlaveType == 6 ? '锁定中':(scope.row.SlaveType == 7 ? '锁定堵转':(scope.row.SlaveType == 8 ? '解锁堵转':'--'))))))) }}
            </template>
          </el-table-column>
          <af-table-column
            label="异常描述"
            prop="SlaveError"
            align='center'>
          </af-table-column>
          <af-table-column
            label="信号强度"
            prop="SlaveSignal"
            align='center'>
          </af-table-column>
          <af-table-column
            label="温度（℃）"
            prop="SlaveHeat"
            align='center'>
          </af-table-column>
          <af-table-column
            label="电压（V）"
            prop="SlaveVoltage"
            align='center'>
          </af-table-column>
          <el-table-column
            label="传感器状态"
            prop="SlaveCGQType"
            align='center'>
            <template slot-scope="scope">
                {{ scope.row.SlaveCGQType == 1 ? '占用状态' : (scope.row.SlaveCGQType == 2 ? '空闲状态' : (scope.row.SlaveCGQType == 3 ? '车辆移动' : (scope.row.SlaveCGQType == 4 ? '车辆驶出' : (scope.row.SlaveCGQType == 5 ? '车辆驶入' :(scope.row.SlaveCGQType == 6 ? '平行车位模式':(scope.row.SlaveCGQType == 7 ? '垂直车位模式':'--')))))) }}
            </template>
          </el-table-column>
          <af-table-column
            label="传感器设置回复"
            prop="SlaveCGQpz"
            align='center'>
          </af-table-column>
          <el-table-column label="操作" fixed="right"  align='center' min-width="200">
            <template slot-scope="scope">
              <el-button size="mini" @click="handleChange(scope.row,2)">解锁</el-button>
              <el-button size="mini" @click="handleChange(scope.row,1)">锁定</el-button>
            </template>
          </el-table-column>·
        </el-table>
        <!--分页-->
        <el-col :span="24" class="toolbar" >
          <el-pagination
            layout="total, prev, pager, next, jumper"
            @current-change="handleCurrentChange"
            @prev-click="prevpage"
            @next-click="nextpage"
            :page-size="10"
            :total="total"
            style="float:right;"
          ></el-pagination>
        </el-col>
        <!-- 新增设备 -->
        <el-drawer
          title="新增设备"
          :visible.sync="addDrawer"
          :direction="direction"
          :before-close="handleClose">
          <add v-if="addDrawer" @close='handleClose' @success="handleDrawerSuccess(form)  "  ref='newForm' :id="id" :row="row"></add>
        </el-drawer>
      </div>
    </section>
  </template>
  
  <script>
  import { getEquipmentFloorLockInfo,FloorLockUpdate } from '@/api/public.js';
  import add from './add.vue';
  export default {
    data() {
      return {
        // 分页信息
        pageIndex:1,
        pageSize:10,
        total:0,
        equipmentInfo:[], // 设备列表
        searchInfo:{ // 查询条件
          Gatewayimei:'',
          SlaveSN:'',
        },
        // 弹框
        addDrawer:false, // 是否显示新增设备弹窗
        id:'',
        row:'',
        direction:'rtl',
      }
    },
  
    components: {add},
  
    computed: {},
  
    mounted() {
      this.init();
    },
  
    methods: {
      // 初始化
      async init(){
        await getEquipmentFloorLockInfo({pageIndex:this.pageIndex,pageSize:this.pageSize,data:this.searchInfo}).then((res)=>{
          if(res.Success){
            this.equipmentInfo=res.Data.data;
            this.total=res.Data.totalCount;
            this.equipmentInfo.forEach(element => {
              for (let key in element) {
                if((!element[key] && element[key]!=0) || element[key] == ''){
                  element[key]='--';
                }
                // element[key] = (!element[key] && element[key] !==0) || element[key] == '' ? '--' : element[key];
              }
            });
          }
        })
        
      },
      // 条件查询
      toSearch(){
        this.pageIndex=1;
        this.init();
      },
      // 清除查询条件
      backAll(){
        this.searchInfo={
          Gatewayimei:'',
          SlaveSN:'',
        };
        this.pageIndex=1;
        this.init();
      },
      // 锁定、解锁设备
      handleChange(row,type){
        this.$confirm('确认执行该操作吗？').then( async () =>{
            await FloorLockUpdate({Data1:row.Id,Data2:type}).then(res=>{
                if (res.Success) {
                  this.$message({
                    message: "操作成功",
                    type: "success",
                  });
                  this.init();
                }else{
                  this.$message({
                    message: "操作失败，请重试",
                    type: "fail",
                  });
                }
            })
        })
        
      },
      // 打开新增
      handleAdd(){
        this.addDrawer=true;
      },
      // 关闭
      handleClose(){
        this.addDrawer=false;
        this.init();
      },
      handleDrawerSuccess(){
        this.addDrawer=false;
      },
      // 分页
      prevpage(val){
        this.pageIndex=val;
        this.init();
      },
      nextpage(val){
        this.pageIndex=val;
        this.init();
      },
      handleCurrentChange(val){
        this.pageIndex=val;
        this.init();
      },
    },
  };
  </script>
  <style scoped lang='scss'>

   /* 搜索 */
  .search{
    margin-bottom: 30px;
    .inputs{
      text-align: left;
      display: flex;
  
    }
    .wxName,.select,.timeSelect{
      width: 15%;
      margin-right: 20px;
    }
    .timeSelect{
      width: 20%;
    }
    .Btns{
      display: flex;
      justify-content: flex-end;
      margin-bottom: 10px;
    }
  }
  /* 添加 */
  .add{
      width: 100%;
      background-color: white;
      height: 60px;
      width: 100%;
      padding: 10px 50px;
      position: relative;
    }
    .add button{
      float: right;
      margin:0 10px 0 0 ;
      text-align: center;
    }
  
    .container{
      img{
        &:hover{
          cursor: pointer;
        }
      }
    }
  </style>
  