<template>
  <section style="height: 100%;display: flex;flex-direction: column;">
    <div class="search">
      <div class="inputs">
        <el-date-picker
          v-model="searchInfo.Data1"
          type="date"
          @change="chooseDate"
          placeholder="选择日期">
        </el-date-picker>
        <el-button @click="search(1)" :class=" searchInfo.Data2 == 1 ? 'choosed' : '' ">按月查询</el-button>
        <el-button @click="search(2)" :class=" searchInfo.Data2 == 2 ? 'choosed' : '' ">按日查询</el-button>
        <el-button type="primary" @click="educe">导出</el-button>
        <p class="remind">备注：按日查询为选择日期当天00：00：00 - 23：59：59</p>
      </div>
    </div>
    <div class="container">
      <el-table
       :data="tableData"
       border
       height="calc(100% - 24px)"
       class="table"
       fit>
        <af-table-column
        align='center'
        fixed="left"
        prop="NeighbourhoodName"
        label="场地名称"
        width="180">
        </af-table-column>
        <af-table-column
        align='center'
        prop="CreateTime"
        label="安装时间">
        </af-table-column>
        <af-table-column
        align='center'
        prop="sumPortCount"
        label="安装枪数">
        </af-table-column>
        <af-table-column
        align='center'
        prop="InputCost"
        label="投入成本（元）">
        </af-table-column>
        <af-table-column
        align='center'
        prop="countnum"
        label="总订单数">
        </af-table-column>
        <af-table-column
        align='center'
        prop="ycountnum"
        label="本月订单数">
        </af-table-column>
        <af-table-column
        align='center'
        prop="PointedAmount"
        label="场地电价（元/度）">
        </af-table-column>
        <af-table-column
        align='center'
        prop="ServiceAmount"
        label="服务电价（元/度）">
        </af-table-column>
        <af-table-column
        align='center'
        prop="ysumOrderElectricNum"
        label="本月合计使用电度数">
        </af-table-column>
        <af-table-column
        align='center'
        prop="yActualAmount"
        label="本月收入（订单明细实收）">
        </af-table-column>
        <af-table-column
        align='center'
        prop="yActualAmounts"
        label="本月收入（入账 - 退款）">
        </af-table-column>
        <af-table-column
        align='center'
        prop="yOrderAmount"
        label="本月入账（元）">
        </af-table-column>
        <af-table-column
        align='center'
        prop="ysumRefundAmount"
        label="本月退款（元）">
        </af-table-column>
        <af-table-column
        align='center'
        prop="ysumElectricityAmount"
        label="本月合计应支出电费（元）">
        </af-table-column>
        <af-table-column
        align='center'
        prop="ysumServiceAmount"
        label="本月合计服务费（元）">
        </af-table-column>
        <af-table-column
        align='center'
        prop="BenefitProportion"
        label="场所占比（%）">
        </af-table-column>
        <af-table-column
        align='center'
        prop="BenefitAmount"
        label="本月应分服务费金额（元）">
        </af-table-column>
        <af-table-column
        align='center'
        prop="ysumServiceCleanAmount"
        label="本月服务费净收入（元）">
        </af-table-column>
        <af-table-column
        align='center'
        prop="OneSpearDayIncome"
        label="单枪日收入（元/天/枪）">
        </af-table-column>
      </el-table>
    </div>
  </section>
</template>

<script>
import {GetOrderYueReports,ExportOrderYueReports} from '@/api/public.js';
import {baseUrl} from '@/api/url.js';
export default {
  data() {
    return {
        tableData:[],
        searchInfo:{
          Data1:null,
          Data2:1,
        }
    }
  },

  components: {},

  computed: {},

  mounted() {
    this.getToday();
    this.$nextTick(()=>{
      this.init();
    });
  },

  methods: {
    async init(){
      await GetOrderYueReports(this.searchInfo).then(res=>{
        if(res.Success){
          this.tableData = res.Data;
          this.tableData.forEach(element => {
            element.CreateTime=this.timeToTime(element.CreateTime.substring(6,19));
          });
        }else{
          this.tableData = [];
          this.$message({
            message:'获取数据失败，请重试',
            type:'fail'
          })
        }
      })
    },
    getToday(){
      let myDate = new Date();
      let year = myDate.getFullYear();    //获取完整的年份  2023
      let month = myDate.getMonth() < 10 ? '0' + (myDate.getMonth() +1) : myDate.getMonth()+1 ;       //获取当前月份(0-11,0代表1月)
      let day = myDate.getDate();        //获取当前日(1-31)
      this.searchInfo.Data1 = `${year}-${month}-${day}`
    },
    // 时间戳转时间
    timeToTime(time){
       const date = new Date(Number(time))
       const Y = date.getFullYear() // 年
       const M = date.getMonth() + 1 < 10 ? '0'+(date.getMonth()+1) : date.getMonth()+1 // 月
       const D = date.getDate() < 10 ? '0' + date.getDate() : date.getDate() // 日
       const H = date.getHours() < 10 ? '0' + date.getHours() : date.getHours() // 时
       const m = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes() // 分
       const S = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds() // 秒 
       const Time=`${Y}-${M}-${D} ${H}:${m}:${S}`;
       return Time;
    },
    // 中国标准时间转年月日
    timeChange(time,ifend){
      var chinaStandard=time;
        var date = new Date(chinaStandard);
        var y = date.getFullYear();
        var m = date.getMonth() + 1;
        m = m < 10 ? ('0' + m) : m;
        var d = date.getDate();
        d = d < 10 ? ('0' + d) : d;
        var h = date.getHours();
        var minute = date.getMinutes();
        minute = minute < 10 ? ('0' + minute) : minute;
        var second = date.getSeconds();
        second = second < 10 ? ('0' + second) : second;
        let Time;
        if(ifend){
          Time = y + '/' + m + '/' + d+' 23:59:59';
        }else{
          Time = y + '/' + m + '/' + d+' '+h+':'+minute+ ':' + second;
        }
        return Time;
    },
    chooseDate(e){
      console.log(e,111)
      this.searchInfo.Data1= this.timeChange(e);
      this.init();
    },
    // 条件搜索
    search(type){
      this.searchInfo.Data2 = type;
      this.init();
    },
    // 导出
    async educe(){
      await ExportOrderYueReports(this.searchInfo).then(res=>{
        if(res.Success){
          if(res.Success){
            window.open(baseUrl+'/'+res.Data);
          }else{
            this.$message({
              message: res.Message,
              type: "fail",
            });
          }
        }
      })
    },
  },
};
</script>
<style scoped lang="scss">
@import "@/static/default";
.remind{
  font-size: 14px;
  color: rgb(116, 4, 4);
  width: 100%;
  text-align: left;
  line-height: 45px;
}
    /* 搜索 */
    .search{
      margin-bottom: 10px;
        .inputs{
          display: flex;
          flex-wrap: wrap;
        }
        .el-date-editor.el-input{
          margin-right: 20px;
        }
      }
  .choosed{
    background:#ccc;
  }
  .container{
    background-color: #fff !important;
    padding:0 32px;
    flex: 1;
    .table{
      margin-top:24px !important;
      width: 100% !important;
    }
  }
</style>
