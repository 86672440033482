<template>
  <section>
    <el-form ref="form" label-position="right" status-icon :model="form" :rules="rules" label-width="100px" style="width:95%;overflow:auto">
      <el-form-item label="菜单名称" prop="MenuName">
        <el-input placeholder="请输入菜单名称" v-model="form.MenuName">
        </el-input>
      </el-form-item>
      <el-form-item label="菜单路径" prop="PageUrl">
        <el-input placeholder="请输入菜单路径" v-model="form.PageUrl">
        </el-input>
      </el-form-item>
      <el-form-item label="排序" prop="Sort">
        <el-input placeholder="请输入排序" v-model="form.Sort">
        </el-input>
      </el-form-item>
      <el-form-item label="是否冻结" prop="IsLocked">
         <el-radio-group v-model="form.IsLocked">
           <el-radio-button label="否"></el-radio-button>
           <el-radio-button label="是"></el-radio-button>
         </el-radio-group>
      </el-form-item>
      <el-form-item>
        <el-button @click="close('form')">取消</el-button>
        <el-button type="primary" @click="addMenu('form')" :plain='true'>立即添加</el-button>
      </el-form-item>
    </el-form>
  </section>
</template>

<script>
let _this;
import {addMenu} from '@/api/public.js';
// import { addUser }  from '@/api/zhushou.js';

export default {
  props:{row:{type:Object}},
  data() {
    return {
      form:{ParentId:''},
      imageUrl:'',
      value:[],
      options:[],
      show:true,
      rules: {
        MenuName: [
          { required: true, message: "菜单名称不得为空", trigger: "blur" },
        ],
        PageUrl: [
          { required: true, message: "菜单路径不得为空", trigger: "blur" },
        ],
        Sort: [
          { required: true, message: "排序不得为空", trigger: "blur" },
        ],
        IsLocked: [
          { required: true, message: "请选择是否冻结", trigger: "change" },
        ],
      },
    };
  },

  components: {},

  computed: {},

  mounted() {
    _this=this;
    // this.init();
    if(this.row){
      this.form.ParentId=this.row.Id;
      this.form.Level=2;
    }else{
      this.form.Level=1;
    }
  },

  methods: {
     close(){
        _this.$emit('close');
     },
     //  添加
     addMenu(form) {
       if(_this.form.IsLocked==='否'){
         _this.form.IsLocked=false;
       }else if(_this.form.IsLocked==='是'){
         _this.form.IsLocked=true;
       }
        _this.$refs["form"].validate(valid => {
          if (valid) {
            addMenu(_this.form)
              .then((res) => {
                if (res.Success) {
                  _this.$message({
                    message: "添加成功",
                    type: "success",
                  });
                  setTimeout(() => {
                    _this.$emit("success");
                    _this.$emit('close');
                  }, 1000);
                }else{
                  _this.$message({
                    message: "添加失败,请重试",
                    type: "fail",
                  });
                }
              })
              .catch(error => {});
          } else {
            return false;
          }
      });
    },
  }
};
</script>
<style lang="scss" scoped>
 .el-drawer{
   overflow:auto !important;
 }
 .avatar-uploader .el-upload {
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }
  .avatar-uploader-icon {
    border: 1px dashed #ccc;
    border-radius: 50%;
    overflow:hidden;
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }
  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }
</style>
