<template>
  <section>
    <el-form ref="form" label-position="right" status-icon :model="form" :rules="rules" label-width="150px" style="width:95%;overflow:auto">
      <el-form-item label="问题" prop="Question" >
        <el-input placeholder="请输入问题" v-model="form.Question">
        </el-input>
      </el-form-item>
      <el-form-item label="答案" prop="Answer" >
        <el-input placeholder="请输入答案" v-model="form.Answer">
        </el-input>
      </el-form-item>
      <el-form-item label="是否页面加载展示" prop="IsShow" >
         <el-radio-group v-model="form.IsShow">
           <el-radio-button label="否"></el-radio-button>
           <el-radio-button label="是"></el-radio-button>
         </el-radio-group>
      </el-form-item>
      <el-form-item>
        <el-button @click="close('form')">取消</el-button>
        <el-button type="primary" @click="edit" :plain='true'>立即修改</el-button>
      </el-form-item>
    </el-form>
  </section>
</template>

<script>
let _this;
import {upDateCustomerMessageInfo} from '@/api/public.js';
export default {
  props:{row:{type:Object}},
  data() {
    return {
      form:{ // 表单数据
        Question:'',
        Answer:'',
        IsShow:'',
      },
      rules: { // 表单验证
        Question: [
          { required: true, message: '问题不得为空', trigger: 'blur', }
        ],
        Answer: [
          { required: true, message: '答案不得为空', trigger: 'change', }
        ],
        IsShow: [
          { required: true, message: '请选择是否页面加载展示', trigger: 'change', }
        ],
      },
    };
  },

  components: {},

  computed: {},

  mounted() {
    _this=this;
    this.form=this.row;
  },

  methods: {
    // 关闭
    close(){
       _this.$emit('close');
    },
     //  添加
    edit() {
        _this.$refs["form"].validate(valid => {
          if (valid) {
            this.$confirm('确认修改该问答吗？', '提示', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning'
            }).then(()=>{
              this.form.IsShow=this.form.IsShow == '是' ? 1 : 2;
              upDateCustomerMessageInfo(this.form)
               .then((res) => {
                 if (res.Success) {
                   _this.$message({
                     message: "修改成功",
                     type: "success",
                   });
                   setTimeout(() => {
                     _this.$emit("success");
                   }, 1000);
                 }else{
                   _this.$message({
                     message: "修改失败，请重试",
                     type: "fail",
                   });
                 }
               })
               .catch(error => {});
            }).catch(() => {});
          } else {
            return false;
          }
      });
    },
    
  }
};
</script>
<style lang="scss" scoped>
 .el-drawer{
   overflow:auto !important;
 }
 .avatar-uploader .el-upload {
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }
  .avatar-uploader-icon {
    border: 1px dashed #ccc;
    border-radius: 50%;
    overflow:hidden;
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }
  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }
</style>
