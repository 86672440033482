<template>
  <section>
    <!-- 搜索 -->
    <div class="search">
      <div class="inputs">
        <el-input class="wxName" v-model="searchInfo.Data.Command" placeholder="请输入命令类型"></el-input>
        <div class="Btns">
         <el-button type="primary" @click="toSearch">查询</el-button>
         <el-button  @click="backAll">返回所有</el-button>
        </div>
      </div>
    </div>
    <div class="container">
        <el-table
          :data="messageInfo"
          style="width: 100%"
          class="table"
          fit
        >
            <!-- 数据展示区 -->
            <el-table-column
              label="ID"
              prop="Id"
              align='center'
              width='60px'>
            </el-table-column>
            <el-table-column
              label="设备编号"
              prop="EquipmentNum"
              align='center'
              width='120px'>
            </el-table-column>
            <el-table-column
              label="命令"
              prop="Command"
              align='center'
              width='70px'>
            </el-table-column>
            <el-table-column
              label="报文"
              prop="Message"
              align='center'>
            </el-table-column>
            <!-- <el-table-column
              label="解析数据"
              prop="Data"
              align='center'
              width='100px'>
            </el-table-column> -->
            <el-table-column
              label="创建时间"
              prop="CreateTime"
              align='center'
              width='150px'>
            </el-table-column>
        </el-table>
        <!--分页-->
        <el-col :span="24" class="toolbar" >
          <el-pagination
            layout="total, prev, pager, next, jumper"
            @current-change="handleCurrentChange"
            @prev-click="prevpage"
            @next-click="nextpage"
            :page-size="10"
            :total="total"
            style="float:right;"
          ></el-pagination>
        </el-col>
    </div>
  </section>
</template>

<script>
import {getMessageInfo} from '@/api/public.js';
export default {
  data() {
    return {
        messageInfo:[], // 报文信息
        searchInfo:{ // 搜索条件
            pageIndex:1,
            pageSize:10,
            Data:{
                Command:'',
            }
        },
        total:0,
    }
  },

  components: {},

  computed: {},

  mounted() {
    this.init();
  },

  methods: {
    // 初始化
    async init(){
        await getMessageInfo(this.searchInfo).then(res=>{
            if(res.Success){
                this.messageInfo=res.Data.data;
                this.total=res.Data.totalCount;
                this.messageInfo.forEach(element => {
                  element.CreateTime=this.timeToTime(element.CreateTime.substring(6,19));
                });
            }
        })
    },
    // 时间戳转时间
    timeToTime(time){
       const date = new Date(Number(time));
       const Y = date.getFullYear(); // 年
       const M = date.getMonth() + 1 < 10 ? '0'+(date.getMonth()+1) : date.getMonth()+1; // 月
       const D = date.getDate() < 10 ? '0' + date.getDate() : date.getDate(); // 日
       const H = date.getHours() < 10 ? '0' + date.getHours() : date.getHours(); // 时
       const m = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes(); // 分
       const S = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds(); // 秒
       const Time=`${Y}-${M}-${D} ${H}:${m}:${S}`;
       return Time;
    },
    // 条件查询
    toSearch(){
      this.searchInfo.pageIndex=1;
      this.init();
    },
    // 清除查询条件
    backAll(){
      this.searchInfo={
        pageIndex:1,
        pageSize:10,
        Data:{
            Command:'',
        }
      };
      this.init();
    },
    // 分页
    handleCurrentChange(val){
      this.searchInfo.pageIndex=val;
      this.init();
    },
    prevpage(val){
      this.searchInfo.pageIndex=val;
      this.init();
    },
    nextpage(val){
      this.searchInfo.pageIndex=val;
      this.init();
    },
  },
};
</script>
<style scoped lang='scss'>
@import "@/static/default";
/* 内容 */
.container{
  background-color: #fff !important;
  padding:0 32px;
  .toolbar{
    background: #fff;
    width:calc(100% + 64px);
    padding:16px 32px 26px 0;
    margin-left:-32px;
  }
}
/* 搜索 */
.search{
  margin-bottom: 30px;
  .inputs{
    display: flex;
    justify-content: space-between;
  }
  .wxName{
    width: 30%;
  }
  .Btns{
    display: flex;
    justify-content: flex-end;
    margin-bottom: 10px;
  }
}
</style>
