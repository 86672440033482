<template>
  <section>
    <!-- 搜索 -->
    <div class="search">
      <div class="inputs">
        <el-input class="wxName" v-model="searchInfo.OwerName" placeholder="用户名称"></el-input>
        <el-input class="wxName" v-model="searchInfo.Phone" placeholder="用户电话"></el-input>
        <el-input class="wxName" v-model="searchInfo.EquipmentLockNum" placeholder="车位锁设备号"></el-input>
        <el-input class="wxName" v-model="searchInfo.Licenseplate" placeholder="车牌号"></el-input>
        <!-- <el-select class='select' v-model="Phone" clearable  placeholder="卡状态" @change="selectState"> 
          <el-option
            v-for="item in statusOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select> -->
        <div class="Btns">
         <el-button type="primary" @click="toSearch">查询</el-button>
         <el-button type="primary" @click="backAll">返回所有</el-button>
      </div>
      </div>
    </div>
    <div class="container">
      <el-table
        :data="cardUser"
        style="width: 100%" 
        class="table"
        fit
      >
        <!-- 数据展示区 -->
        <el-table-column
          label="ID"
          prop="Id"
          align='center'
          width='100px'>
        </el-table-column>
        <af-table-column
          label="订单号"
          prop="Ordernum"
          align='center'>
        </af-table-column>
        <af-table-column
          label="设备编号"
          prop="EquipmentLockNum"
          align='center'>
        </af-table-column>
        <af-table-column
          label="用户信息"
          prop="OwerName"
          align='center'>
           <template slot-scope="scope">
            <p>{{scope.row.OwerName?scope.row.OwerName:'--'}}</p>
            <p>{{scope.row.Phone?scope.row.Phone:'--'}}</p>
            <p>{{scope.row.Licenseplate?scope.row.Licenseplate:'--'}}</p>
           </template>
        </af-table-column>
        <af-table-column
          label="预约时间段"
          prop="TimeList"
          align='center'>
            <template slot-scope="scope">
             <p>{{ scope.row.TimeList[0]}} - {{scope.row.TimeList[1]}}</p>
            </template>
        </af-table-column>
        <af-table-column
          label="是否存在违约"
          prop="defaultorder"
          align='center'>
            <template slot-scope="scope">
             <p>{{ scope.row.defaultorder == 0 ? "预约生成" : ( scope.row.defaultorder == 1 ? "订单生成中(锁被解开)":( scope.row.defaultorder == 2 ? "取消预约":( scope.row.defaultorder == 3 ? "结束":( scope.row.defaultorder == 4 ? "预约过期":'--'))))}}</p>
            </template>
        </af-table-column>
        <af-table-column
          label="违约描述"
          prop="defaultText"
          align='center'>
        </af-table-column>
        <af-table-column
          label="预约码"
          prop="reseraCade"
          align='center'>
        </af-table-column>
        <af-table-column
          label="创建时间"
          prop="CreateTime"
          align='center'>
        </af-table-column>
        <af-table-column
          label="输入验证码/取消时间"
          prop="CodeTime"
          align='center'>
        </af-table-column>
        <af-table-column
          label="审核时间"
          prop="AuditTime"
          align='center'>
        </af-table-column>
        <af-table-column
          label="审核员"
          prop="AuditUser"
          align='center'>
        </af-table-column>
        <af-table-column
          label="审核结果"
          prop="AuditResult"
          align='center'>
        </af-table-column>
        <!-- <el-table-column label="操作" fixed="right" align='center' min-width="180">
          <template slot-scope="scope">
            <el-button size="mini" @click="audit(scope.$index,scope.row,1)">通过</el-button>
            <el-button size="mini" @click="audit(scope.$index,scope.row,2)">拒绝</el-button>
          </template>
        </el-table-column> -->
      </el-table>
      <!--分页-->
      <el-col :span="24" class="toolbar" >
        <el-pagination
          layout="total, prev, pager, next, jumper"
          @current-change="handleCurrentChange"
          @prev-click="prevpage"
          @next-click="nextpage"
          :page-size="10"
          :total="total"
          style="float:right;"
        ></el-pagination>
      </el-col>
    </div>
  </section>
</template>

<script>
import {getReservationInfo} from '@/api/public.js';
import {timeToTime} from '@/api/publicFun.js';
export default {
  data() {
    return {
      // 用户信息
      cardUser:[],
      row:'',
      // 分页信息
      total:0,
      searchInfo:{
        pageIndex:1,
        pageSize:10,
        data:{
          OwerName:'',
          EquipmentLockNum:'',
          Licenseplate:'',
          Phone:'',
        },
      },
    }
  },

  components: {},

  computed: {},

  mounted() {
    this.init();
  },

  methods: {
    // 数据初始化
    async init(){
      await getReservationInfo(this.searchInfo).then(res=>{
        if(res.Success){
          this.cardUser=res.Data.data;
          this.total=res.Data.totalCount;
          this.cardUser.forEach(element => {
            element.CreateTime = timeToTime(element.CreateTime.substring(6,19));
            element.CodeTime = timeToTime(element.CodeTime.substring(6,19));
            element.AuditTime = timeToTime(element.AuditTime.substring(6,19));
          });
        }
      })
    },
    // 条件查询
    toSearch(){
      this.searchInfo.pageIndex=1;
      this.init();
    },
    // 清除查询条件
    backAll(){
      this.pageIndex=1;
      this.searchInfo={
        pageIndex:1,
        pageSize:10,
        data:{
          OwerName:'',
          EquipmentLockNum:'',
          Licenseplate:'',
          Phone:'',
        },
      };
      this.init();
    },
    // 时间戳转时间
    timeToTime(time){
       const date = new Date(Number(time));
       const Y = date.getFullYear(); // 年
       const M = date.getMonth() + 1 < 10 ? '0'+(date.getMonth()+1) : date.getMonth()+1; // 月
       const D = date.getDate() < 10 ? '0' + date.getDate() : date.getDate(); // 日
       const H = date.getHours() < 10 ? '0' + date.getHours() : date.getHours(); // 时
       const m = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes(); // 分
       const S = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds(); // 秒 
       const Time=`${Y}-${M}-${D} ${H}:${m}:${S}`;
       return Time;
    },
    // 审核
    audit(index,row,type){},
    // 分页
    prevpage(val){
      this.pageIndex=val;
      this.init();
    },
    nextpage(val){
      this.pageIndex=val;
      this.init();
    },
    handleCurrentChange(val){
      this.pageIndex =val;
      this.init();
    },
  },
  
};
</script>
<style scoped lang='scss'>
 /* 搜索 */
.search{
  margin-bottom: 30px;
  .inputs{
    display: flex;
    text-align: left;
  }
  .wxName,.select,.timeSelect{
    width: 15%;
    margin-right: 20px;
  }
  .Btns{
    display: flex;
    justify-content: flex-end;
    margin-bottom: 10px;
  }
}
/* 遮罩层 */
.cover{
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.3);
  z-index: 20000;
}
.inside{
  height: 300px;
  width: 500px;
  background: #fff;
  position: absolute;
  top: 100px;
  left:50%;
  margin: 0 0 0 -150px;
  padding: 20px;
  box-sizing: border-box;
}

.inside .title{
  height: 50px;
  line-height: 50px;
  border-bottom:solid 1px #f0f0f0 ;
}

.inside .cardBalance,.inside .sendBalance{
  height: 50px;
  line-height: 50px;
  text-align: left;
  margin-top: 20px;
}

.inside input{
  height: 30px;
  width: 300px;
  padding-left: 10px;
  outline: 0;
  border: #ccc solid 1px;
}

.inside .btn{
  position: absolute;
  right: 0;
  bottom: 0;
  height: 50px;
  width: 100%;
  border-top: solid 1px #f0f0f0 ;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.inside .btn button{
  height: 30px;
  width: 50px;
  border: solid 1px #ccc;
  border-radius: 5px;
  margin-right: 20px;
}

.inside .btn button:nth-child(2){
  background: #40a9ff;
  color: #fff;
}

.cardStatus{
  display: inline-block;
  background:#f6ffed;
  border:solid 1px #b7eb8f;
  padding: 3px;
  color:#52c41a;
  font-size: 14px;
  border-radius: 3px;
}
</style>
