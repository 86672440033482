<template>
  <div>
    <el-form ref="form" label-position="right" status-icon :model="form" :rules="rules" label-width="100px" style="width:95%;overflow:auto">
      <!-- 物业 -->
      <el-form-item label="提现状态" prop="ApplyState">
        <el-select v-model="value" placeholder="请选择" @change="selectApplyState" >
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button @click="close('form')">取消</el-button>
        <el-button type="primary" @click="edit('form')" :plain='true'>立即修改</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import {updateApplyState} from '@/api/public.js'
export default {
  props:{row:{type:Object}},
  data() {
    return {
        form:{},
        options:[
            {value:1,label:'待打款'},
            {value:2,label:'打款完成'},
            {value:3,label:'撤销'},
        ],
        value:'',
        rules:{
          ApplyState: [
            { required: true, message: "请选择提现状态", trigger: "change" },
          ],
        },
    }
  },

  components: {},

  computed: {},

  mounted() {
    this.form.Id=this.row.Id;
  },

  methods: {
    selectApplyState(e){
        this.form.ApplyState=e;
    },
    edit(){
        updateApplyState(this.form).then(res=>{
            if(res.Success){
                this.$message({
                  message: "修改成功",
                  type: "success",
                });
                setTimeout(() => {
                  this.$emit("success");
                  this.init();
                }, 1000);
            }else{
                this.$message({
                  message: "修改失败，请重试",
                  type: "fail",
                });
            }
        })
    },
  },
};
</script>
<style scoped></style>
