<template>
<section>
  <!-- 搜索 -->
  <div class="search">
    <div class="inputs">
      <!-- 订单状态 -->
      <el-select class='select' v-model="searchInfo.IsShow" clearable placeholder="是否页面加载展示" @change="selectStatus">
        <el-option
          v-for="item in statusOptions"
          :key="item.value"
          :label="item.label"
          :value="item.value">
        </el-option>
      </el-select>
      <el-input class="wxName" v-model="searchInfo.Question" placeholder="请输入问题关键字"></el-input>
      <el-input class="wxName" v-model="searchInfo.Answer" placeholder="请输入答案关键字"></el-input>
      <div class="Btns">
       <el-button type="primary" @click="toSearch">查询</el-button>
       <el-button  @click="backAll">返回所有</el-button>
      </div>
    </div>
  </div>
  <div class="add">
      <el-button type="primary" @click="handleAdd">添加</el-button>
  </div>
  <div class="container">
    <el-table
      :data="customerServiceInfo"
      style="width: 100%" 
      class="table"
      fit
    >
      <!-- 数据展示区 -->
      <el-table-column
        label="ID"
        prop="Id"
        align='center'
        width='60px'>
      </el-table-column>
      <el-table-column
        label="问题"
        prop="Question"
        align='center'>
      </el-table-column>
      <el-table-column
        label="答案"
        prop="Answer"
        align='center'>
      </el-table-column>
      <el-table-column
        label="是否微信端预展示"
        prop="IsShow"
        align='center'>
      </el-table-column>
      <el-table-column
        label="修改时间"
        prop="ModifiyTime"
        align='center'>
      </el-table-column>
      <el-table-column label="操作" fixed="right" align='center'>
        <template slot-scope="scope">
          <el-button size="mini" @click="handleEdit(scope.$index, scope.row)">编辑</el-button>
          <el-button size="mini" @click="handleDel(scope.$index, scope.row)">删除</el-button>
        </template>
    </el-table-column>
    </el-table>
    <!--分页-->
    <el-col :span="24" class="toolbar" >
      <el-pagination
        layout="total, prev, pager, next, jumper"
        @current-change="handleCurrentChange"
        @prev-click="prevpage"
        @next-click="nextpage"
        :page-size="10"
        :total="total"
        style="float:right;"
      ></el-pagination>
    </el-col>
    <!-- 新增菜单 -->
    <el-drawer
      title="新增问答"
      :visible.sync="addDrawer"
      :direction="direction"
      :before-close="handleClose"
      size='50%'
      style="overflow:auto">
      <add v-if="addDrawer" @close='handleClose' @success="handleDrawerSuccess(form)  "  ref='newForm' :id="id" :row="row"></add>
    </el-drawer>
    <el-drawer
      title="修改问答"
      :visible.sync="editDrawer"
      :direction="direction"
      :before-close="handleClose"
      size='50%'
      style="overflow:auto">
      <edit v-if="editDrawer" @close='handleClose' @success="handleDrawerSuccess(form)  "  ref='newForm' :id="id" :row="row"></edit>
    </el-drawer>
  </div>
</section>
</template>

<script>
import{ getCustomerMessageInfo,delCustomerMessageInfo} from '@/api/public.js';
import add from './add.vue';
import edit from './edit.vue';
export default {
  data() {
    return {
      // 分润明细数据
      customerServiceInfo:[],
      // 分页信息
      pageIndex:1,
      pageSize:10,
      total:0,
      searchInfo:{ // 查询信息
        IsShow:'',
        Question:'',
        Answer:'',
      },
      row:'',
      addDrawer:false,
      editDrawer:false,
      // 下拉框选项   
      statusOptions:[
        {value:1,label:'是'},
        {value:2,label:'否'},
      ],
      direction:'rtl',
    }
  },

  components: {add,edit},

  computed: {},

  mounted() {
    this.init();
  },
  methods: {
    // 数据初始化
    async init(){
      await getCustomerMessageInfo({pageIndex:this.pageIndex,pageSize:this.pageSize,data:this.searchInfo}).then((res)=>{
        if(res.Success){
          this.customerServiceInfo=res.Data.data?res.Data.data:[];
          this.total=res.Data.totalCount;
          this.customerServiceInfo.forEach(element => {
            element.ModifiyTime=this.timeToTime(element.ModifiyTime.substring(6,19));
            element.IsShow=element.IsShow == 1 ? '是' : '否';
          });
        }
      })
    },
    // 时间戳转时间
    timeToTime(time){
       const date = new Date(Number(time))
       const Y = date.getFullYear() // 年
       const M = date.getMonth() + 1 < 10 ? '0'+(date.getMonth()+1) : date.getMonth()+1 // 月
       const D = date.getDate() < 10 ? '0' + date.getDate() : date.getDate() // 日
       const H = date.getHours() < 10 ? '0' + date.getHours() : date.getHours() // 时
       const m = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes() // 分
       const S = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds() // 秒 
       const Time=`${Y}-${M}-${D} ${H}:${m}:${S}`;
       return Time;
    },
    // 删除
    async handleDel(index,row){
        await this.$confirm('确认删除该问答吗？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
           delCustomerMessageInfo({Id:row.Id}).then(res=>{
            if(res.Success){
              this.$message({
                message: "删除成功",
                type: "success",
              });
              this.init();
            }
          })
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });          
        });
    },
    // 条件查询
    toSearch(){
      this.pageIndex=1;
      this.init();
    },
    // 清除查询条件
    backAll(){
      this.pageIndex=1;
      this.searchInfo={};
      this.init();
    },
    selectStatus(e){},
    // 打开新增
    handleAdd(){
      this.addDrawer=true;
    },
    handleEdit(index,row){
      this.row=row;
      this.editDrawer=true;
    },
    handleClose(){
      this.addDrawer=false;
      this.editDrawer=false;
      this.init();
    },
    handleDrawerSuccess(){
      this.addDrawer=false;
      this.editDrawer=false;
      this.init();
    },
    // 分页
    prevpage(val){
      this.pageIndex=val;
      this.init();
    },
    nextpage(val){
      this.pageIndex=val;
      this.init();
    },
    handleCurrentChange(val){
      this.pageIndex =val;
      this.init();
    },
  },
};
</script>
<style scoped lang='scss'>
  @import "@/static/default";
/* 搜索 */
.search{
  margin-bottom: 30px;
    .inputs{
      display: flex;
    }
    .wxName,.select,.timeSelect{
      width: 200px;
      margin-right: 20px;
    }
    .Btns{
      display: flex;
      justify-content: flex-end;
      margin-bottom: 10px;
    }
  }
  /* 添加 */
  .add{
    width: 100%;
    background-color: white;
    height: 60px;
    width: 100%;
    padding: 10px 50px;
    position: relative;
  }
  .add button{
    position: absolute;
    right:20px;
    top:10px;
    width: 100px;
  }
// 按钮
  .btns{
    width: 100%;
    background-color: white;
    height: 60px;
    width: 100%;
    padding: 10px 50px;
    position: relative;
    button{
      position: absolute;
      right:20px;
      top:10px;
    }
  }
/* 内容 */
  .container{
    background-color: #fff !important;
    padding:0 32px;
    .toolbar{
      background: #fff;
      width:calc(100% + 64px);
      padding:16px 32px 26px 0;
      margin-left:-32px;
    }
  }
</style>
